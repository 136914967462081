import { gql } from "@apollo/client";
export const GET_PROPERTY_DETAIL = gql`
  query GetBuildingSpaceById($getBuildingSpaceByIdId: String) {
    getBuildingSpaceById(id: $getBuildingSpaceByIdId) {
      id
      targetId
      city
      market
      zoning
      physicalStatus
      address
      floors
      yearBuilt
      parkingRatio
      buildingSize
      suiteUnit
      suiteSize
      minDivisible
      maxContiguous
      suiteConditionNotes
      leaseType
      expiryDate
      availableOn
      askingNetRent
      grossRent
      listingComments
      listingBroker
      listingBrokerEMAIL
      listingBrokerPHONE
      Vacancy
      daysOnMarket
      listedOnDate
      marketingComments
      ownerPropertyManager
      totalAdditionalRent
      typicalFloorPlate
      isFavourite
      created_at
      updated_at
    }
  }
`;
