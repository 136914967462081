import { gql } from "@apollo/client";
export const CREATE_USER = gql`
  mutation CreateUserByAdmin($input: userInput!) {
    createUserByAdmin(input: $input) {
      code
      success
      message
      user {
        firstName
        lastName
        email
        phoneNumber
        market
        id
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUserByAdmin($input: userInput!, $updateUserByAdminId: String) {
    updateUserByAdmin(input: $input, id: $updateUserByAdminId) {
      user {
        phoneNumber
        market
        lastName
        id
        firstName
        email
        companyName
      }
      success
      message
      code
    }
  }
`;

export const EDIT_DYNAMICDATA = gql`
  mutation EditBuilding($input: buildingInput) {
    editBuilding(input: $input) {
      updated_at
    }
  }
`;
