import work01 from "../../assets/images/dark/image-one.svg";
import work02 from "../../assets/images/dark/image-three.svg";
import work03 from "../../assets/images/dark/image-two.svg";

export const WorkData = [
  {
    image: work01,
    title: "Real-Time Office Leasing Deal Data",
    discription:
      "SpaceComps provides real-time office lease deal comparables for brokers, landlords, tenants, and appraisers.",
  },
  {
    image: work02,
    title: "Anonymous Crowd Sourcing Platform",
    discription:
      "With SpaceComps, data is anonymously crowd sourced, reviewed by our in house team of experts, and is verified by our membership and proprietary algorithms. Our system is designed for maximum efficiency, putting you directly in touch with deal comps that fit your exact needs. ",
  },
  {
    image: work03,
    title: "Give to Get Credit System",
    discription:
      "At SpaceComps, our system rewards you with credits that you can use at your discretion. With a team of experts reviewing deal comps submitted, you can trust that you'll receive the credits to make informed decisions when you need them most. Leveraging the power of our Credit System, you can take control of your leasing business and drive success.",
  },
];

export const creditData = [
  "Share deal completed within last 12 months - 5 CREDITS",
  "Share deal completed within 12-24 months - 3 CREDITS",
  "Share partial deal comp, deal comp correction or deal over 24 months - 1 CREDIT",
];
