import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

const AutoComplete = ({ handleChange, countries, key, value }: any) => {
  const classes = useStyles();
  const handleInput = (e: any) => {
    console.log(e.target.value);
  };
  const [address, setAddress] = useState([]);
  useEffect(() => {
    if (countries) {
      const newArrayOfObj = countries.map(({ address: label, ...rest }: any) => ({
        label,
        ...rest,
      }));
      setAddress(newArrayOfObj);
    }
  }, [countries]);

  return (
    <div key={key}>
      <Autocomplete
        id="country-select-demo"
        options={address}
        autoHighlight
        value={value === "" ? { label: "" } : { label: value }}
        getOptionLabel={(option: any) => option?.label}
        isOptionEqualToValue={(option: { label: any }, val: { label: any }) => option.label === val.label}
        onChange={(_e, valuesdata) => {
          handleChange(valuesdata, "address");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Please select"
            onChange={handleInput}
            className={classes.formControl}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
            }}
          />
        )}
      />
    </div>
  );
};

export default AutoComplete;

const useStyles: any = makeStyles({
  title: {
    marginTop: "23px",
    width: "100%",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "28px",
    color: "#001D23",
  },
  formControl: {
    border: "1px solid #8A8A8A !important",
  },
});
