/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Signup } from "../../../components/layout/Auth";
import { SIGNUP } from "../../../graphql/mutations/signup";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/auth/provideAuth";
import { toast } from "react-toastify";
import { GET_USER_ROLE } from "../../../graphql/queries/getUserRole";
const UserSignUp = () => {
  const [signup, { data, loading, error }] = useMutation(SIGNUP, { fetchPolicy: "network-only" });
  const { data: userrole, loading: userroleloading } = useQuery(GET_USER_ROLE, { fetchPolicy: "network-only" });

  const navigate = useNavigate();
  const location = useLocation();

  const { handleState } = useAuth();
  const [state, setState] = useState("");
  useEffect(() => {
    const role = location.state;
    const roleInfo = userrole?.roleList?.info;
    if (role && roleInfo) {
      const roleId = roleInfo.find((item: any) => item.role === role)?.id;
      setState(roleId);
    }
  }, [location.state, userrole?.roleList?.info]);
  const onSubmit = async (values: { [key: string]: string }) => {
    try {
      const { data: signupData } = await signup({
        variables: {
          input: {
            firstName: values?.firstName,
            lastName: values?.lastName,
            email: values?.email,
            companyName: values?.companyName,
            phoneNumber: values?.phoneNumber,
            market: values?.market,
            role: state,
          },
        },
      });
      if (signupData && signupData.signUpUser) {
        toast.success(signupData.signUpUser.message);
      }
    } catch (err: any) {
      toast.error(err.message || "An error occurred while signing up");
    }
  };

  useEffect(() => {
    if (data?.signUpUser?.success) {
      navigate("/login");
    }
  }, [data, navigate]);

  useEffect(() => {
    const isLoading = loading || userroleloading;
    handleState(!isLoading);
  }, [loading, userroleloading]);

  return (
    <Signup
      onSubmitCallback={onSubmit}
      selectedRole={location.state}
      navigateBaseUrl="/"
      error={!data && error}
      data={data}
      loading={loading}
    />
  );
};

export default UserSignUp;
