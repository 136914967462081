import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Container } from "reactstrap";
import useForm from "../../../Hooks/useForm";
import { defaultLoginFields } from "../../../utils/constants/defaultAuthFields";
import frame2 from "../../../assets/images/dark/close.png";
import { InputBox } from "../Form";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
export interface ILoginProps {
  navigateBaseUrl: string;
  error?: any;
  loading?: boolean;
  onSubmitCallback(values: { [key: string]: string | number }): void;
}
const Login: FC<ILoginProps> = ({ onSubmitCallback, navigateBaseUrl }: ILoginProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { handleChange, values, errors, handleSubmit } = useForm(onSubmitCallback, defaultLoginFields);

  const handleCloseClick = () => {
    navigate("/");
  };
  return (
    <>
      <Container fluid className={classes.containerStyle}>
        <Box onClick={handleCloseClick} className={classes.iconBox}></Box>
        <Card className={`mx-auto  shadow-sm p-3 mb-5  ${classes.main}`}>
          <CardBody className="p-3 w-100 d-flex flex-column">
            <h3 className={classes.headingStyle}> Login</h3>
            <form className={classes.mainForm} onSubmit={handleSubmit}>
              {defaultLoginFields.map((field) => (
                <InputBox
                  key={field.name}
                  {...field}
                  onChange={handleChange}
                  error={errors[field.name]}
                  value={values[field.name]}
                  className={classes.inputCustom}
                />
              ))}
              <div className={`${classes.checkStyleDiv} form-group form-check `}>
                <div>
                  <input type="checkbox" className={`${classes.checkBoxStyle} form-check-input`} id="check" />
                  <small className={`${classes.keepMe}`}>Keep me Logged In</small>
                </div>
                <div>
                  <Link
                    className={`${classes.forgotPassword}`}
                    to={`${navigateBaseUrl}forgot-password`}
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <div className="text-center">
                <button type="submit" className={`${classes.button}`}>
                  Login
                </button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};
export default Login;
const useStyles = makeStyles({
  main: {
    margin: "50px 0px",
    background: "none",
    "@media (max-width: 780px)": {
      marginTop: "120px",
      oveflow: "hidden",
      width: "auto",
    },
    width: "630px",
    height: "auto",
    borderRadius: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  mainForm: {
    width: "420px",
    display: "block",
    margin: "auto",
    "@media (max-width: 780px)": {
      width: "100%",
    },
  },
  inputCustom: {
    border: "1px solid #8A8A8A !important",
    color: "#FFFFFF !important",
  },
  button: {
    marginTop: "35px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    color: "#FFF",
    borderRadius: "0px",
    borderColor: "#FD6262",
    borderWidth: "1px",
    height: "54.19px",
    background: "#FD6262",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    "&:hover": {
      background: "#808080",
      borderRadius: "0px",
      borderColor: "#808080",
      color: "#fff",
    },
  },
  account: {
    paddingBottom: "53px",
    height: "21px",
    left: "587px",
    paddingTop: "30px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    alignItems: "center",
    textAlign: "center",
    color: "#757575",
  },
  headingStyle: {
    textAlign: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "32px",
    lineHeight: "54px",
    letterSpacing: "0.01em",
    textTransform: "capitalize",
    color: "#FFFFFF",
  },

  title: {
    width: "100%",
    height: "44px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#001D23",
  },
  formControl: {
    boxSizing: "border-box",
    display: "block",
    alignItems: "flexStart",
    padding: "18.5px 0px 15.5px 12px",
    width: "100%",
    height: "44px",
    border: "2px solid #DDDDDD",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#001D23",
  },
  forgotPassword: {
    width: "124px",
    height: "21px",
    left: "804px",
    top: "755px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: " #757575",
    "&:hover": {
      color: "#FFFF !important",
    },
  },
  errors: {
    fontSize: "13px",
    color: "red",
    textAlign: "center",
    marginTop: "10px",
    "@media (max-width: 500px)": {
      paddingLeft: "15px",
    },
  },
  keepMe: {
    height: "21px",
    left: "543px",
    top: "755px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
    textAlign: "center",
    color: "#757575",
  },
  iconBox: {
    "&::after": {
      content: '""',
      position: "absolute",
      top: "0px",
      right: "0px",
      width: "2%",
      height: "4%",
      cursor: "pointer",
      transform: "translate(-20px, 20px)",
      backgroundImage: `url(${frame2})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top right",
    },
  },

  containerStyle: {
    padding: "20px 15px",
  },
  checkBoxStyle: {
    marginLeft: "100px",
    width: "18px",
    borderRadius: "0px",
    height: "18px",
    marginTop: "1.5px",
    "&:checked": {
      borderRadius: "0px",
      backgroundColor: "#FD6262 !important",
      borderColor: "#FD6262 !important",
    },
  },
  registerStyle: {
    textDecorationLine: "underline !important",
    textDecoration: "none",
    cursor: "pointer",
    color: "#10257F",
    "&:hover": {
      color: "#10257F",
    },
  },
  checkStyleDiv: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: "21px",
  },
});
