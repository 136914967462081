import React from "react";
import { CompanyData } from "../../utils/constants/headerSlidesData";
import DetailInfo from "../layout/DetailInfo/DetailInfo";
import { makeStyles } from "@mui/styles";

const AllCompanies = () => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      {CompanyData.map((Item: any) => {
        return (
          <div>
            <DetailInfo data={Item.data} />
          </div>
        );
      })}
    </div>
  );
};
export default AllCompanies;
const useStyles: any = makeStyles({
  main: {
    paddingTop: "140px",
  },
  tableTitle: {
    color: "#201D1D",
    fontSize: "40px",
    textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: "600",
    lineHeight: "36px",
    letterSpacing: "0em",
    paddingBottom: "15px",
  },
});
