/**
 * Context API used for Auth related information and methods.
 * I am keeping everything related to the authentication and PrivateRoute in this one file.
 */
import { Backdrop } from "@mui/material";
import React, { createContext, useContext } from "react";
import Loader from "../../common/Loader";
import { useAuthProvider } from "./useAuthProvider";

export interface DefaultContextProps {
  user: boolean;
  isAdmin: boolean;
  state: boolean;
  handleState(values: boolean): void;
  handleHeader(values: boolean): void;
  header: boolean;
}

const defaultContext: DefaultContextProps = {
  user: false,
  isAdmin: false,
  state: true,
  handleState: () => null,
  header: true,
  handleHeader: () => null,
};

// Context API used for Auth related information and methods.
const authContext = createContext(defaultContext);

// Context Provider to wrap the whole app within and make auth information available.
export function ProvideAuth({ children }: any) {
  const auth = useAuthProvider();
  return (
    <authContext.Provider value={auth}>
      {!auth.state && (
        <Backdrop sx={{ color: "#fff", zIndex: 999 }} open={!auth.state}>
          <Loader />
        </Backdrop>
      )}
      {children}
    </authContext.Provider>
  );
}

// Custom hook to access auth related data and methods.
// Most important hook to be used throughout
export function useAuth() {
  return useContext(authContext);
}
