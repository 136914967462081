import React, { useEffect } from "react";
import DialogContent from "@mui/material/DialogContent";
import { defaultAdminDealsFields } from "../../../utils/constants/defaultAuthFields";
import { Form } from "../Form";
import useForm from "../../../Hooks/useForm";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_DEAL_NET_RENT } from "../../../graphql/mutations/deals";
import { toast } from "react-toastify";
import { useAuth } from "../../../contexts/auth/provideAuth";
import DetailCard from "../detailCards/DetailCard";
import { GET_DEAL_NET_RENT_BY_ID } from "../../../graphql/queries/getDealNetRentById";
import { Dialog, DialogTitle, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface ActionProps {
  data?: any;
  isOpen?: boolean;
  onClose(): void;
}

const DealsAction = ({ data, isOpen = false, onClose }: ActionProps) => {
  const {
    data: dealDAta,
    loading: netrentLoading,
    refetch,
  } = useQuery(GET_DEAL_NET_RENT_BY_ID, {
    fetchPolicy: "network-only",
    variables: {
      dealId: data?.id,
    },
  });
  const [createDealNetRent, { loading: netRent }] = useMutation(CREATE_DEAL_NET_RENT, {
    fetchPolicy: "network-only",
    onCompleted: refetch,
  });
  const { handleState } = useAuth();
  const onSubmitCallback = async () => {
    try {
      const months = Number(values.noOfMonths);
      const rent = parseFloat(values.netRent);
      const updatedValues = { noOfMonths: months, netRent: rent, dealId: data?.id };
      const dealRentResponse = await createDealNetRent({
        variables: {
          input: updatedValues,
        },
      });
      if (dealRentResponse?.data) {
        toast.success("Deals added successfully");
        clearForm();
      }
    } catch (e: any) {
      const errorMessage = e?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };
  const { handleChange, values, errors, handleSubmit, clearForm } = useForm(onSubmitCallback, defaultAdminDealsFields);
  useEffect(() => {
    const isLoading = netrentLoading || netRent;
    handleState(!isLoading);
  }, [netRent, handleState, netrentLoading]);

  return (
    <Dialog open={isOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
        <IconButton
          onClick={() => {
            clearForm();
            onClose();
          }}
          sx={{
            ml: "auto",
            backgroundColor: "#8A8A8A",
            "&:hover": {
              backgroundColor: "#8A8A8A", // Change to the color you desire on hover
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "0", marginTop: "-30px" }}>
        <Form
          handleSubmit={handleSubmit}
          fields={defaultAdminDealsFields}
          values={values}
          disable={netRent}
          errors={errors}
          handleChange={handleChange}
        />
        <Divider />
        <DetailCard listData={dealDAta?.getDealNetRentById} />
      </DialogContent>
    </Dialog>
  );
};

export default DealsAction;
