import React, { ChangeEvent, useEffect, useRef } from "react";
import SearchBlock from "../../../components/SearchFilter/SearchBlock";
import { Container, Row, Col } from "reactstrap";
import { GET_BUILDING_SEARCH_LIST } from "../../../graphql/queries/building";
import { useLazyQuery } from "@apollo/client";
import SearchResult from "../../../components/SearchFilter/SearchResult";
import MapContainer from "../../../components/MapContainer/MapContainer";
import { useAuth } from "../../../contexts/auth/provideAuth";
import InnerHeader from "../../../components/Header/InnerHeader";

interface FilterOptions {
  search: string;
  city: string[];
  subMarket: string[];
  baseYear: string[];
  campRange: any;
}

function BrowserSearch() {
  const ref = useRef<null | HTMLDivElement>(null);

  const { handleState } = useAuth();
  const [searchBuilding, { data: buildingData, error, loading }] = useLazyQuery(GET_BUILDING_SEARCH_LIST, {
    fetchPolicy: "network-only",
  });
  const [values, setValues] = React.useState<FilterOptions>({
    search: "",
    city: [],
    subMarket: [],
    baseYear: [],
    campRange: "",
  } as FilterOptions);

  function handleFilterChange(event: ChangeEvent<HTMLInputElement>, name = "") {
    let value: string | string[] | number[] = event?.target?.value;
    const fieldName = event?.target?.name ?? name;
    switch (fieldName) {
      case "city":
      case "subMarket":
        value = [event?.target?.value];
        break;
      case "baseYear":
        value = event?.target ? [event?.target?.value] : (event as unknown as string);
        break;
      case "campRange":
        value = event?.target?.value;
    }
    setValues((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  }

  function handleSearchFilterSubmit(event: any) {
    event.preventDefault();
    searchBuilding({
      variables: {
        search: values.search,
      },
    });
    setValues((prev) => ({
      ...prev,
      city: [],
      subMarket: [],
      baseYear: [],
      campRange: "",
    }));
  }

  function handleMarketFilterSubmit(event: any) {
    values.search = "";
    event.preventDefault();
    const { search, ...rest } = values;
    rest.campRange = rest.campRange.split("-");
    Object.keys(rest).forEach((key) => {
      if (
        Array.isArray(rest[key as keyof typeof rest]) &&
        rest[key as keyof typeof rest].length === 1 &&
        rest[key as keyof typeof rest][0] === ""
      ) {
        delete rest[key as keyof typeof rest];
      }
    });
    searchBuilding({
      variables: {
        filterOptions: { ...rest },
      },
    });
    setValues((prev) => ({
      ...prev,
      search: "",
    }));
  }

  useEffect(() => {
    if (buildingData || error) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [buildingData, error]);
  useEffect(() => {
    const isLoading = loading;
    handleState(!isLoading);
  }, [loading, handleState]);
  return (
    <div className="buildingSearch">
      <InnerHeader props={"Search By Building  and Market"} />
      <section className="search-section mt-6">
        <Container fluid>
          <h2 style={{ color: "#FFFFFF" }}>Search By Building</h2>
          <Row>
            <Col lg={5}>
              <SearchBlock
                value={values}
                disabled={!values.search.trim()}
                handleFilterChange={handleFilterChange}
                handleSearchFilterSubmit={handleSearchFilterSubmit}
                handleMarketFilterSubmit={handleMarketFilterSubmit}
              />
            </Col>
            <Col lg={7}>
              <MapContainer data={buildingData} />
            </Col>
            <Col lg={12}>
              <div ref={ref}>
                {" "}
                <SearchResult error={error} loading={loading} data={buildingData} />
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="d-flex justify-content-end mb-5"></Container>
      </section>
    </div>
  );
}

export default BrowserSearch;
