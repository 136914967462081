import { gql } from "@apollo/client";
export const USERS_LIST = gql`
  query UserList($first: Int, $after: Int, $search: String) {
    userList(first: $first, after: $after, search: $search) {
      totalCount
      edges {
        node {
          id
          firstName
          lastName
          email
          phoneNumber
          market
          companyName
          isActive
          role {
            role
            id
            roleConstraint
          }
        }
      }
    }
  }
`;

export const DELETE_USER = gql`
  query DeleteUserByAdmin($loginName: String, $userId: String) {
    deleteUserByAdmin(loginName: $loginName, userId: $userId) {
      code
      success
      message
      info {
        lastLoginDate
        loginName
        isActive
        isAdmin
        isDeleted
        isNewUser
        isPending
      }
    }
  }
`;
export const SINGLE_USER_DETAILS = gql`
  query SingleUserDetail($id: String) {
    singleUserDetail(id: $id) {
      user {
        firstName
        lastName
        email
        companyName
        phoneNumber
        market
        id
      }
    }
  }
`;

export const STATIC_BUILDING_DATA = gql`
  query GetBuilding($first: Int, $after: Int, $search: String) {
    getBuilding(first: $first, after: $after, search: $search) {
      totalCount
      edges {
        node {
          id
          address
          buildingName
          city
          market
          officeSize
          buildingPhysicalStatus
          buildingSize
          yearBuilt
          class
          floors
          typicalFloorPlate
          realityTaxes
          operatingCosts
          electricity
          totalAdditionalRent
          baseYear
          ownerPropertyManager
          totalDirectAvailable
          totalSubletAvailable
          totalavailable
          occupant
          zoning
          lotSize
          parkingRatio
          created_at
          updated_at
        }
      }
    }
  }
`;

export const EXPORT_BUILDING_DATA = gql`
  query ExportBuilding($first: Int, $after: Int, $search: String) {
    exportBuilding(first: $first, after: $after, search: $search) {
      totalCount
      edges {
        node {
          id
          city
          market
          zoning
          class
          address
          floors
          occupant
          baseYear
          lotSize
          ownerPropertyManager
          officeSize
          buildingPhysicalStatus
          buildingSize
          parkingRatio
          buildingName
          yearBuilt
          typicalFloorPlate
          realityTaxes
          operatingCosts
          electricity
          totalAdditionalRent
          totalavailable
          totalDirectAvailable
          totalSubletAvailable
          buildingSpaces
          latlng
          created_at
          updated_at
        }
        cursor
      }
    }
  }
`;
