export const spacesField = [
  {
    label: "Address City",
    key: "address",
    alternateMatches: ["Address City"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Address City is required",
        level: "error",
      },
    ],
  },
  {
    label: "Market",
    key: "market",
    alternateMatches: ["Market"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Year Built Building Class",
    key: "yearBuilt",
    alternateMatches: ["Year Built Building Class"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Owner",
    key: "ownerPropertyManager",
    alternateMatches: ["Owner"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Building Size",
    key: "buildingSize",
    alternateMatches: ["buildingSize"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "# of Floors",
    key: "floors",
    alternateMatches: ["# of Floors"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Unit Physical Status",
    key: "physicalStatus",
    alternateMatches: ["Unit Physical Status"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Typical Floor Size",
    key: "typicalFloorPlate",
    alternateMatches: ["Typical Floor Size"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Suite Size(sq. ft.)",
    key: "suiteSize",
    alternateMatches: ["Suite Size", "Suite Size(sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Unit / Suite",
    key: "suiteUnit",
    alternateMatches: ["Unit", "Unit / Suite"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Min. Divisible (sq. ft.)",
    key: "minDivisible",
    alternateMatches: ["Min. Divisible", "Min. Divisible (sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Max. Contiguous(sq. ft.)",
    key: "maxContiguous",
    alternateMatches: ["Max. Contiguous", "Max. Contiguous(sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Suite Condition and Notes",
    key: "suiteConditionNotes",
    alternateMatches: ["Suite Condition and Notes"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Lease Type",
    key: "leaseType",
    alternateMatches: ["Lease Type"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Expiry Date",
    key: "expiryDate",
    alternateMatches: ["Expiry Date"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Available On",
    key: "availableOn",
    alternateMatches: ["Available On"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Asking Net Rent ($ per sq. ft.)",
    key: "askingNetRent",
    alternateMatches: ["Asking Net Rent", "Asking Net Rent ($ per sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Additional Rent ($ per sq. ft.)",
    key: "totalAdditionalRent",
    alternateMatches: ["Additional Rent", "Additional Rent ($ per sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Gross Rent ($ per sq. ft.)",
    key: "grossRent",
    alternateMatches: ["Gross Rent", "Gross Rent ($ per sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Parking Ratio # of Stalls",
    key: "parkingRatio",
    alternateMatches: ["Parking Ratio", "Parking Ratio # of Stalls"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Listing Comments",
    key: "listingComments",
    alternateMatches: ["Listing Comments"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Listing Broker",
    key: "listingBroker",
    alternateMatches: ["Listing Broker"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Listing Broker EMAIL",
    key: "listingBrokerEMAIL",
    alternateMatches: ["Listing Broker EMAIL"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Listing Broker PHONE",
    key: "listingBrokerPHONE",
    alternateMatches: ["Listing Broker PHONE"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Vacancy",
    key: "Vacancy",
    alternateMatches: ["Vacancy"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Zoning",
    key: "zoning",
    alternateMatches: ["Zoning"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Days On Market",
    key: "daysOnMarket",
    alternateMatches: ["Days On Market"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Listed On Date",
    key: "listedOnDate",
    alternateMatches: ["Listed On Date"],
    fieldType: {
      type: "input",
    },
  },

  {
    label: "Marketing Comments",
    key: "marketingComments",
    alternateMatches: ["Marketing Comments"],
    fieldType: {
      type: "input",
    },
  },
];

export const stackingField = [
  {
    label: "Office Size (sq. ft.)",
    key: "officeSize",
    alternateMatches: ["Office Size", "Office Size (sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Building Physical Status",
    key: "buildingPhysicalStatus",
    alternateMatches: ["Building Physical Status"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Year Built & Year Renovated",
    key: "yearBuilt",
    alternateMatches: ["Year Built & Year Renovated"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Class",
    key: "class",
    alternateMatches: ["Class"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Floors",
    key: "floors",
    alternateMatches: ["Floors"],
    fieldType: {
      type: "input",
    },
  },
];

const fieldTypeInput = {
  type: "input",
};

export const DealField = [
  {
    label: "ADDRESS",
    key: "address",
    alternateMatches: ["ADDRESS"],
    fieldType: fieldTypeInput,

    validations: [
      {
        rule: "required",
        errorMessage: "Address is required",
        level: "error",
      },
    ],
  },
  {
    label: "COMMENTS",
    key: "comments",
    alternateMatches: ["COMMENTS"],
    fieldType: fieldTypeInput,
  },
  {
    label: "DATE ADDED",
    key: "dateAdded",
    alternateMatches: ["DATE ADDED"],
    fieldType: fieldTypeInput,
  },

  {
    label: "Deal Done",
    key: "dealDone",
    alternateMatches: ["Deal Done"],
    fieldType: fieldTypeInput,
  },
  {
    label: "Fixturing Period",
    key: "fixturn",
    alternateMatches: ["Fixturing Period"],
    fieldType: fieldTypeInput,
  },
  {
    label: "Gross Rent (PSF PA)",
    key: "grossRent",
    alternateMatches: ["Gross Rent (PSF PA)"],
    fieldType: fieldTypeInput,
  },
  {
    label: "Suite",
    key: "suite",
    alternateMatches: ["Suite"],
    fieldType: fieldTypeInput,
  },
  {
    label: "LANDLORD",
    key: "landlord",
    alternateMatches: ["LANDLORD"],
    fieldType: fieldTypeInput,
  },
  {
    label: "NER",
    key: "ner",
    alternateMatches: ["NER"],
    fieldType: fieldTypeInput,
  },
  {
    label: "Net Rent (PSF PA)",
    key: "netRent",
    alternateMatches: ["Net Rent (PSF PA)"],
    fieldType: fieldTypeInput,
  },

  {
    label: "NODE",
    key: "node",
    alternateMatches: ["NODE"],
    fieldType: fieldTypeInput,
  },
  {
    label: "RE FEES ($/SQ. FT)",
    key: "reFees",
    alternateMatches: ["RE FEES ($/SQ. FT)"],
    fieldType: fieldTypeInput,
  },
  {
    label: "Size (SF)",
    key: "size",
    alternateMatches: ["Size (SF)"],
    fieldType: fieldTypeInput,
  },

  {
    label: "SOURCE",
    key: "source",
    alternateMatches: ["SOURCE"],
    fieldType: fieldTypeInput,
  },
  {
    label: "Lease Commencement Date",
    key: "startDate",
    alternateMatches: ["Lease Commencement Date"],
    fieldType: fieldTypeInput,
  },
  {
    label: "TENANT",
    key: "tenant",
    alternateMatches: ["TENANT"],
    fieldType: fieldTypeInput,
  },
  {
    label: "Term (Months)",
    key: "terms",
    alternateMatches: ["Term (Months)"],
    fieldType: fieldTypeInput,
  },
  {
    label: "TI ($/SQ. FT)",
    key: "ti",
    alternateMatches: ["TI ($/SQ. FT)"],
    fieldType: fieldTypeInput,
  },
  {
    label: "TMI ($/SQ.FT)",
    key: "tmi",
    alternateMatches: ["TMI ($/SQ. FT)"],
    fieldType: fieldTypeInput,
  },

  {
    label: "TYPE",
    key: "type",
    alternateMatches: ["TYPE"],
    fieldType: fieldTypeInput,
  },
  {
    label: "Floor",
    key: "floor",
    alternateMatches: ["Floor"],
    fieldType: fieldTypeInput,
  },
  {
    label: "Avg. Net Rent Over Term",
    key: "avgNetRentOverTerm",
    alternateMatches: ["Avg. Net Rent Over Term"],
    fieldType: fieldTypeInput,
  },
  {
    label: "Additional Rent (PSF)",
    key: "totalAdditionalRent",
    alternateMatches: ["Additional Rent (PSF)"],
    fieldType: fieldTypeInput,
  },
  {
    label: "Tenant Cash Allowance (PSF)",
    key: "cashAllowance",
    alternateMatches: ["Tenant Cash Allowance (PSF)"],
    fieldType: fieldTypeInput,
  },
  {
    label: "Model Suite Y/N Cost (PSF)",
    key: "modelSuiteCost",
    alternateMatches: ["Model Suite Y/N Cost (PSF)"],
    fieldType: fieldTypeInput,
  },
  {
    label: "Gross Free Rent",
    key: "netGrossFreeRent",
    alternateMatches: ["Gross Free Rent"],
    fieldType: fieldTypeInput,
  },
  {
    label: "Selling Fee PSF PA (not including listing fee)",
    key: "sellingFeeTotal",
    alternateMatches: ["Selling Fee PSF PA (not including listing fee)"],
    fieldType: fieldTypeInput,
  },
  {
    label: "Listing Fee",
    key: "listingFeeTotal",
    alternateMatches: ["Listing Fee"],
    fieldType: fieldTypeInput,
  },
  {
    label: "GER",
    key: "realGrossRate",
    alternateMatches: ["GER"],
    fieldType: fieldTypeInput,
  },
];
