import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getLocalData } from "../../utils/commonFunctions";
import withoutHeader from "../../utils/hoc/withoutHeader";
const PublicRoutes = () => {
  const { token }: any = getLocalData();

  return !token ? (
    <>
      <div className="contentArea m-0">
        <Outlet />
      </div>
    </>
  ) : (
    <Navigate to="/how-spacecomps-works" />
  );
};
export default withoutHeader(PublicRoutes);
