import bg1 from "../../assets/images/bg/bg1.jpg";
import bg2 from "../../assets/images/bg/bg2.jpg";
import bg3 from "../../assets/images/bg/bg3.jpg";
import bg4 from "../../assets/images/bg/bg4.jpg";
const globalSubTitle = "2 comments, 1 Like";
const globalDesc = "This is a wider card with supporting text below as a natural lead-in to additional content.";

export const BlogData = [
  {
    image: bg1,
    title: "This is simple blog test",
    subtitle: globalSubTitle,
    description: globalDesc,
    btnbg: "primary",
  },
  {
    image: bg2,
    title: "Lets be simple blog",
    subtitle: globalSubTitle,
    description: globalDesc,
    btnbg: "primary",
  },
  {
    image: bg3,
    title: "Don't Lamp blog",
    subtitle: globalSubTitle,
    description: globalDesc,
    btnbg: "primary",
  },
  {
    image: bg4,
    title: "Simple is beautiful blog",
    subtitle: globalSubTitle,
    description: globalDesc,
    btnbg: "primary",
  },
];
