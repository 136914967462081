import { IDefaultAuthFormFields } from "../../ts/interfaces/authFields";
import { Placeholder } from "./placeholder";
export const defaultLoginFields: IDefaultAuthFormFields[] = [
  { title: "Email", type: "", name: "email", value: "", placeholder: Placeholder.Email, maxLength: "" },
  {
    title: "Password",
    name: "password",
    type: "password",
    value: "",
    placeholder: Placeholder.Password,
    maxLength: "",
  },
];
export const defaultSignupFields: IDefaultAuthFormFields[] = [
  {
    title: "Email",
    type: "",
    name: "email",
    value: "",
    placeholder: Placeholder.Email,
    maxLength: "100",
    readonly: false,
  },
  {
    title: "Role",
    type: "text",
    name: "role",
    value: "",
    maxLength: "",
    readonly: true,
  },
];
export const defaultForgetPasswordFields: IDefaultAuthFormFields[] = [
  { title: "Email", type: "", name: "email", value: "", placeholder: Placeholder.Email, maxLength: "" },
];
export const defaultResetPasswordFields: IDefaultAuthFormFields[] = [
  {
    title: "New Password",
    name: "password",
    type: "password",
    value: "",
    placeholder: Placeholder.Password,
    maxLength: "",
  },
  {
    title: "Confirm Password",
    name: "confirmPassword",
    type: "password",
    value: "",
    placeholder: Placeholder.Password,
    maxLength: "",
  },
];
export const defaultAddCreditFields: IDefaultAuthFormFields[] = [
  {
    title: "ADDRESS",
    type: "search",
    name: "address",
    value: "",
    placeholder: "Enter Address",
    maxLength: "",
  },
  {
    title: "TENANT",
    type: "text",
    name: "tenant",
    value: "",
    placeholder: "Enter Tenant",
    maxLength: "",
  },
  {
    title: "NODE",
    type: "text",
    name: "node",
    value: "",
    placeholder: "Enter Node",
    maxLength: "",
  },

  {
    title: "LANDLORD",
    type: "text",
    name: "landlord",
    value: "",
    placeholder: "Enter Landlord",
    maxLength: "",
  },
  {
    title: "SIZE",
    type: "text",
    name: "size",
    value: "",
    placeholder: "Enter Size(SQ. FT.)",
    maxLength: "",
  },
  {
    title: "TERM",
    type: "text",
    name: "terms",
    value: "",
    placeholder: "Enter Term (MTHS)",
    maxLength: "",
  },
  {
    title: "NET RENT",
    type: "text",
    name: "netRent",
    value: "",
    placeholder: "Enter Net Rent($/SQ. FT.)",
    maxLength: "",
  },
  {
    title: "TMI",
    type: "text",
    name: "tmi",
    value: "",
    placeholder: "Enter TMI($/SQ. FT.)",
    maxLength: "",
  },
  {
    title: "GROSS RENT",
    type: "text",
    name: "grossRent",
    value: "",
    placeholder: "Enter Gross Rent($/SQ. FT)",
    maxLength: "",
  },
  {
    title: "TI",
    type: "text",
    name: "ti",
    value: "",
    placeholder: "Enter TI($/SQ. FT)",
    maxLength: "",
  },
  {
    title: "FIXTURING PERIOD",
    type: "text",
    name: "fixturn",
    value: "",
    placeholder: "Enter Fixturing Period(MTHS)",
    maxLength: "",
  },
  {
    title: "RE FEES",
    type: "text",
    name: "reFees",
    value: "",
    placeholder: "Enter Re Fees($/SQ. FT)",
    maxLength: "",
  },
  {
    title: "NER",
    type: "text",
    name: "ner",
    value: "",
    placeholder: "Enter Ner($/SQ. FT)",
    maxLength: "",
  },
  {
    title: "TYPE",
    type: "text",
    name: "type",
    value: "",
    placeholder: "Enter Type",
    maxLength: "",
  },
  {
    title: "DEAL DONE",
    type: "text",
    name: "dealDone",
    value: "",
    placeholder: "Enter Deal Done",
    maxLength: "",
  },
  {
    title: "Lease Commencement Date",
    type: "date",
    name: "startDate",
    value: "",
    placeholder: "Enter Start Date",
    maxLength: "",
  },

  {
    title: "COMMENTS",
    type: "text",
    name: "comments",
    value: "",
    placeholder: "Enter Comments",
    maxLength: "",
  },
  {
    title: "SOURCE",
    type: "text",
    name: "source",
    value: "",
    placeholder: "Enter Source",
    maxLength: "",
  },
  {
    title: "DATE ADDED",
    type: "date",
    name: "dateAdded",
    value: "",
    placeholder: "Enter Date Ended",
    maxLength: "",
  },
  {
    title: "Listing Commissions",
    type: "text",
    name: "listingCommissions",
    value: "",
    placeholder: "Enter Listing Commissions",
    maxLength: "",
  },
  {
    title: "Selling Commissions",
    type: "text",
    name: "sellingCommissions",
    value: "",
    placeholder: "Enter Selling Commissions",
    maxLength: "",
  },
  {
    title: "Discount Rate",
    type: "text",
    name: "discountRate",
    value: "",
    placeholder: "Enter Discount Rate",
    maxLength: "",
  },
  {
    title: "LLW",
    type: "text",
    name: "llw",
    value: "",
    placeholder: "Enter LLW",
    maxLength: "",
  },
  {
    title: "LTO",
    type: "text",
    name: "lto",
    value: "",
    placeholder: "Enter LTO",
    maxLength: "",
  },
  {
    title: "Net Free Rent (months)",
    type: "text",
    name: "netReentMonthly",
    value: "",
    placeholder: "Enter Net Free Rent (months)",
    maxLength: "",
  },
  {
    title: "Suite",
    type: "text",
    name: "suite",
    value: "",
    placeholder: "Enter Suite",
    maxLength: "",
  },
  {
    title: "FLOOR",
    type: "text",
    name: "floor",
    value: "",
    placeholder: "Enter Floor",
    maxLength: "",
  },
  {
    title: "Avg. Net Rent Over Term",
    type: "text",
    name: "avgNetRentOverTerm",
    value: "",
    placeholder: "Enter Avg. Net Rent Over Term",
    maxLength: "",
  },
  {
    title: "Total Additional Rent",
    type: "text",
    name: "totalAdditionalRent",
    value: "",
    placeholder: "Enter Total Additional Rent",
    maxLength: "",
  },
  {
    title: "Cash Allowance (PSF)",
    type: "text",
    name: "cashAllowance",
    value: "",
    placeholder: "Enter Cash Allowance (PSF)",
    maxLength: "",
  },
  {
    title: "Model Suite Cost (PSF)",
    type: "text",
    name: "modelSuiteCost",
    value: "",
    placeholder: "Enter Model Suite Cost (PSF)",
    maxLength: "",
  },
  {
    title: "Net/Gross Free Rent (Months)",
    type: "text",
    name: "netGrossFreeRent",
    value: "",
    placeholder: "Enter Net/Gross Free Rent (Months)",
    maxLength: "",
  },
  {
    title: "Selling Fee Total (PSF)",
    type: "text",
    name: "sellingFeeTotal",
    value: "",
    placeholder: "Enter Selling Fee Total (PSF)",
    maxLength: "",
  },
  {
    title: "Listing Fee Total (PSF)",
    type: "text",
    name: "listingFeeTotal",
    value: "",
    placeholder: "Enter Listing Fee Total (PSF",
    maxLength: "",
  },
  {
    title: "GER- Real Gross Rate",
    type: "text",
    name: "realGrossRate",
    value: "",
    placeholder: "Enter GER- Real Gross Rate",
    maxLength: "",
  },
];

export const defaultAdminDealsFields: IDefaultAuthFormFields[] = [
  {
    title: "Numbers of Months",
    name: "noOfMonths",
    type: "text",
    value: "",
    placeholder: "Enter Number of Months",
    maxLength: "3",
  },
  {
    title: "Net Rent",
    name: "netRent",
    type: "text",
    value: "",
    placeholder: "Enter Net Rent",
    maxLength: "6",
  },
];
