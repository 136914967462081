import React, { useMemo, useEffect, useState } from "react";
import SpaceCompsTable from "../../components/layout/SpaceCompsTable/SpaceCompsTable";
import { stackingColumn } from "../../utils/constants/tableColumns";
import { STATIC_BUILDING_DATA } from "../../graphql/queries/usersAdmin";
import { gql, useLazyQuery } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { IconButton, Tooltip, Typography } from "@mui/material";
import ImportExcel from "../../components/layout/Excel/ImportExcel";
import { Result } from "react-spreadsheet-import/types/types";
import { saveExcelData } from "../../utils/importExcel";
import UploadIcon from "@mui/icons-material/Backup";
import { stackingField } from "../../utils/constants/TableField";
import FileDownloadIcon from "@mui/icons-material/CloudDownload";
import { ExportToCsv } from "export-to-csv";
import { client } from "../../graphql/apollo-client";
import { useAuth } from "../../contexts/auth/provideAuth";
import { toast } from "react-toastify";
const Stacking = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { handleState } = useAuth();
  const classes = useStyles();

  const [buildinglisting, { data, loading }] = useLazyQuery<any>(STATIC_BUILDING_DATA, {
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    buildinglisting({
      variables: {
        first: 50,
        after: 0,
        search: "",
      },
    });
  }, [buildinglisting]);
  const stackingData = useMemo(
    () =>
      data?.getBuilding?.edges.map((d: any, index: number) => ({
        ...d?.node,
        id: index + 1,
      })) ?? [],
    [data]
  );

  useEffect(() => {
    const Loading = loading || !data?.getBuilding?.edges;
    handleState(!Loading);
  }, [loading, handleState, data?.getBuilding?.edges]);
  const onImportExcelOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };
  const handleExportData = () => {
    csvExporter.generateCsv(stackingData);
  };
  const onSubmit = async (submitData: Result<string>) => {
    // instead of gql``, pass mutation which get called to save data
    try {
      const stackResponse = await saveExcelData(submitData.validData, client, gql``);
      if (stackResponse.data) {
        toast.success("Data uploaded successfully");
      }
    } catch (e: any) {
      const errorMessage = e?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };

  return (
    <div className={`${classes.tableDesined} form-group form-check`}>
      <SpaceCompsTable
        columns={stackingColumn}
        data={stackingData}
        getRowId={(row) => row.id}
        enableColumnFilterModes
        enableColumnOrdering
        enableGrouping
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enablePinning
        enableTopToolbar={true}
        enableToolbarInternalActions={true}
        muiTableHeadCellColumnActionsButtonProps={{
          sx: {
            color: "#FFF",
          },
        }}
        muiTableHeadCellDragHandleProps={{
          sx: {
            color: "#FFF",
          },
        }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "0",
            border: "1px dashed #e0e0e0",
          },
        }}
        renderTopToolbarCustomActions={() => (
          <div className={classes.topToolbarBoxStyling}>
            <div>
              <Typography component="span" variant="h4">
                STACKING
              </Typography>
            </div>
            <div className={classes.iconContainer}>
              <Tooltip title="Upload Excel">
                <IconButton onClick={onImportExcelOpen}>
                  <UploadIcon color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Export All Data">
                <IconButton onClick={handleExportData}>
                  <FileDownloadIcon color="primary" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
      />
      <ImportExcel isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} fields={stackingField} />
    </div>
  );
};
const csvOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: stackingColumn.map((c) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);
export default Stacking;
const useStyles: any = makeStyles({
  tableDesined: {
    textAlign: "center",
    margin: "auto",
    marginTop: "40px",
    width: "78vw",
    display: "block",
    overflowX: "auto",
  },
  stackStyle: {
    width: "100%",
    textAlign: "center",
  },
  iconContainer: {
    display: "flex",
  },
  exportCssStyle: {
    width: "25%",
  },
  topToolbarBoxStyling: {
    display: "flex",
    flex: 2,
    justifyContent: "space-between",
  },
});
