import { gql } from "@apollo/client";
export const GET_DEAL_NET_RENT_BY_ID = gql`
  query GetDealNetRentById($dealId: String) {
    getDealNetRentById(dealId: $dealId) {
      totalCount
      edges {
        node {
          rentMonthly
          presentValue
          noOfMonths
          netRent
          id
          dealId
          createdOn
        }
      }
    }
  }
`;
