import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const getPreviousYear = (value: number) => {
  const max = new Date().getFullYear();
  const min = max - value;
  const years = [];
  for (let i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};

export const formatNumberIndianStyle = (number: number): string => {
  const formatter = new Intl.NumberFormat("en-US");
  return formatter.format(number);
};

export const generateRanges = (start: number, end: number, step: number): string[] => {
  const ranges: string[] = [];
  while (start <= end) {
    const startFormatted = start;
    const endFormatted = start + step;
    ranges.push(`${startFormatted + 1}-${endFormatted}`);
    start += step;
  }
  return ranges;
};

export const useQueryData = () => {
  return new URLSearchParams(useLocation().search);
};

export function useScrollDisplay() {
  const location = useLocation();
  const [display, setDisplay] = useState<boolean>(false);
  useEffect(() => {
    if (window.scrollY === 0 && location.pathname === "/") {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
    const handleScroll = () => {
      setDisplay(window.scrollY === 0 && location.pathname === "/");
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location, display]);
  return display;
}

export const getLocalData = () => {
  try {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const isAdmin = localStorage.getItem("isAdmin");
    const loginName = localStorage.getItem("loginName");
    const loginInfoJson = localStorage.getItem("loginInfo");
    const loginInfo = loginInfoJson ? JSON.parse(loginInfoJson) : {};
    return { token, email, isAdmin, loginName, loginInfo };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const setLocalData = (logindata: any) => {
  const { login } = logindata;
  const { token, info } = login;
  const { loginName, isAdmin, firstName } = info;
  localStorage.setItem("loginInfo", JSON.stringify(info));
  localStorage.setItem("token", token);
  localStorage.setItem("email", loginName);
  localStorage.setItem("isAdmin", isAdmin);
  localStorage.setItem("loginName", firstName);
};

export const getFilteredTableData = (data: any) => {
  return data?.map((d: any, index: number) => ({
    ...d?.node,
    indexId: index + 1,
  }));
};

export function convertTypeObj(obj: any, keyToLeaveString: any) {
  for (const key in obj) {
    if (!keyToLeaveString.includes(key)) {
      obj[key] = parseFloat(obj[key]);
    }
  }
  return obj;
}

export const removeNonNumericValue = (item: any) => {
  const newItem = JSON.parse(JSON.stringify(item));
  Object.keys(newItem).forEach((key) => {
    if (newItem[key] === null || newItem[key] === "NaN" || newItem[key] === "" || newItem[key] === "Infinity") {
      newItem[key] = 0;
    }
  });
};

export const removeUnusedKeys = (nonNumericdata: any) => {
  return nonNumericdata.map((item: any) => {
    const {
      __typename,
      targetId,
      id,
      total_vacancy_average,
      sublet_average,
      direct_average,
      total_floors,
      created_at,
      updated_at,
      latlng,
      indexId,
      ...rest
    } = item;
    removeNonNumericValue(rest);
    return { ...rest };
  });
};
