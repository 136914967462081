import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminDashboard from "../../../pages/admin-pages/AdminDashboard";
import PrivateRoute from "../PrivateRoutes";
import ProjectTables from "../../../pages/admin-pages/ProjectTables";
import ErrorPage from "../../../pages/ErrorPage";
import AdminLogin from "../../../pages/auth/admin/AdminLogin";
import UserManagement from "../../../pages/admin-pages/UserManagement";
import AdminForgotPassword from "../../../pages/auth/admin/AdminForgotPassword";
import StaticData from "../../../pages/admin-pages/StaticData";
import DynamicData from "../../../pages/admin-pages/DynamicData";
import Spaces from "../../../pages/admin-pages/Spaces";
import Stacking from "../../../pages/admin-pages/Stacking";
import Deals from "../../../pages/admin-pages/Deals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const index = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<PrivateRoute path="admin" />}>
          <Route path="/dashboard" element={<AdminDashboard />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/management-static" element={<StaticData />} />
          <Route path="/deals" element={<Deals />} />
          <Route path="/management-Dynamic" element={<DynamicData />} />
          <Route path="/project-tables" element={<ProjectTables />} />
          <Route path="/spaces" element={<Spaces />} />
          <Route path="/stacking" element={<Stacking />} />
          {/* <Route path="/deals" element={<Deal />} /> */}
        </Route>
        <Route path="forgot-password" element={<AdminForgotPassword />} />
        <Route path="/login" element={<AdminLogin />} />
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
      <ToastContainer />
    </React.Fragment>
  );
};

export default index;
