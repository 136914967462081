import React, { createContext, useContext } from "react";
import { useDataContext } from "./useDataContext";

export interface DefaultContextProps {
  detailsData: any;
  setDetails: any;
}

const defaultContext: DefaultContextProps = {
  detailsData: {},
  setDetails: () => null,
};

const dataContext = createContext(defaultContext);

export function ProvideData({ children }: any) {
  const data = useDataContext();
  return <dataContext.Provider value={data}>{children}</dataContext.Provider>;
}

export function useData() {
  return useContext(dataContext);
}
