export const fields = [
  {
    label: "Address",
    key: "address",
    alternateMatches: ["Address", "Address."],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Address is required",
        level: "error",
      },
    ],
  },
  {
    label: "Building Name Complex Name",
    key: "buildingName",
    alternateMatches: ["Building Name Complex Name", "Building Name Complex Name."],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "City",
    key: "city",
    alternateMatches: ["City"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Market",
    key: "market",
    alternateMatches: ["Market"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Building Size (sq. ft.)",
    key: "buildingSize",
    alternateMatches: ["Building Size", "Building Size (sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Office Size (sq. ft.)",
    key: "officeSize",
    alternateMatches: ["Office Size", "Office Size (sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Building Physical Status",
    key: "buildingPhysicalStatus",
    alternateMatches: ["Building Physical Status"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Year Built & Year Renovated",
    key: "yearBuilt",
    alternateMatches: ["Year Built & Year Renovated"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Class",
    key: "class",
    alternateMatches: ["Class"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Floors",
    key: "floors",
    alternateMatches: ["Floors"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Typical Floor Plate (sq.ft)",
    key: "typicalFloorPlate",
    alternateMatches: ["Typical Floor Plate (sq.ft)", "Typical Floor Plate (sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Realty Taxes ($ per sq. ft.)",
    key: "realityTaxes",
    alternateMatches: ["Realty Taxes ($ per sq.ft)", "Realty Taxes ($ per sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Operating Costs ($ per sq. ft.)",
    key: "operatingCosts",
    alternateMatches: ["Operating Costs ($ per sq.ft)", "Operating Costs ($ per sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Electricity ($ per sq. ft.)",
    key: "electricity",
    alternateMatches: ["Electricity ($per sq.ft)", "Electricity ($ per sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },

  {
    label: "Total Additional Rent ($ per sq. ft.)",
    key: "totalAdditionalRent",
    alternateMatches: ["Total Additional Rent ($per sq.ft)", "Total Additional Rent ($ per sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Base Year",
    key: "baseYear",
    alternateMatches: ["Base Year"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Owner Property Manager",
    key: "ownerPropertyManager",
    alternateMatches: ["Owner Property Manager"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Total Direct Available (sq. ft.)",
    key: "totalDirectAvailable",
    alternateMatches: ["Total Direct Available (sq.ft)", "Total Direct Available (sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Total Sublet Available (sq. ft.)",
    key: "totalSubletAvailable",
    alternateMatches: ["Total Sublet Available (sq.ft)", "Total Sublet Available (sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Total Available (sq. ft.)",
    key: "totalavailable",
    alternateMatches: ["Total Available (sq.ft)", "Total Available (sq. ft.)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Occupant",
    key: "occupant",
    alternateMatches: ["Occupant"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Zoning",
    key: "zoning",
    alternateMatches: ["Zoning"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Lot Size (Acres)",
    key: "lotSize",
    alternateMatches: ["Lot Size (Acres)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Parking Ratio # of stalls",
    key: "parkingRatio",
    alternateMatches: ["Parking Ratio # of stalls"],
    fieldType: {
      type: "input",
    },
  },
];
