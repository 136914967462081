import React from "react";
import Slider from "react-slick";
import NextArrow from "../../../common/NextArrow";
import PrevArrow from "../../../common/PrevArrow";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "@mui/material";
export interface SliderPropsType {
  autoplaySpeed?: number;
  children: any;
  prevBtn?: any;
  nextBtn?: any;
  data: any;
  slides: any;
  slidesToScroll: number;
  prevButtonStyle: any;
  NextButtonStyle: any;
  autoplay?: boolean;
  arrow?: boolean;
  maxwidth?: boolean;
}
const CommonSlider = ({
  children,
  arrow = true,
  data,
  slides,
  prevButtonStyle,
  NextButtonStyle,
  autoplaySpeed,
  slidesToScroll,
  autoplay,
  prevBtn,
  nextBtn,
  maxwidth = "lg",
}: any) => {
  const getSlideToshow = (num: number) => {
    const totalCount = data.length ?? 0;
    if (totalCount > num) {
      return num;
    }
    return totalCount;
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: getSlideToshow(slides),
    slidesToScroll: slidesToScroll || 3,
    arrow: arrow || true,
    initialSlide: 0,
    autoplay: autoplay || false,
    _autoplaySpeed: autoplaySpeed,
    get autoplaySpeed() {
      return this._autoplaySpeed;
    },
    set autoplaySpeed(value) {
      this._autoplaySpeed = value;
    },
    nextArrow: arrow && <NextArrow nextButton={nextBtn} customStyle={NextButtonStyle} />,
    prevArrow: arrow && <PrevArrow prevButton={prevBtn} customStyle={prevButtonStyle} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: getSlideToshow(slides),
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: getSlideToshow(2),
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: getSlideToshow(1),
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container maxWidth={maxwidth}>
      <Slider {...settings}> {children} </Slider>
    </Container>
  );
};

export default CommonSlider;
