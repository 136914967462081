import React from "react";
import { Container, Row, Col } from "reactstrap";
import { makeStyles } from "@mui/styles";
import WorkComponent from "./WorkComponent";
import { Button, Stack } from "@mui/material";
import CreditTextComponent from "./CreditTextComponent";
import { useNavigate } from "react-router-dom";
import InnerHeader from "../../../components/Header/InnerHeader";

const HowToWork = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const handleSharing = () => {
    navigate("/profile");
  };

  const handleSeeking = () => {
    navigate("/building-search");
  };

  return (
    <div className="buildingSearch">
      {/* <GlobalHeader data={info} /> */}
      <InnerHeader props={"How SpaceComps Works"} />
      <section className="search-section">
        <Container fluid>
          <Row sx={{ width: "100%" }}>
            <Col>
              <WorkComponent />
              <CreditTextComponent />
              <Stack direction="row" sx={{ paddingTop: "20px" }} spacing={2}>
                <Button
                  className={classes.sharingButton}
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleSharing}
                  variant="contained"
                >
                  Sharing Comps
                </Button>
                <Button
                  className={classes.seekingButton}
                  sx={{ textTransform: "capitalize" }}
                  onClick={handleSeeking}
                  variant="contained"
                >
                  Seeking Comps{" "}
                </Button>
              </Stack>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default HowToWork;

const useStyles = makeStyles({
  titleText: {
    fontFamily: "Inter !important",
    fontSize: "40px !important",
    fontWeight: "700 !important",
    lineHeight: "54px !important",
    letterSpacing: "0.01em !important",
    color: "#FFFF",
  },

  img: {
    objectPosition: "top right",
    objectFit: "none",
    minWidth: "85%",
    position: "relative",
  },

  sharingButton: {
    fontFamily: "Roboto !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    LineHeight: "26px !important",
    background: "#FD6262 !important",
    borderRadius: "0 !important",
  },
  seekingButton: {
    fontFamily: "Roboto !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    LineHeight: "26px !important",
    background: "#FFFFFF !important",
    borderRadius: "0 !important",
    color: "#FD6262 !important",
    border: "1px solid #10257F !important",
  },
  creditTitle: {
    fontFamily: "Roboto !important",
    fontSize: "44px !important",
    fontWeight: "700 !important",
    lineHeight: "48px !important",
    textAlign: "left",
    color: "#FFFFFF",
  },
});
