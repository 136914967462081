import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/auth/provideAuth";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const { handleHeader } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    useEffect(() => {
      handleHeader(true);
    }, [location.pathname, handleHeader]);
    return <Component {...props} location={location} params={params} navigate={navigate} />;
  }
  return ComponentWithRouterProp;
}

export default withRouter;
