import { ILocaionPoint, IMapDefaultValue, IsliderSettings, IHotelCards } from "../../ts/interfaces/mapDefaultValue";
import firstImage from "../../assets/images/logos/first.png";
import secondImage from "../../assets/images/logos/second.png";
import thirdImage from "../../assets/images/logos/third.png";

export const defaultCenter: ILocaionPoint = {
  lat: 43.6706285,
  lng: -79.3926029,
};

export const Locations: IMapDefaultValue[] = [
  {
    name: "Location 1",
    location: {
      lat: 41.3954,
      lng: 2.162,
    },
  },
  {
    name: "Location 2",
    location: {
      lat: 41.3917,
      lng: 2.1649,
    },
  },
  {
    name: "Location 3",
    location: {
      lat: 41.3773,
      lng: 2.1585,
    },
  },
  {
    name: "Location 4",
    location: {
      lat: 41.3797,
      lng: 2.1682,
    },
  },
  {
    name: "Location 5",
    location: {
      lat: 41.4055,
      lng: 2.1915,
    },
  },
];

export const sliderSettings: IsliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
};

export const hotelCards: IHotelCards[] = [
  {
    imageSrc: firstImage,
    description: "firstcard",
    pricingText: "$3,20,000",
    address: "secondcard",
  },
  {
    imageSrc: secondImage,
    description: "5i large design apartment with terrace",
    pricingText: "$3,20,000",
    address: "Ravine Road,Toronto, ON, Canada",
  },
  {
    imageSrc: thirdImage,
    description: "Large 4-room apartment with a beautiful terrace",
    pricingText: "$3,20,000",
    address: "7117 Gilley Ave, Burnaby, Canada",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1559508551-44bff1de756b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=387&q=80",

    description: "5i large design apartments with terrace",
    pricingText: "$3,20,000",
    address: "7117 Gilley Aves, Burnaby, Canada ",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1616940844649-535215ae4eb1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",

    description: "5i large design apartment with terraces",
    pricingText: "$3,20,000",
    address: "Ravine Road,Toronto, ON, Canada",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1599619351208-3e6c839d6828?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=872&q=80",

    description: "Large 4-room apartment with a beautiful terrace",
    pricingText: "$3,20,000",
    address: "7117 Gilley Av, Burnaby, Canada",
  },
];
