import React from "react";
import { useNavigate } from "react-router-dom";
import withoutHeader from "../utils/hoc/withoutHeader";

function ErrorPage() {
  const navigate = useNavigate();
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <h1 style={{ color: "#FFFF" }} className="display-1 fw-bold">
          404
        </h1>
        <p style={{ color: "#FFFF" }} className="fs-3">
          <span className="text-danger">Opps!</span> Page not found.
        </p>
        <p className="lead" style={{ color: "#FFFF" }}>
          The page you’re looking for doesn’t exist.
        </p>
        <button onClick={() => navigate("/")} className="btn btn-primary">
          Go Home
        </button>
      </div>
    </div>
  );
}
export default withoutHeader(ErrorPage);
