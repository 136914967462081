import React from "react";
import { Login } from "../../../components/layout/Auth";
import { LOGIN } from "../../../graphql/queries/login";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "../../../components/layout/Navigations/Admin/AdminNavbar";
import { toast } from "react-toastify";
import { setLocalData } from "../../../utils/commonFunctions";
const AdminLogin = () => {
  const navigate = useNavigate();
  const [handleLogin, { loading }] = useLazyQuery(LOGIN, { fetchPolicy: "network-only" });
  const handleError = (error: ApolloError) => {
    const errorMessage = error.message;
    toast.error(errorMessage);
  };

  const onSubmit = async (values: { [key: string]: string }) => {
    try {
      const { error, data: logindata } = await handleLogin({
        variables: {
          loginName: values.email,
          password: values.password,
        },
      });
      if (error) {
        handleError(error);
        return;
      }
      if (!logindata?.login?.info?.isAdmin) {
        toast.error("User not registered");
        return;
      }
      setLocalData(logindata);
      toast.success("Login successful");
      navigate("/admin/dashboard");
    } catch (error: any) {
      const errorMessage = error?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };
  return (
    <>
      <AdminNavbar />
      <Login onSubmitCallback={onSubmit} navigateBaseUrl="/admin/" loading={loading} />
    </>
  );
};

export default AdminLogin;
