import { gql } from "@apollo/client";
export const FORGOTPASSWORD = gql`
  query ForgotPassword($loginName: String) {
    forgotPassword(loginName: $loginName) {
      code
      success
      message
      info {
        id
        entityOid
        loginName
        lastLoginDate
        datePwChanged
        isNewUser
        isPending
        isAdmin
        isActive
        createdOn
        isDeleted
      }
    }
  }
`;
