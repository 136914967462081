import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Link, NavLink, useLocation } from "react-router-dom";

import LogoLight from "../../../assets/images/dark/logo.png";
import { makeStyles } from "@mui/styles";
import { Button, Container } from "reactstrap";
import { userDropdownOptions } from "../../../utils/constants/Navigations";
import { DropDown } from "../DropDown";
import user1 from "../../../assets/images/users/user4.jpg";
import { getLocalData } from "../../../utils/commonFunctions";
import { useAuth } from "../../../contexts/auth/provideAuth";

const Navbar = () => {
  const [activeClass, setActiveClass] = useState("normal");
  const classes = useStyles();
  const { header } = useAuth();
  const { pathname } = useLocation();
  const { token, loginName, email }: any = getLocalData();

  const getClassName = (() => {
    if (pathname === "/building-search") return "buildingSearch";
    if (!header) return "white-header";
    return "banner-caption";
  })();
  const btnName = {
    name: pathname === "/login" ? "Get Started" : "Login",
    pathname: pathname === "/login" ? "/getstarted" : "/login",
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let activeClass1 = "activeHeader";
      if (window.scrollY < 160) {
        activeClass1 = "HeaderAppbar";
      }
      setActiveClass(activeClass1);
    });
  }, [setActiveClass]);

  const navTitle = (
    <div className="d-flex align-items-center gap-2 avtar">
      <span className={classes.username}>{loginName !== "null" ? loginName : email}</span>
      <img src={user1} alt="profile" className="rounded-circle" width="40" />
    </div>
  );
  const filter = activeClass === "HeaderAppbar" || activeClass === "normal";
  const getLogo = LogoLight;

  return (
    <div className={getClassName}>
      <Container style={{ position: "fixed", zIndex: 999 }}>
        <Box sx={{ flexGrow: 1, py: 2 }}>
          <AppBar
            className={`${!filter ? classes.whiteheader : classes.transparentheader} `}
            elevation={pathname === "/building-search" ? 1 : 0}
          >
            <Toolbar sx={{ flexWrap: "wrap" }}>
              <Box component="div" sx={{ flexGrow: 1 }}>
                <div className={` ${classes.beforeScroll}`}>
                  <Link to="/">
                    <div className="logo_body ">
                      <div className="logo">
                        <img src={getLogo} alt="" />
                      </div>
                      <span className={classes.logoText}>SpaceComps</span>
                    </div>
                  </Link>
                </div>
              </Box>

              <NavLink
                to={!token ? btnName.pathname : "/how-spacecomps-works"}
                style={{ textDecoration: "none" }}
                className="mx-1"
              >
                <Button className="btn btn-primary btn-navheader">
                  {!token ? btnName.name : "How SpaceComps Works"}
                </Button>
              </NavLink>
              {token && (
                <div className="dropdown">
                  <DropDown title={navTitle} options={[...userDropdownOptions]} />
                </div>
              )}
            </Toolbar>
          </AppBar>
        </Box>
      </Container>
    </div>
  );
};
export default Navbar;

const useStyles = makeStyles({
  styleActive: {
    paddingLeft: "0px",
    paddingRight: "0px",
    "@media (max-width: 600px)": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },
  logoText: {
    color: "#FFFF",
  },

  transparentheader: {
    padding: "7px 0",
    background: "none !important",
    boxShadow: "none !important",
  },
  whiteheader: {
    padding: "7px 0",
    // background: "#2E3642 !important",
    display: "none !important",
    boxShadow: "none !important",
  },

  beforeScroll: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  username: {
    fontSize: "16px",
    fontWeight: 600,
    color: "white !important",
  },

  spaceStyle: {
    fontFamily: "Poppins !important",
    fontStyle: "italic",
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "34px !important",
    textTransform: "capitalize",
    letterSpacing: "0.5px !important",
  },
  icon: {
    color: "#FFF",
    "&:hover": {
      color: "#00C194",
    },
  },
});
