import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Container, Card, CardBody, CardSubtitle, CardText, Button } from "reactstrap";
import { UserRole } from "../../utils/constants/roleData";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import BoxImage from "../../assets/images/dark/Image-01.png";
import arrow from "../../assets/images/dark/arrow.png";

const GetDesribe = () => {
  const classes = useStyles();

  return (
    <>
      <section className="my-5">
        <Container>
          <Grid container spacing={2} className={classes.parentDiv}>
            <Grid item xs={5} className={classes.imageBox}>
              <Box>
                <Typography variant="subtitle1" component="h4" className={classes.title}>
                  How Would You Describe <br></br> Yourself
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Grid container spacing={2} className={classes.cardDiv}>
                <Grid item md={4}>
                  <div className="product_parrent_first">
                    {UserRole.firstBox.map((item, index) => {
                      return (
                        <Card key={index} className="product_box mb-3">
                          <CardBody>
                            <Box sx={{ mb: 5 }}>
                              <img src={item.img} alt="img" />
                            </Box>
                            <CardSubtitle className="mb-2 subtitle_cards" tag="h6">
                              {item.title}
                            </CardSubtitle>
                            <CardText className="title_cards">{item.description}</CardText>
                            <Link to="/signup" state={item?.title}>
                              <Button className="btn-next">
                                Next{" "}
                                <span style={{ padding: "4px" }}>
                                  <img src={arrow} alt="img" />
                                </span>
                              </Button>
                            </Link>
                            <Box className={classes.cardBody}></Box>
                          </CardBody>
                        </Card>
                      );
                    })}
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className="product_parrent_second second_col">
                    {UserRole.secondBox.map((item, index) => {
                      return (
                        <Card key={index} className="product_box mb-3">
                          <CardBody>
                            <Box sx={{ mb: 5 }}>
                              <img src={item.img} alt="img" />
                            </Box>
                            <CardSubtitle className="mb-2 subtitle_cards" tag="h6">
                              {item.title}
                            </CardSubtitle>
                            <CardText className="title_cards">{item.description}</CardText>

                            <Link to="/signup" state={item?.title}>
                              <Button className="btn-next">
                                Next{" "}
                                <span style={{ padding: "4px" }}>
                                  <img src={arrow} alt="img" />
                                </span>
                              </Button>
                            </Link>
                            <Box className={classes.cardBody}></Box>
                          </CardBody>
                        </Card>
                      );
                    })}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
};

export default GetDesribe;

const useStyles = makeStyles({
  imageBox: {
    backgroundImage: `url(${BoxImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    backgroundPosition: "center",
    backgroundAttachment: "scroll",
    padding: "0px !important",
    borderRight: "1px solid #3F4349 !important",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  parentDiv: {
    border: "1px solid #3F4349",
    height: "626px",
  },
  title: {
    fontFamily: "Poppins !important",
    fontSize: "30px !important",
    fontWeight: "600 !important",
    lineHeight: "42px !important",
    textAlign: "center !important" as any,
    color: "#FFFF",
  },
  cardDiv: {
    justifyContent: "center !important",
  },
  cardBody: {
    borderBottom: "4px solid #3F4349 !important",
    borderRadius: "6px",
    position: "relative",
    top: "15px",
  },
});
