const errorPassword =
  "Password Should Contains Atleast 8 Charaters and Containing Special Character Uppercase,Lowercase and Numbers";

export const errorMessages: {
  [name: string]: string;
} = {
  required: "The Field is Required",
  firstName: "Please Enter Only Alphanumeric Characters",
  lastName: "Please Enter Only Alphanumeric Characters",
  name: "Please Enter Your Name",
  market: "Please Enter Valid Market Interest",
  email: "Please Enter a Valid Email Address.",
  password: errorPassword,
  phoneNumber: "Invalid Phone Number",
  companyName: "Please Enter Valid Company Name",
  matchPassword: "Password and Confirm Password Does Not Match",
  textLength: "The text may not be longer than 100 words",
  netRent: "Please Enter Valid Net Rent",
  noOfMonths: "Please Enter Valid No Of Months",
  default: "",
};
