import React, { useEffect, useState, useMemo } from "react";
import SpaceCompsTable from "../../components/layout/SpaceCompsTable/SpaceCompsTable";
import { buildingManagement } from "../../utils/constants/tableColumns";
import { EXPORT_BUILDING_DATA, STATIC_BUILDING_DATA } from "../../graphql/queries/usersAdmin";
import { useLazyQuery, useMutation } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { IconButton, Tooltip, Typography } from "@mui/material";
import ImportExcel from "../../components/layout/Excel/ImportExcel";
import { Result } from "react-spreadsheet-import/types/types";
import { saveExcelData } from "../../utils/importExcel";
import { fields } from "../../utils/constants/excelFields";
import UploadIcon from "@mui/icons-material/Backup";
import { client } from "../../graphql/apollo-client";
import FileDownloadIcon from "@mui/icons-material/CloudDownload";
import { ExportToCsv } from "export-to-csv";
import { CREATE_BUILDING } from "../../graphql/mutations/createBuilding";
import { useAuth } from "../../contexts/auth/provideAuth";
import { toast } from "react-toastify";
import { GET_BUILDING_AVERAGE_CALCULATION } from "../../graphql/queries/getBuildingAverageCalculation";
import { MaterialReactTableProps } from "material-react-table";
import { EDIT_DYNAMICDATA } from "../../graphql/mutations/userAdmin";
import {
  convertTypeObj,
  getFilteredTableData,
  removeNonNumericValue,
  removeUnusedKeys,
} from "../../utils/commonFunctions";
const StaticData = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { handleState } = useAuth();
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 5 });
  const classes = useStyles();
  const [buildinglisting, { data, refetch, loading }] = useLazyQuery<any>(STATIC_BUILDING_DATA, {
    fetchPolicy: "cache-and-network",
  });
  const [handleExport, { loading: exportLoading }] = useLazyQuery<any>(EXPORT_BUILDING_DATA, {
    fetchPolicy: "cache-and-network",
  });
  const [buildingcalculation, { data: calculation }] = useLazyQuery(GET_BUILDING_AVERAGE_CALCULATION, {
    fetchPolicy: "network-only",
  });
  const [editDynamicData] = useMutation(EDIT_DYNAMICDATA, {
    fetchPolicy: "network-only",
    onCompleted: refetch,
  });

  useEffect(() => {
    buildinglisting({
      variables: {
        first: pagination.pageSize,
        after: pagination.pageSize * pagination.pageIndex,
        search: globalFilter ?? "",
      },
    });
  }, [buildinglisting, globalFilter, pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    buildingcalculation({
      variables: {
        search: globalFilter ?? "",
      },
    });
  }, [buildingcalculation, globalFilter]);

  const calculateBuildingData = (value: string) => {
    return typeof value === "string" ? value?.replace(/,/g, "") : "0";
  };

  const staticData = useMemo(() => {
    const filteredData = data?.getBuilding?.edges.map(
      (d: { node: { officeSize: string; totalDirectAvailable: string; totalSubletAvailable: string } }, i: number) => {
        setRowCount(data?.getBuilding?.totalCount);
        const officeSize = calculateBuildingData(d?.node?.officeSize);
        const totalDirectAvailable = calculateBuildingData(d?.node?.totalDirectAvailable);
        const totalSubletAvailable = calculateBuildingData(d?.node?.totalSubletAvailable);
        const officeSizeNumber = parseFloat(officeSize) || 0;
        const totalDirectAvailableNumber = parseFloat(totalDirectAvailable) || 0;
        const totalSubletAvailableNumber = parseFloat(totalSubletAvailable) || 0;
        const vacancyDirectPercentage = officeSizeNumber
          ? ((totalDirectAvailableNumber / officeSizeNumber) * 100).toFixed(2)
          : 0;
        const vacancySubletPercentage = officeSizeNumber
          ? ((totalSubletAvailableNumber / officeSizeNumber) * 100).toFixed(2)
          : 0;
        const vacancyTotalPercentage = officeSizeNumber
          ? (((totalDirectAvailableNumber + totalSubletAvailableNumber) / officeSizeNumber) * 100).toFixed(2)
          : 0;

        const direct_average = calculation?.getBuildingAverageCalculationQuery?.direct_average;
        const sublet_average = calculation?.getBuildingAverageCalculationQuery?.sublet_average;
        const total_floors = calculation?.getBuildingAverageCalculationQuery?.total_floors;
        const total_vacancy_average = calculation?.getBuildingAverageCalculationQuery?.total_vacancy_average;
        const indexId = i + 1;
        const node = {
          ...d.node,
          vacancyDirectPercentage,
          vacancySubletPercentage,
          vacancyTotalPercentage,
          direct_average,
          sublet_average,
          total_floors,
          total_vacancy_average,
          indexId,
        };
        removeNonNumericValue(node);
        return node;
      }
    );
    return filteredData ?? [];
  }, [data, calculation]);

  useEffect(() => {
    const Loading = loading || exportLoading;
    handleState(!Loading);
  }, [loading, handleState, exportLoading]);

  const onImportExcelOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  const onSubmit = async (submitData: Result<string>) => {
    try {
      const staticdataResponse = await saveExcelData(submitData.validData, client, CREATE_BUILDING, 100);
      if (staticdataResponse.data) {
        toast.success("Data uploaded successfully");
        await refetch();
      } else {
        toast.error("There was an error in syncing the data");
      }
    } catch (e: any) {
      const errorMessage = e?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };

  const handleSaveRow: MaterialReactTableProps<any>["onEditingRowSave"] = async ({ exitEditingMode, values, row }) => {
    try {
      const { indexId, address, vacancyDirectPercentage, vacancySubletPercentage, vacancyTotalPercentage, ...rest } =
        values;
      const keyToLeaveString = [
        "buildingName",
        "buildingPhysicalStatus",
        "city",
        "class",
        "id",
        "market",
        "occupant",
        "ownerPropertyManager",
        "parkingRatio",
        "yearBuilt",
        "zoning",
      ];
      const editFields = convertTypeObj(rest, keyToLeaveString);
      const editDynamicDataResponse = await editDynamicData({
        variables: {
          input: { ...editFields, id: row?.original?.id },
        },
      });
      if (editDynamicDataResponse.data) {
        exitEditingMode();
        toast.success("Static data edited successfully");
      }
    } catch (e: any) {
      const errorMessage = e?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };

  const handleExportData = async () => {
    const { data: exportData } = await handleExport({
      variables: {
        first: rowCount,
        after: 0,
        search: globalFilter ?? "",
      },
    });
    const exportColumn = getFilteredTableData(exportData?.exportBuilding?.edges);
    const exportSheet = removeUnusedKeys(exportColumn);
    csvExporter.generateCsv(exportSheet);
  };

  if (!staticData) {
    return <h2>Loading.....</h2>;
  }

  return (
    <div>
      <div className={`${classes.tableDesined} form-group form-check`}>
        <SpaceCompsTable
          columns={buildingManagement}
          data={staticData}
          getRowId={(row) => row.id}
          editingMode="row"
          enableEditing
          onEditingRowSave={handleSaveRow}
          enableColumnFilterModes
          enableColumnOrdering
          enableMultiRowSelection={false}
          enableGrouping
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          initialState={{ columnVisibility: { __typename: false } }}
          state={{ pagination, globalFilter, showProgressBars: !staticData }}
          enablePinning
          enableTopToolbar={true}
          onPaginationChange={setPagination}
          onGlobalFilterChange={setGlobalFilter}
          rowCount={rowCount}
          enableRowNumbers
          enableToolbarInternalActions={true}
          manualPagination={true}
          muiTablePaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "0",
              border: "1px dashed #e0e0e0",
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              backgroundColor: "#808080 !important",
              color: "#fff",
            },
          }}
          muiTableBodyProps={{
            sx: {
              "& tr td": {
                background: "#1B1C1E !important",
                color: "#FFFFFF",
                borderBottom: "1px solid #3F4349",
              },

              "& tr:nth-of-type(even)": {
                backgroundColor: "#1B1C1E !important",
                color: "#FFFFFF",
              },
            },
          }}
          renderTopToolbarCustomActions={() => (
            <div className={classes.topToolbarBoxStyling}>
              <div>
                <Typography component="span" sx={{ color: "#FFFF" }} variant="h4">
                  STATIC DATA
                </Typography>
              </div>
              <div className={classes.iconContainer}>
                <Tooltip title="Upload Excel">
                  <IconButton onClick={onImportExcelOpen}>
                    <UploadIcon color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Export All Data">
                  <IconButton onClick={handleExportData}>
                    <FileDownloadIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          )}
        />
        <ImportExcel isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} fields={fields} />
      </div>
    </div>
  );
};
const csvOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true,
  headers: buildingManagement
    ?.map((c) => c.header)
    ?.filter((item) => {
      return item !== "S.NO";
    }),
};
const csvExporter = new ExportToCsv(csvOptions);

export default StaticData;
const useStyles: any = makeStyles({
  tableDesined: {
    textAlign: "center",
    margin: "auto",
    marginTop: "40px",
    width: "78vw",
    display: "block",
    overflowX: "auto",
  },
  exportCssStyle: {
    width: "20%",
  },
  iconContainer: {
    display: "flex",
  },
  topToolbarBoxStyling: {
    display: "flex",
    flex: 2,
    justifyContent: "space-between",
  },
});
