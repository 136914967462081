import React, { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
const SubMenu = ({ item }: any) => {
  const classes = useStyle();

  const { icon, iconOpen, iconClosed, path, title, subNav } = item;
  const [isSubnavOpened, setSubnavOpened] = useState(false);
  const showSubnav = useCallback(() => subNav && setSubnavOpened((prev) => !prev), [subNav]);
  return (
    <>
      <NavLink
        to={path}
        className={({ isActive }) => (isActive ? `${classes.aciveStyle}` : `${classes.inactiveStyle}`)}
        onClick={showSubnav}
      >
        <div className={classes.divStyle}>
          <i className={icon}></i>

          <label className={classes.labelStyle}>{title}</label>
        </div>
        {isSubnavOpened ? (
          <i className={`${iconOpen} ${classes.iconStyle}`}></i>
        ) : (
          <i className={`${iconClosed} ${classes.iconStyle}`}></i>
        )}
      </NavLink>
      {isSubnavOpened &&
        subNav?.map((submenu: any, index: any) => {
          return (
            <NavLink
              className={({ isActive }) =>
                isActive ? `${classes.aciveStyleDropdownStyle}` : `${classes.inAciveStyleDropdownStyle}`
              }
              to={submenu.path}
              key={index}
            >
              <i className={`${submenu.icon} `}></i>
              <label className={classes.labelStyle}>{submenu.title}</label>
            </NavLink>
          );
        })}
    </>
  );
};
export default SubMenu;

const useStyle: any = makeStyles({
  divStyle: {
    display: "flex",
    color: "#e1e9fc",
    alignItems: "center",
    padding: "20px",
    listStyle: "none",
    height: "60px",
    textDecoration: "none",
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  labelStyle: {
    marginLeft: "16px",
    cursor: "pointer",
  },

  inactiveStyle: {
    display: "flex",
    color: "#e1e9fc",
    alignItems: "center",
    padding: "20px",
    listStyle: "none",
    height: "60px",
    textDecoration: "none",
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
      background: "#FD6262 !important",
    },
  },
  aciveStyle: {
    display: "flex",
    color: "#e1e9fc",
    alignItems: "center",
    padding: "20px",
    listStyle: "none",
    height: "60px",
    textDecoration: "none",
    fontSize: "16px",
    background: "#FD6262 !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  divStyle2s: {
    display: "flex",
    color: "#e1e9fc",
    alignItems: "center",
    padding: "30px",
    listStyle: "none",
    height: "60px",
    textDecoration: "none",
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  aciveStyleDropdownStyle: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "5rem",
    height: "60px",
    textDecoration: "none",
    fontSize: "16px",
    color: "#fff !important",
    background: "#FD6262 !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  inAciveStyleDropdownStyle: {
    display: "flex",
    color: "#fff !important",
    alignItems: "center",
    paddingLeft: "5rem",
    height: "60px",
    textDecoration: "none",
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
      background: "#fd6262 !important",
    },
  },
  iconStyle: {
    color: "#fff !important",
    "&:hover": {
      color: "#fff !important",
    },
  },
});
