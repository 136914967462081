import React from "react";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { IDropDownProps } from "./DropDown";
import DropDownMenu from "./DropDownMenu";

const UnControlledDropDown = ({ title, options }: IDropDownProps) => {
  return (
    <UncontrolledDropdown inNavbar nav>
      <DropdownToggle caret nav>
        {title}
      </DropdownToggle>
      <DropDownMenu options={[...options]} />
    </UncontrolledDropdown>
  );
};

export default UnControlledDropDown;
