import { gql } from "@apollo/client";
export const CHANGEPASSWORD = gql`
  query ChangePassword($tokenId: String, $password: String) {
    changePassword(tokenId: $tokenId, password: $password) {
      code
      message
      info {
        loginName
        lastLoginDate
        isPending
        isNewUser
        isDeleted
        isAdmin
        isActive
        id
        entityOid
        datePwChanged
        createdOn
      }
    }
  }
`;
