import React, { FC } from "react";
import { Col, Button, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import ResultImg from "../../assets/images/logos/result-img.png";
import CommonSlider from "../layout/slider/CommonSlider";
import { makeStyles } from "@mui/styles";

export interface ISearchProps {
  error?: any;
  data?: {
    searchBuilding: {
      edges: [
        {
          cursor: string;
          node: {
            id: string;
            address: string;
            floors: number;
            buildingSize: number;
            buildingSpaces: [];
          };
        }
      ];
      totalCount: any;
    };
  };
  loading?: boolean;
}

const SearchResult: FC<ISearchProps> = ({ data, error }: ISearchProps) => {
  const navigate = useNavigate();
  const handleButtonClick = (id: string) => {
    // Navigate to the desired route with the encrypted id
    navigate(`/building-search/allcomps-list?id=${id}`);
  };
  const classes = useStyles();
  const prevButtonStyle = {
    display: "block",
    top: "410px",
    color: "red",
    height: "auto",
    width: "auto",
    right: "60px",
    zIndex: "9",
  };
  const NextButtonStyle = {
    display: "block",
    top: "410px",
    color: "black",
    height: "auto",
    marginRight: "52px",
    width: "auto",
  };
  return (
    <div>
      {error && <p className="text-center text-white">No data found</p>}
      {data?.searchBuilding?.totalCount && (
        <h2 className="text-start resultNumber" style={{ color: "#FFFFFF" }}>
          Total Number of result - {data?.searchBuilding?.totalCount}
        </h2>
      )}
      <div className={`${classes.buildingSearchSlider} slider-section`}>
        <CommonSlider
          data={data?.searchBuilding?.edges || []}
          prevButtonStyle={prevButtonStyle}
          NextButtonStyle={NextButtonStyle}
          slides={3}
        >
          {data?.searchBuilding?.edges.map(({ node }) => (
            <Col sm={4} className="sliderDiv" key={node.id}>
              <div className="search-widget mx-2">
                <Row>
                  <Col sm={5} style={{ color: "#FFFFFF" }} className="cell">
                    Address
                  </Col>
                  <Col sm={7} className="cell">
                    <div className="address-txt" style={{ color: "#FFFFFF" }}>
                      {node.address}
                    </div>
                  </Col>
                  <Col sm={5} className="cell">
                    <img alt="logo" src={ResultImg} className="result-img"></img>
                  </Col>
                  <Col sm={7} className="cell d-flex align-items-center">
                    <div className="primary-blue font-weight-400" style={{ color: "#FFFFFF" }}>
                      {node.buildingSpaces.length} SPACECOMPS AVAILABLE Size range: {node.buildingSize} sq. ft.
                    </div>
                  </Col>
                </Row>
              </div>
              <Col className="text-end mb-4">
                <Button className="btn btn-primary" onClick={() => handleButtonClick(node.id)}>
                  View All Comps
                </Button>
              </Col>
            </Col>
          ))}
        </CommonSlider>
      </div>
    </div>
  );
};
export default SearchResult;
const useStyles: any = makeStyles({
  buildingSearchSlider: {
    "& .slick-track": {
      gap: "0 !important",
    },
    "& .slick-slider .slick-slide": {
      padding: "0 15px !important",
    },
  },
});
