import React, { ChangeEventHandler, FormEventHandler, useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import { Col, Form, Input, Button, Container, Row } from "reactstrap";
import SelectOptions from "../../common/SelectOptions";
import { getCompsRange, getSubmarket, getMarket, getDateRange } from "../../utils/constants/searchBlockData";
import DateRange from "../../common/DateRange";

interface SearchBlockprops {
  handleFilterChange: ChangeEventHandler<HTMLInputElement>;
  handleSearchFilterSubmit: FormEventHandler<HTMLFormElement>;
  handleMarketFilterSubmit: FormEventHandler<HTMLFormElement>;
  disabled: boolean;
  value: any;
}
const SearchBlock = ({
  handleFilterChange,
  handleSearchFilterSubmit,
  handleMarketFilterSubmit,
  disabled,
  value,
}: SearchBlockprops) => {
  const [searchDisabled, setSearchDisabled] = useState<boolean>(true);
  const [checked, setChecked] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFilterChange(event);
    const form = event.currentTarget.form;
    if (!form) {
      return;
    }
  };
  const selectItemStyles = {
    cursor: "pointer",
  };

  useEffect(() => {
    const { search, ...rest } = value;
    rest.campRange = rest.campRange?.split("-");
    const hasValue = Object.values(rest).some((item: any) => {
      if (Array.isArray(item)) {
        return item.length !== 0 && !item.includes("");
      } else {
        return item && item.length !== 0;
      }
    });
    setSearchDisabled(!hasValue);
  }, [value]);

  return (
    <div>
      <Form action="Post" onSubmit={handleSearchFilterSubmit}>
        <Container fluid>
          <div className="search-widget">
            <Row>
              <Col style={{ color: "#FFFFFF" }} sm={6} className="cell">
                Building
              </Col>
              <Col sm={6} className="cell">
                <Input
                  type="text"
                  value={value.search}
                  className="search-Input"
                  placeholder="Type here to search"
                  name="search"
                  onChange={handleFilterChange}
                  autoComplete="off"
                />
              </Col>
            </Row>
          </div>
        </Container>

        <Col className="text-end mb-5">
          <Button className="btn btn-primary" disabled={disabled}>
            Search
          </Button>
        </Col>
      </Form>
      <Form action="Post" onSubmit={handleMarketFilterSubmit}>
        <h2 style={{ color: "#FFFFFF" }}>Search by Market</h2>
        <Container fluid>
          <div className="search-widget">
            <Row>
              <Col style={{ color: "#FFFFFF" }} sm={6} className="cell">
                Market
              </Col>
              <Col sm={6} className="cell">
                <Input
                  type="select"
                  className="shadow-none"
                  name="city"
                  value={value.city}
                  style={selectItemStyles}
                  onChange={handleInputChange}
                >
                  <SelectOptions defaultname="Please Select" options={getMarket} />
                </Input>
              </Col>
              <Col style={{ color: "#FFFFFF" }} sm={6} className="cell">
                Submarket
              </Col>
              <Col sm={6} className="cell">
                <Input
                  type="select"
                  className="shadow-none"
                  name="subMarket"
                  value={value.subMarket}
                  style={selectItemStyles}
                  onChange={handleInputChange}
                >
                  <SelectOptions defaultname="Please Select" options={getSubmarket} />
                </Input>
              </Col>
              <Col style={{ color: "#FFFFFF" }} sm={6} className="cell">
                Comp Range (sq. ft.)
              </Col>
              <Col sm={6} className="cell">
                <Input
                  className="shadow-none"
                  type="select"
                  name="campRange"
                  value={value.campRange}
                  style={selectItemStyles}
                  onChange={handleInputChange}
                >
                  <SelectOptions defaultname="All" options={getCompsRange} />
                </Input>
              </Col>
              <Col sm={6} style={{ color: "#FFFFFF" }} className="cell d-flex justify-content-between">
                Date Range
                <span>
                  <Switch checked={checked} onChange={handleChange} inputProps={{ "aria-label": "controlled" }} />
                </span>
              </Col>

              <Col sm={6} className="cell">
                {checked ? (
                  <DateRange onChange={handleFilterChange} setSearchDisabled={setSearchDisabled} />
                ) : (
                  <Input
                    type="select"
                    className="shadow-none"
                    name="baseYear"
                    value={value.baseYear}
                    style={selectItemStyles}
                    onChange={handleInputChange}
                  >
                    <SelectOptions defaultname="Please Select" options={getDateRange} />
                  </Input>
                )}
              </Col>
            </Row>
          </div>
        </Container>
        <Col className="text-end mb-5">
          <Button className="btn btn-primary" disabled={searchDisabled}>
            Search
          </Button>
        </Col>
      </Form>
    </div>
  );
};
export default SearchBlock;
