import React, { useMemo, useState, useEffect } from "react";
import SpaceCompsTable from "../../components/layout/SpaceCompsTable/SpaceCompsTable";
import { adminTableColumns, adminModalTableColumns } from "../../utils/constants/tableColumns";
import { USERS_LIST, DELETE_USER } from "../../graphql/queries/usersAdmin";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { CREATE_USER, UPDATE_USER } from "../../graphql/mutations/userAdmin";
import UserModal from "../../components/layout/UserModal/UserModal";
import { USER_APPROVAL } from "../../graphql/mutations/getUserAprovel";
import { MaterialReactTableProps, MRT_Row, MRT_RowSelectionState } from "material-react-table";
import { Delete, Edit } from "@mui/icons-material";
import { Button, Typography, Box, IconButton, Tooltip } from "@mui/material";
import { useAuth } from "../../contexts/auth/provideAuth";
import { toast } from "react-toastify";
import AlertModal from "../../components/layout/AlertModal/AlertModal";
import { GET_USER_ROLE } from "../../graphql/queries/getUserRole";
const UserManagement = () => {
  const classes = useStyles();
  const { handleState } = useAuth();
  const [userListing, { data: userListData, refetch, loading }] = useLazyQuery<any>(USERS_LIST, {
    fetchPolicy: "cache-and-network",
  });

  const [userUpdateAdmin, { loading: UpdateLoading }] = useMutation(UPDATE_USER, {
    onCompleted: refetch,
  });
  const { data: role } = useQuery(GET_USER_ROLE, { fetchPolicy: "network-only" });

  const [userDetail, { loading: DeleteLoading }] = useLazyQuery(DELETE_USER, {
    onCompleted: refetch,
  });
  const [handleApprovedUser, { loading: approvelLoading }] = useMutation(USER_APPROVAL, {
    onCompleted: refetch,
  });
  const [rowCount, setRowCount] = useState(0);

  const userData = useMemo(() => {
    const filteredData = userListData?.userList?.edges.map((d: any, i: any) => {
      setRowCount(userListData?.userList?.totalCount);
      const indexId = i + 1;
      return {
        ...d.node,
        indexId,
      };
    });
    return filteredData ?? [];
  }, [userListData]);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowData, setRowData] = useState<any>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [success, setSucess] = useState(false);
  const [userSave, { loading: createLoading }] = useMutation(CREATE_USER, {
    onCompleted: refetch,
  });
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 5 });

  useEffect(() => {
    userListing({
      variables: {
        first: pagination.pageSize,
        after: pagination.pageSize * pagination.pageIndex,
        search: globalFilter ?? "",
      },
    });
  }, [userListing, globalFilter, pagination.pageIndex, pagination.pageSize]);

  const handleDelete = (row: MRT_Row<any>) => {
    setDeleteModal(true);
    setRowData(row);
  };
  const handleDeleteClose = () => {
    setDeleteModal(false);
  };
  const handleDeleteRow = async () => {
    try {
      const userDelete = await userDetail({
        variables: {
          loginName: rowData?.original?.email,
          userId: rowData?.original?.id,
        },
      });
      if (userDelete.data) {
        handleDeleteClose();
        toast.success(userDelete.data.deleteUserByAdmin.message);
      }
    } catch (e: any) {
      const errorMessage = e?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };
  const [state, setState] = useState("");

  const onSubmit = async (values: { [key: string]: string }) => {
    setSucess(false);
    try {
      const userSubmit = await userSave({
        variables: {
          input: {
            email: values?.email,
            role: values?.role,
          },
        },
      });
      if (userSubmit.data?.createUserByAdmin) {
        setSucess(true);
        setCreateModalOpen(false);
        toast.success(userSubmit.data?.createUserByAdmin.message);
      }
    } catch (e: any) {
      setSucess(false);
      const errorMessage = e?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };

  const handleSaveRow: MaterialReactTableProps<any>["onEditingRowSave"] = async ({ exitEditingMode, values }) => {
    try {
      const editUser = await userUpdateAdmin({
        variables: {
          input: {
            firstName: values?.firstName,
            lastName: values?.lastName,
            phoneNumber: values?.phoneNumber,
            market: values?.market,
            email: values?.email,
            companyName: values?.companyName,
            role: state,
          },
          updateUserByAdminId: values.id,
        },
      });
      if (editUser.data?.updateUserByAdmin) {
        exitEditingMode();
        setState("");
        toast.success("User details updated successfully.");
      }
    } catch (e: any) {
      const errorMessage = e?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    handleState(!loading && !UpdateLoading && !DeleteLoading && !createLoading && !approvelLoading);
  }, [loading, handleState, UpdateLoading, DeleteLoading, createLoading, approvelLoading]);

  const getInput = (data: any, status: boolean) => {
    return data.map((item: any) => ({ userId: item.original.id, isActive: status }));
  };
  const Approve = async (row: any) => {
    const inputValues = getInput(row, true);
    try {
      const acceptResponse = await handleApprovedUser({
        variables: {
          input: inputValues,
        },
      });
      if (acceptResponse.data) {
        setRowSelection({});
        toast.success("User Approved");
      }
    } catch (e: any) {
      const errorMessage = e?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };

  const Reject = async (row: any) => {
    const inputValues = getInput(row, false);
    try {
      const rejectResponse = await handleApprovedUser({
        variables: {
          input: inputValues,
        },
      });
      if (rejectResponse.data) {
        setRowSelection({});
        toast.success("User Rejected");
      }
    } catch (e: any) {
      const errorMessage = e?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };

  const getcolumnData = adminTableColumns(role?.roleList?.info || [], state, setState);
  const addUserData = adminModalTableColumns(role?.roleList?.info || []);

  return (
    <>
      <div className={classes.main}>
        <div className={`${classes.tableDesined} form-group form-check `}>
          <Typography component="span" variant="h4" sx={{ color: "#FFFF" }}>
            USER MANAGEMENT
          </Typography>
          <SpaceCompsTable
            columns={getcolumnData}
            data={userData}
            editingMode="modal"
            getRowId={(row) => row.id}
            enableEditing
            enableRowSelection={(row) => !row?.original?.isActive}
            enableSelectAll={false}
            onEditingRowSave={handleSaveRow}
            onEditingRowCancel={() => {
              setState("");
            }}
            manualPagination={true}
            enableColumnFilterModes
            enableColumnOrdering
            enableGlobalFilter
            onGlobalFilterChange={setGlobalFilter}
            enablePinning
            enableRowNumbers={false}
            enableRowActions
            initialState={{ columnVisibility: { id: false } }}
            state={{ pagination, rowSelection, showProgressBars: !userListData }}
            onRowSelectionChange={setRowSelection}
            onPaginationChange={setPagination}
            rowCount={rowCount}
            muiTablePaperProps={{
              elevation: 0,
              sx: {
                borderRadius: "0",
                border: "1px dashed #e0e0e0",
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                backgroundColor: "#808080 !important",
                color: "#fff",
              },
            }}
            muiTableBodyProps={{
              sx: {
                "& tr td": {
                  background: "#1B1C1E !important",
                  color: "#FFFFFF",
                  borderBottom: "1px solid #3F4349",
                },

                "& tr:nth-of-type(even)": {
                  backgroundColor: "#1B1C1E !important",
                  color: "#FFFFFF",
                },
              },
            }}
            renderRowActions={({ row, table }) => {
              return (
                <Box sx={{ display: "flex" }}>
                  <Tooltip arrow placement="left" title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow placement="right" title="Delete">
                    <IconButton
                      onClick={() => {
                        handleDelete(row);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            }}
            renderTopToolbarCustomActions={({ table }) => {
              const rowValue = table.getSelectedRowModel().flatRows;
              return (
                <div className={classes.actionBtn}>
                  <Button
                    color="secondary"
                    onClick={() => setCreateModalOpen(true)}
                    variant="contained"
                    className={classes.addButtonStyle}
                  >
                    ADD USER
                  </Button>
                  <Button
                    onClick={() => {
                      Approve(rowValue);
                    }}
                    className={table.getIsSomeRowsSelected() ? "secondary_btn" : "primary_btn"}
                    disabled={!table.getIsSomeRowsSelected()}
                    variant="contained"
                  >
                    Approve
                  </Button>
                  <Button
                    onClick={() => {
                      Reject(rowValue);
                    }}
                    className={table.getIsSomeRowsSelected() ? "error_btn" : "primary_btn"}
                    disabled={!table.getIsSomeRowsSelected()}
                    variant="contained"
                  >
                    Reject
                  </Button>
                </div>
              );
            }}
          />
        </div>
      </div>
      <UserModal
        columns={addUserData}
        open={createModalOpen}
        success={success}
        onClose={() => setCreateModalOpen(false)}
        onSubmitCallback={onSubmit}
      />
      <AlertModal
        openModal={deleteModal}
        onClose={handleDeleteClose}
        handleSubmit={handleDeleteRow}
        name="UserManagement"
      />
    </>
  );
};
export default UserManagement;
const useStyles: any = makeStyles({
  tableDesined: {
    textAlign: "center",
    marginTop: "40px",
    width: "78vw",
    display: "block",
    margin: "auto",
    overflowX: "auto",
  },
  addButtonStyle: {
    color: "#FFF",
    borderRadius: "0px",
    borderColor: "#10257F",
    borderWidth: "1px",

    background: "#10257F !important",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    "&:hover": {
      background: "#00c194 !important",
      borderRadius: "0px",
      borderColor: "#00c194",
      color: "#fff",
    },
  },
  tooltipStyle: {
    textAlign: "left !important" as any,
  },
  actionBtn: {
    display: "flex",
    gap: "5px",
  },
});
