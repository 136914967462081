import { gql } from "@apollo/client";
export const CREDIT_SCORE = gql`
  query GetCreditScore {
    getCreditScore {
      success
      message
      info {
        creditAmount
        userId
        id
      }
    }
  }
`;

export const CHECKOUT_SESSION = gql`
  query CheckoutSession($productName: String, $unitAmount: Int, $quantity: Int) {
    createCheckoutSession(productName: $productName, unitAmount: $unitAmount, quantity: $quantity)
  }
`;
