import { gql } from "@apollo/client";
export const GET_USER_ROLE = gql`
  query RoleList {
    roleList {
      success
      message
      code
      info {
        roleConstraint
        role
        id
      }
    }
  }
`;
