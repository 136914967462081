import slide2 from "../../assets/images/logos/slide2.png";
import slide3 from "../../assets/images/logos/slide3.png";
import slide4 from "../../assets/images/logos/slide4.png";
import work1 from "../../assets/images/logos/work1.png";
import work2 from "../../assets/images/logos/work2.png";
import work3 from "../../assets/images/logos/work3.png";
import work4 from "../../assets/images/logos/work4.png";

export const headerSlideImg = [slide2, slide3, slide4];
const CanadaSourceData = {
  subtitle: "Real time office leasing comps.",
  list: [
    "Anonymously Sourced By Our Members",
    "Reviewed by our in-house team of Experts",
    "Verified by our membership and proprietary algorithms",
  ],
};

export const CompanyData: any = [
  {
    name: "Spacecomps.Com",
    data: CanadaSourceData,
    table: [
      {
        Market: {
          label: "Renewal Market",
          color: "",
          action: true,
        },
        City: {
          label: "Toronto",
          color: "",
        },
        Submarket: {
          label: "Downtown West",
          color: "",
        },
        Class: {
          label: "AAA",
          color: "",
        },
        "AVG NER": {
          label: "$37.53",
          color: "#00C194",
        },
        "Yearly % Changes": {
          label: "+4.3%",
          color: "#00C194",
        },
      },
      {
        Market: {
          label: "Relocation Market",
          color: "",
          action: true,
        },
        City: {
          label: "Toronto",
          color: "",
        },
        Submarket: {
          label: "Downtown West",
          color: "",
        },
        Class: {
          label: "All",
          color: "",
        },
        "AVG NER": {
          label: "$22.47",
          color: "#FF0000",
        },
        "Yearly % Changes": {
          label: "-28.4%",
          color: "#FF0000",
        },
      },
      {
        Market: {
          label: "Model Suite Market",
          color: "",
          action: true,
        },
        City: {
          label: "Toronto",
          color: "",
        },
        Submarket: {
          label: "Downtown West",
          color: "",
        },
        Class: {
          label: "B",
          color: "",
        },
        "AVG NER": {
          label: "$985",
          color: "#00C194",
        },
        "Yearly % Changes": {
          label: "+1.3%",
          color: "#00C194",
        },
      },
    ],
  },
];

export const SpacecompsWorks = [
  {
    id: 1,
    title: "Deals Data",
    img: work1,
  },
  {
    id: 2,
    title: "Available Spaces",
    img: work2,
  },
  {
    id: 3,
    title: "Buildings Data",
    img: work3,
  },
  {
    id: 4,
    title: "Credits on Deals Submitted",
    img: work4,
  },
];
