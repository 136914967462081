import React from "react";
import "./index.css";
import "./common.css";
import reportWebVitals from "./reportWebVitals";
import Routing from "./components/routing";
import "bootstrap/dist/css/bootstrap.min.css";

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<Routing />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
