import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import withoutHeader from "../../../utils/hoc/withoutHeader";

export interface Props {
  title: string;
  message: string;
}
const MessageCard = ({ title, message }: Props) => {
  const classes = useStyles();
  return (
    <div className={`${classes.card} card `}>
      <div className={`${classes.cardbody}`}>
        <i className={`${classes.checkmark} ${title === "Error" ? classes.error : ""}`}>
          {title === "Error" ? "x" : "✓"}
        </i>
        <h1 style={{ color: "#FFF" }}>{title}</h1>
        <p className={`${classes.para}`}>{message}</p>
        <Link to="/">
          <Button className={`btn btn-primary ${title === "Error" && classes.errorbtn}`}>Back to Home</Button>
        </Link>
      </div>
    </div>
  );
};

export default withoutHeader(MessageCard);
const useStyles: any = makeStyles((theme: { breakpoints: { down(arg0: string): any } }) => ({
  card: {
    background: "#1B1C1E",
    padding: "60px",
    borderRadius: "4px",
    border: "1px solid #3F4349",
    display: "block",
    width: "50%",
    marginTop: "100px",
    margin: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    [theme?.breakpoints?.down("xs")]: {
      width: "100%",
    },
  },
  cardbody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  checkmark: {
    color: "#9ABC66",
    fontSize: "100px",
    lineHeight: "200px",
    fontStyle: "normal",
  },
  error: {
    color: "red",
  },
  errorbtn: {
    color: "white",
    background: "red !important",
    border: "none",
    outline: "none",
  },
  para: {
    color: " #FFF",
    fontSize: "20px",
    textAlign: "center",
  },
}));
