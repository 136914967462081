import React, { ChangeEventHandler } from "react";
import InputBox from "./InputBox";
import { makeStyles } from "@mui/styles";
import { IDefaultAuthFormFields } from "../../../ts/interfaces/authFields";

export interface FormProps {
  fields?: IDefaultAuthFormFields[];
  handleChange: ChangeEventHandler<HTMLInputElement>;
  errors: [key: string];
  values: [key: string];
  disable: boolean;
  handleSubmit: any;
}
const Form = ({ fields, disable, handleChange, errors, values, handleSubmit }: FormProps) => {
  const classes = useStyles();
  return (
    <form className={classes.mainForm} onSubmit={handleSubmit}>
      <div className={classes.dealForm}>
        {fields?.map((field: any, index: number) => {
          return (
            <div className="input">
              <InputBox
                key={index}
                {...field}
                inputProps={{ maxLength: Number(field.maxLength) }}
                onChange={(e) => {
                  handleChange(e);
                }}
                error={errors[field.name]}
                value={values[field.name]}
              />
            </div>
          );
        })}
      </div>
      <div className="text-end mt-2">
        <button disabled={disable} type="submit" className={`${classes.styleButtonCss}`}>
          Submit
        </button>
      </div>
    </form>
  );
};

export default Form;

const useStyles: any = makeStyles({
  mainForm: {
    display: "block",
    margin: "auto",
    paddingBottom: "20px",
    "@media (max-width: 780px)": {
      width: "100%",
    },
  },
  styleButtonCss: {
    color: "#FFF !important",
    border: "0px !important",
    height: "54.19px !important",
    background: "#00C194 !important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    width: "100%",
    fontWeight: 500,
    fontSize: "16px",
    "&:hover": {
      background: "#10257F",
      borderRadius: "0px",
      borderColor: "#10257F",
    },
  },
  dealForm: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    gap: "5px",
    marginTop: "10px",
    "& .input": {
      flex: "1 1 0px",
    },
  },
});
