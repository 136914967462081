import React from "react";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { Result, Fields, RawData } from "react-spreadsheet-import/types/types";
import { toast } from "react-toastify";
export interface IExcelImportProps {
  isOpen: boolean | undefined;
  onClose(): void;
  onSubmit(data: Result<string>): void;
  fields: Fields<string>;
}

const ImportExcel = ({ isOpen, onClose, fields, onSubmit }: IExcelImportProps) => {
  const validate = async (headerValues: RawData, data: RawData[]) => {
    if (data.length === 0 || !data) {
      toast.error("Please Upload A Valid Sheet");
      onClose();
    } else {
      return { headerValues, data };
    }
  };
  return (
    <ReactSpreadsheetImport
      selectHeaderStepHook={validate as any}
      allowInvalidSubmit={false}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      fields={fields}
    />
  );
};

export default ImportExcel;
