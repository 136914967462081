import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, Container } from "reactstrap";
import { Link } from "react-router-dom";
import useForm from "../../../Hooks/useForm";
import { defaultSignupFields } from "../../../utils/constants/defaultAuthFields";
import { InputBox } from "../Form";
import { makeStyles } from "@mui/styles";
import InnerHeader from "../../Header/InnerHeader";
import { Grid, Box, Typography } from "@mui/material";
const ADMIN_PATH = "admin";
export interface ISignupProps {
  navigateBaseUrl: string;
  error?: any;
  data?: any;
  loading?: boolean;
  selectedRole?: string;
  onSubmitCallback(values: { [key: string]: string | number }): void;
}
const Signup = ({ onSubmitCallback, navigateBaseUrl, data, error, selectedRole, loading = false }: ISignupProps) => {
  const classes = useStyles();
  const { handleChange, values, errors, handleSubmit, clearForm } = useForm(onSubmitCallback, [
    ...defaultSignupFields,
    { name: "check", value: false },
  ]);
  /* eslint-disable */
  const [check, setChek] = useState(false);
  const isAdminAuth = useMemo(() => navigateBaseUrl === ADMIN_PATH, [navigateBaseUrl]);
  useEffect(() => {
    if (selectedRole) {
      values.role = selectedRole;
    } else {
      window.location.href = "/login";
    }
  }, [selectedRole]);

  useEffect(() => {
    setChek(values?.check);
  }, [values?.check]);
  useEffect(() => {
    if (data) {
      clearForm();
    }
  }, [error, data]);
  return (
    <>
      <InnerHeader props={"Sign Up"} />
      <Container>
        <Grid container spacing={2} className={classes.parentDiv}>
          <Grid item xs={5} className={classes.imageBox}>
            <Box>
              <Typography variant="subtitle1" component="h4" className={classes.title}>
                Our team of experts is dedicated to providing you with the best technology solutions for your business.
                Contact us today to learn more!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Card className={`mx-auto  shadow-sm p-3 mb-5  ${classes.main}`}>
              <CardBody className="p-3 w-100 d-flex flex-column">
                <form className={classes.mainForm} onSubmit={handleSubmit}>
                  <>
                    {" "}
                    {defaultSignupFields.map((field) => {
                      return (
                        <InputBox
                          key={field.name}
                          {...field}
                          onChange={handleChange}
                          error={errors[field?.name]}
                          value={values[field?.name]}
                          readonly={field.readonly}
                          className={classes.inputCustom}
                        />
                      );
                    })}
                  </>
                  <div className={`${classes.checkDivStyle} form-group form-check`}>
                    <div>
                      <input
                        type="checkbox"
                        className={`${classes.checkBoxStyle} form-check-input`}
                        id="check"
                        onChange={handleChange}
                        name="check"
                        checked={check}
                      />
                      <small className={`${classes.keepme}`}>
                        I accept
                        <span className={`${classes.terms}`}>Terms & Conditions.</span>
                      </small>
                      <span className="text-danger">{errors.check}</span>
                    </div>
                  </div>
                  <div className={`${classes.submitStyle} text-center`}>
                    <Button disabled={loading} type="submit" className={`${classes.button}  btn btn-primary`}>
                      Apply
                    </Button>
                  </div>
                </form>
                <small className={`${classes.Account}`}>
                  Already have an account? &nbsp;
                  {!isAdminAuth && (
                    <Link className={`${classes.loginStyle} text-blue`} to={`${navigateBaseUrl}login`}>
                      Login Now
                    </Link>
                  )}
                </small>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Signup;

const useStyles: any = makeStyles({
  parentDiv: {
    border: "1px solid #3F4349",
    margin: "50px 0px !important",
  },
  mainForm: {
    width: "420px",
    display: "block",
    margin: "auto",
    "@media (max-width: 780px)": {
      width: "100%",
    },
  },
  main: {
    // marginTop: "120px",
    background: "none !important",
    boxShadow: "0px 18px 58px 16px rgba(0, 0, 0, 0.06)",
    "@media (max-width: 780px)": {
      marginTop: "30px",
      oveflow: "hidden",
      width: "auto",
    },
    width: "630px",
    height: "auto",
    borderRadius: "0px",
  },
  submitStyle: {
    marginTop: "32px",
  },
  button: {
    marginTop: "35px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    color: "#FFF",
    borderRadius: "0px",
    borderColor: "#00C194",
    borderWidth: "0px",
    height: "54.19px",
    background: "#00C194",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    "&:hover": {
      background: "#10257F",
      borderRadius: "0px",
      borderColor: "#10257F",
    },
  },
  Account: {
    height: "21px",
    paddingTop: "30px",
    paddingBottom: "53px",
    left: "587px",
    marginTop: "10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400 " as any,
    fontSize: "14px",
    lineHeight: "21px",
    alignItems: "center",
    textAlign: "center",
    color: "#757575",
  },
  Heading: {
    textAlign: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600" as any,
    fontSize: "32px",
    lineHeight: "54px",
    letterSpacing: "0.01em",
    textTransform: "capitalize",
    color: "#10257F",
  },
  Title: {
    width: "100%",
    height: "44px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500" as any,
    fontSize: "18px",
    lineHeight: "28px",
    color: "#001D23",
  },
  formControl: {
    marginTop: "23px",
    display: "block",
    alignItems: "flexStart",
    width: "100%",
    height: "55px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "28px",
    color: "#001D23",
  },

  forgotPassword: {
    width: "124px",
    height: "21px",
    left: "804px",
    top: "755px",

    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400" as any,
    fontSize: "14px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: " #757575",
  },
  errors: {
    fontSize: "13px",
    color: "red",
    marginTop: "5px",
  },
  keepme: {
    flexWrap: "wrap",
    paddingLeft: "6px",
    left: "543px",
    top: "755px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400" as any,
    fontSize: "14px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#757575",
  },
  terms: {
    color: "#FFFF",
    marginLeft: "3px",
  },
  checkBoxStyle: {
    marginTop: "2px",
    width: "18px",
    height: "18px",
    borderRadius: "0px",
    boxShadow: "none",
    "&:checked": {
      backgroundColor: "#00C194",
      borderRadius: "0px",
      boxShadow: "none",
    },
  },

  loginStyle: {
    cursor: "pointer",
    color: "#FFFF !important",
    textDecorationLine: "underline !important",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
  checkDivStyle: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "21px",
  },

  imageBox: {
    borderRight: "1px solid #3F4349 !important",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputCustom: {
    border: "1px solid #8A8A8A !important",
    color: "#505050 !important",
  },
  title: {
    fontFamily: "Poppins !important",
    fontSize: "16px !important",
    fontWeight: "400 !important",
    lineHeight: "28.8px !important",
    textAlign: "center !important" as any,
    color: "#8A8A8A !important",
    padding: "50px",
  },
});
