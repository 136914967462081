import React, { FC, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from "@react-google-maps/api";
import { Box, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { defaultCenter } from "../../utils/constants/MapDefaultValue";
import ReactPaginate from "react-paginate";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import LockIcon from "@mui/icons-material/Lock";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
export interface IMapProps {
  error?: any;
  data?: {
    searchBuilding: {
      edges: [
        {
          node: {
            latlng: any;
            address: string;
            city: string;
          };
        }
      ];
    };
  };
  loading?: boolean;
}

export interface LockUnlockProps {
  isUnlock: boolean;
  buildingSpaceId: string;
  buildingId: string;
}
export const LockUnlockComponent: FC<LockUnlockProps> = ({
  isUnlock,
  buildingSpaceId,
  buildingId,
}: LockUnlockProps) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (isUnlock) {
      navigate(`/building-search/allcomps-list/property-detail?id=${buildingSpaceId}`);
    } else {
      navigate(`/building-search/allcomps-list?id=${buildingId}`);
    }
  };
  return (
    <div onClick={handleClick}>
      {isUnlock ? (
        <IconButton>
          <RemoveRedEyeIcon />
        </IconButton>
      ) : (
        <IconButton>
          <LockIcon />
        </IconButton>
      )}
    </div>
  );
};

const MapContainer: FC<IMapProps> = ({ data }: IMapProps) => {
  const classes = useStyles();
  const [selectedMarker, setSelectedMarker] = useState<any>(null);
  const [googlemap, seGoogleMap] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 5;
  const mapStyles = [
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }],
    },
  ];
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyD1RHgvL2nY9-S_qpXl6d9P8HOP9aS6EPo",
  });
  const onLoad = (map: any) => {
    seGoogleMap(map);
  };

  useEffect(() => {
    if (googlemap && data && isLoaded) {
      const bounds = new window.google.maps.LatLngBounds();
      data?.searchBuilding?.edges.map((marker) => {
        return bounds.extend({
          lat: marker?.node?.latlng?.lat || 0,
          lng: marker?.node?.latlng?.lng || 0,
        });
      });
      googlemap.fitBounds(bounds);
    }
  }, [googlemap, data, isLoaded]);
  const handleMarkerClick = (markerdata: any) => {
    setCurrentPage(0);
    setSelectedMarker(markerdata);
    setIsOpen(true);
  };
  const pageCount = Math.ceil(selectedMarker?.buildingSpaces.length / pageSize);
  const startIndex = currentPage * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageData = selectedMarker?.buildingSpaces?.slice(startIndex, endIndex);

  const handlePageChange = (selected: { selected: number }) => {
    setCurrentPage(selected.selected);
  };
  return (
    <>
      <Container fluid="md" className={classes.containerStyle}>
        <Box className={classes.boxStyle}>
          <Box className={classes.loadBoxStyle}>
            {isLoaded ? (
              <GoogleMap
                mapContainerClassName={classes.mapStyles}
                onLoad={onLoad}
                onClick={() => setIsOpen(false)}
                zoom={13}
                center={defaultCenter}
                options={{
                  styles: mapStyles, // Apply custom map styles
                }}
              >
                {data?.searchBuilding?.edges?.map((item: any, index: any) => {
                  const { lat, lng } = item?.node?.latlng || { lat: 0, lng: 0 };
                  return (
                    <Marker
                      key={index}
                      position={{ lat, lng }}
                      title={item.node.address}
                      onMouseOver={() => handleMarkerClick(item.node)}
                      label={item.node.buildingSpaces.length.toString()}
                    >
                      {isOpen && item.node.id === selectedMarker.id && (
                        <InfoWindow
                          position={{ lat: selectedMarker?.latlng?.lat, lng: selectedMarker?.latlng?.lng }}
                          onCloseClick={() => {
                            setIsOpen(false);
                          }}
                        >
                          <section className={classes.popover_map}>
                            {" "}
                            <div className={classes.totalListing}>
                              <p className={classes.totalListing_para}>
                                {selectedMarker.buildingSpaces.length.toString()} Listings
                              </p>
                            </div>
                            <div className={classes.popover__wrapper}>
                              <div className={classes.popover__content}>
                                {selectedMarker?.buildingSpaces.length > 0 ? (
                                  <div className={classes.infoWindowContainer}>
                                    {currentPageData?.map((title: any, tableIndex: number) => (
                                      <div key={tableIndex}>
                                        <div className={`${classes.infoWindowStyles} d-flex align-items-center gap-4`}>
                                          <div className={classes.pro_pic}>
                                            <img
                                              className={classes.pro_pic_img}
                                              alt="img"
                                              src="https://cdn.realtor.ca/listing/TS638101650611870000/reb76/medres/0/1322030_3.jpg"
                                            />
                                          </div>
                                          <div>
                                            <h5 className="text-start">{title?.spaces_values?.suiteSize}</h5>
                                            <p>{title?.spaces_values?.address}</p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                    <div className={classes.pagination}>
                                      <ReactPaginate
                                        pageCount={pageCount}
                                        marginPagesDisplayed={0}
                                        pageRangeDisplayed={2}
                                        onPageChange={handlePageChange}
                                        forcePage={currentPage}
                                        containerClassName={"pagination"}
                                        breakLabel="..."
                                        renderOnZeroPageCount={null}
                                        previousLabel={<ArrowBackIosNewRoundedIcon fontSize="small" />}
                                        nextLabel={<ArrowForwardIosRoundedIcon fontSize="small" />}
                                        previousLinkClassName="arrowicon"
                                        nextLinkClassName="arrowicon"
                                        disabledClassName={"pagination__link--disabled"}
                                        activeClassName={"pagination__link--active"}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <p style={{ color: "#FFFF" }}>No data found</p>
                                )}
                              </div>
                            </div>
                          </section>
                        </InfoWindow>
                      )}
                    </Marker>
                  );
                })}
              </GoogleMap>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
};
export default MapContainer;
const useStyles: any = makeStyles((theme: { breakpoints: { up(arg0: string): any } }) => ({
  containerStyle: {
    padding: "0px 0px",
    width: "100%",
    maxWidth: "100%",
    marginBottom: 30,
  },
  pro_pic_img: {
    maxWidth: "100px !important",
    maxheight: "100% !important",
  },
  markerIcon: {
    padding: `7px 12px`,
    fontSize: "11px",
    width: "1100px",
    color: `white`,
  },
  mapStyles: {
    [theme?.breakpoints?.up("xs")]: {
      height: "630px",
    },
    [theme?.breakpoints?.up("sm")]: {
      height: "630px",
      width: "100%",
    },
    width: "100%",
    height: "630px",
  },
  boxStyle: {
    display: "flex",
    flexWrap: "wrap",
  },
  slideBoxStyle: {
    width: "330px",
    height: "1074px",
    overflowY: "hidden",
    overflowX: "hidden",
    backgroundColor: "white",
  },
  buildingStyle: {
    height: "1074px",
    overflowY: "auto",
    overflowX: "hidden",
    paddingRight: "18px",
  },
  cardStyle: {
    [theme?.breakpoints?.up("xs")]: {
      width: "230px",
      height: "348px",
    },
    [theme?.breakpoints?.up("sm")]: {
      width: "258px",
      height: "348px",
    },
    width: "258px",
    height: "348px",
    margin: "30px",
    paddingRight: "18px",
    border: "1px solid #C4C4C4",
    borderRadius: "5px",
  },
  imgStyle: {
    [theme?.breakpoints?.up("xs")]: {
      width: "230px",
      height: "190px",
    },
    [theme?.breakpoints?.up("sm")]: {
      width: "258px",
      height: "190px",
    },
    width: "258px",
    height: "190px",
    borderRadius: "4px 4px 0px 0px",
  },
  descriptionStyle: {
    alignItems: "center",
    paddingLeft: "20px",
    height: "57px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "28px",
    color: "#10257F",
  },
  pricingStyle: {
    paddingLeft: "20px",
    paddingTop: "20px",
    height: "27px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "27px",
    color: "#00C194",
  },
  addressStyle: {
    alignItems: "center",
    width: "225px",
    paddingLeft: "20px",
    paddingTop: "20px",
    height: "21px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#141B2D",
  },
  loadBoxStyle: {
    [theme?.breakpoints?.up("xs")]: {
      height: "630px",
    },
    [theme?.breakpoints?.up("sm")]: {
      height: "630px",
      width: "100%",
    },
    width: "100%",
    overflow: "hidden",
    border: "2px solid #C4C4C4",
  },
  popover_map: {
    margin: "auto",
    textAlign: "center",
    maxHeight: "300px",
  },
  totalListing: {
    background: "#E6E6E6",
  },
  totalListing_para: {
    padding: "4px 3px",
    textAlign: "start",
    fontWeight: "bold",
    color: "#10257F",
    fontSize: "20px",
  },
}));
