import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { DialogTitle, Typography } from "@mui/material";
export interface InputProps {
  openModal: boolean;
  onClose(): void;
  handleSubmit(): void;
  loadingState?: boolean;
  refetchdata?: any;
  data?: any;
  creditscore?: any;
  name?: string;
}

export default function AlertModal({ openModal, onClose, handleSubmit, creditscore, name }: InputProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const content = React.useMemo(() => {
    switch (name) {
      case "AllComps":
        return creditscore ? (
          <DialogTitle className="dialogMain">
            <Typography id="heading">
              Your credit score is {creditscore}.And 3 credits will be deducted to unlock this Comp. Do you want to
              proceed?
            </Typography>
          </DialogTitle>
        ) : (
          <DialogTitle>
            <Typography id="heading">You don't have credit.</Typography>
          </DialogTitle>
        );
      case "UserManagement":
        return (
          <DialogTitle className="dialogMain">
            <Typography id="heading">Are You Sure, Do You Want To Delete This User?</Typography>
          </DialogTitle>
        );
      default:
        break;
    }
  }, [creditscore, name]);
  return (
    <div>
      <Dialog fullScreen={fullScreen} open={openModal} onClose={onClose} aria-labelledby="heading">
        {content}
        <DialogActions className="buttonDiv">
          <Button autoFocus onClick={onClose} className="cancel_button">
            Cancel
          </Button>
          <Button onClick={handleSubmit} autoFocus className="proceed_button">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
