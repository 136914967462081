import backwordbutton from "../assets/images/logos/back.png";
function PrevArrow(props: any) {
  const { className, style, onClick, customStyle, prevButton } = props;
  return (
    <img
      alt="arrow"
      onClick={onClick}
      className={className}
      style={{
        ...style,
        ...customStyle,
      }}
      src={prevButton || backwordbutton}
    />
  );
}
export default PrevArrow;
