import React, { FC } from "react";
import { Card, CardBody, Container } from "reactstrap";
import useForm from "../../../Hooks/useForm";
import { defaultForgetPasswordFields } from "../../../utils/constants/defaultAuthFields";
import Toaster from "../../../common/Toaster";
import { InputBox } from "../Form";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import frame2 from "../../../assets/images/dark/close.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
export interface IForgotProps {
  navigateBaseUrl: string;
  error?: any;
  data?: string;
  loading?: boolean;
  onSubmitCallback(values: { [key: string]: string | number }): void;
}
const ForgotPassword: FC<IForgotProps> = ({ onSubmitCallback, error, data }: IForgotProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { handleChange, values, errors, handleSubmit } = useForm(onSubmitCallback, defaultForgetPasswordFields);

  const handleCloseClick = () => {
    navigate("/");
  };
  return (
    <>
      <Container fluid>
        <Box onClick={handleCloseClick} className={classes.iconBox}></Box>

        <Card className={` mx-auto  shadow-sm p-3 mb-5 ${classes.main}`}>
          <CardBody className="p-3 w-100 d-flex flex-column">
            <h3 className={classes.headingStyle}> Forgot Password?</h3>
            <Typography variant="subtitle1" sx={{ mt: 3 }} className={classes.titleTypography}>
              Enter your login email and we’ll send you a <br></br> link to reset your password.
            </Typography>
            <form className={classes.mainForm} onSubmit={handleSubmit}>
              {defaultForgetPasswordFields.map((field) => (
                <InputBox
                  key={field.name}
                  {...field}
                  onChange={handleChange}
                  error={errors[field.name]}
                  value={values[field.name]}
                  className={classes.inputCustom}
                />
              ))}
              <div className="text-center">
                <button type="submit" className={`${classes.button} btn btn-primary`}>
                  Submit
                </button>
              </div>
              <Toaster error={error} />
              <p className={`${classes.errors}`}>{data}</p>
            </form>
            <small className={`${classes.Account}`}>
              <span>
                <ArrowBackIcon />
              </span>{" "}
              Back to &nbsp;
              <Link className={`${classes.loginStyle} text-blue`} to={"/login"}>
                Login
              </Link>
            </small>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};
export default ForgotPassword;
const useStyles = makeStyles({
  main: {
    margin: "50px 0px",
    boxShadow: "0px 18px 58px 16px rgba(0, 0, 0, 0.06)",
    background: "none !important",
    "@media (max-width: 780px)": {
      marginTop: "30px",
      oveflow: "hidden",
      width: "auto",
    },
    width: "630px",
    height: "auto",
    borderRadius: "0rem",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  mainForm: {
    width: "420px",
    display: "block",
    margin: "auto",
    "@media (max-width: 780px)": {
      width: "100%",
    },
  },
  loginStyle: {
    cursor: "pointer",
    color: "#FFFF !important",
    textDecorationLine: "underline !important",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
  inputCustom: {
    border: "1px solid #8A8A8A !important",
    color: "#505050 !important",
  },
  Account: {
    height: "21px",
    paddingTop: "30px",
    paddingBottom: "53px",
    left: "587px",
    marginTop: "10px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400 " as any,
    fontSize: "14px",
    lineHeight: "21px",
    alignItems: "center",
    textAlign: "center",
    color: "#757575",
  },
  button: {
    marginTop: "35px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    color: "#FFF",
    borderRadius: "0px",
    borderColor: "#00C194",
    borderWidth: "0px",
    height: "54.19px",
    background: "#10257F",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    "&:hover": {
      background: "#808080",
      borderRadius: "0px",
      borderColor: "#808080",
    },
  },
  account: {
    paddingBottom: "53px",
    height: "21px",
    left: "587px",
    paddingTop: "30px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    alignItems: "center",
    textAlign: "center",
    color: "#757575",
  },
  headingStyle: {
    textAlign: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "32px",
    lineHeight: "54px",
    letterSpacing: "0.01em",
    textTransform: "capitalize",
    color: "#FFFF",
  },
  title: {
    width: "100%",
    height: "44px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#001D23",
  },
  formControl: {
    boxSizing: "border-box",
    display: "block",
    alignItems: "flexStart",
    padding: "18.5px 0px 15.5px 12px",
    width: "100%",
    height: "44px",
    border: "2px solid #DDDDDD",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#001D23",
  },
  titleTypography: {
    fontFamily: "Poppins !important",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "21.79px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  iconBox: {
    "&::after": {
      content: '""',
      position: "absolute",
      top: "0px",
      right: "0px",
      width: "2%",
      height: "4%",
      cursor: "pointer",
      transform: "translate(-20px, 20px)",
      backgroundImage: `url(${frame2})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top right",
    },
  },

  forgotPassword: {
    width: "124px",
    height: "21px",
    left: "804px",
    top: "755px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: " #757575",
  },
  errors: {
    fontSize: "13px",
    color: "red",
    textAlign: "center",
    marginTop: "10px",
    "@media (max-width: 500px)": {
      paddingLeft: "15px",
    },
  },
  keepMe: {
    height: "21px",
    left: "543px",
    top: "755px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
    textAlign: "center",
    color: "#757575",
  },
  containerStyle: {
    background: "#F7FDFC",
    padding: "20px 0px",
  },
  checkBoxStyle: {
    marginLeft: "100px",
    width: "18px",
    borderRadius: "0px",
    height: "18px",
    marginTop: "1.5px",
    "&:checked": {
      borderRadius: "0px",
      backgroundColor: "#00C194",
    },
  },
  registerStyle: {
    textDecorationLine: "underline !important",
    textDecoration: "none",
    cursor: "pointer",
    color: "#00C194",
    "&:hover": {
      color: "#10257F",
    },
  },
  checkStyleDiv: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: "21px",
  },
});
