import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { WorkData } from "../../../utils/constants/workData";

export default function WorkComponent() {
  const classes = useStyles();
  return (
    <List sx={{ mb: "0", p: "0" }}>
      {WorkData?.map((item, index) => (
        <ListItem className={classes.boxList} key={index} alignItems="flex-start">
          <ListItemAvatar>
            <Avatar className={classes.imageIcon} alt={item?.image} src={item?.image} />
          </ListItemAvatar>
          <ListItemText
            className={classes.boxListText}
            primary={<Typography className={classes.customPrimary}>{item?.title}</Typography>}
            secondary={
              <React.Fragment>
                <Typography
                  className={classes.customTypography}
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                >
                  {item?.discription}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}

const useStyles = makeStyles(() => ({
  imageIcon: {
    minWidth: "70px",
    minHeight: "70px",
    marginRight: "25px",
  },

  boxList: {
    border: "1px solid #3F4349",
    // minHeight: "289px",
    justifyContent: "center !important",
    alignItems: "center !important",
    padding: "0px 60px !important",
  },

  boxListText: {
    height: "100%",
    minHeight: "250px",
    justifyContent: "center",
    display: "flex",
    borderLeft: "1px solid #3F4349",
    paddingLeft: "20px",
    flexDirection: "column",
    margin: "0px !important",
  },
  customPrimary: {
    fontFamily: "Inter !important",
    fontWeight: "700 !important",
    fontSize: "24px !important",
    fontHeight: "29.05px !important",
    color: "#FFFFFF",
  },

  customTypography: {
    fontFamily: "Poppins !important",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: "#BABABA",
  },
}));
