import React, { forwardRef } from "react";
import { Col, Table, Button, Container, Row } from "reactstrap";
import ViewImg from "../../assets/images/logos/view-img.png";
const DealsInfo = forwardRef((props: any, ref: any) => {
  const { data } = props;

  const DealsBuildingData = (data1: any, data2: any = null) => {
    return data1 || data2 || "---";
  };
  return (
    <div ref={ref}>
      <Container fluid className="p-0">
        <Row>
          <Col lg={5}>
            <img alt="logo" src={ViewImg} className="view-img"></img>
          </Col>
          <Col lg={7}>
            <Table striped>
              <thead>
                <tr>
                  <th style={{ width: "70%" }}></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Year Built & Year Renovated</th>
                  <td className="address-txt">
                    {DealsBuildingData(data?.getDealById?.Building?.yearBuilt, data?.getDealById?.startDate)}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Landlord</th>
                  <td className="address-txts">
                    {DealsBuildingData(data?.getDealById?.Building?.ownerPropertyManager, data?.getDealById?.landlord)}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Building Size (sq. ft.)</th>
                  <td className="address-txt">
                    {DealsBuildingData(data?.getDealById?.Building?.buildingSize, data?.getDealById?.buildingSize)}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Typical Floor Plate (sq. ft.)</th>
                  <td className="address-txt">
                    {DealsBuildingData(
                      data?.getDealById?.Building?.typicalFloorPlate,
                      data?.getDealById?.typicalFloorPlate
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Number of Floors</th>
                  <td className="address-txt">
                    {DealsBuildingData(data?.getDealById?.Building?.floors, data?.getDealById?.floors)}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Building Class</th>
                  <td className="address-txt">{DealsBuildingData(data?.getDealById?.Building?.class)}</td>
                </tr>
                <tr>
                  <th scope="row">Total Market Vacancy as of deal date</th>
                  <td className="address-txt">{DealsBuildingData(data?.getDealById?.daysOnMarket)}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row className="my-4">
          <Col>
            <Button className="btn btn-primary">View Space Comps</Button>
          </Col>
          <Col className="text-center">
            <Button className="btn btn-primary">View Building Availability</Button>
          </Col>
          <Col className="text-end">
            <Button className="btn btn-primary">View Building Stacking Plan</Button>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Table striped>
              <thead>
                <tr>
                  <th style={{ width: "60%" }}></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Tenant</th>
                  <td className="address-txts"> {data?.getDealById?.tenant || "---"}</td>
                </tr>
                <tr>
                  <th scope="row">Net Rent</th>
                  <td className="address-txt">
                    {data?.getDealById?.netRent?.split("\n").map((item: any, index: any) => {
                      return <p key={index}>{item || "---"}</p>;
                    })}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Net Free Rent (months)</th>
                  <td className="address-txt">{DealsBuildingData(data?.getDealById?.netReentMonthly)}</td>
                </tr>
                <tr>
                  <th scope="row">Fixturing Period</th>
                  <td className="address-txt">{DealsBuildingData(data?.getDealById?.fixturn)}</td>
                </tr>
                <tr>
                  <th scope="row">Gross Rent (PSF)</th>
                  <td className="address-txt">{DealsBuildingData(data?.getDealById?.grossRent)}</td>
                </tr>
                <tr>
                  <th scope="row">Show Suite</th>
                  <td className="address-txt">{DealsBuildingData(data?.getDealById?.suite)}</td>
                </tr>
                <tr>
                  <th scope="row">Term (Months)</th>
                  <td className="address-txt">{DealsBuildingData(data?.getDealById?.terms)}</td>
                </tr>
                <tr>
                  <th scope="row">Real Rate (NER)</th>
                  <td className="address-txt">{DealsBuildingData(data?.getDealById?.ner)}</td>
                </tr>
                <tr>
                  <th scope="row">Model Suite Cost (PSF)</th>
                  <td className="address-txt">{DealsBuildingData(data?.getDealById?.modelSuiteCost)}</td>
                </tr>
                <tr>
                  <th scope="row">Net/Gross Free Rent (Months)</th>
                  <td className="address-txt">{DealsBuildingData(data?.getDealById?.netGrossFreeRent)}</td>
                </tr>
                <tr>
                  <th scope="row">Selling Fee Total (PSF)</th>
                  <td className="address-txt">{DealsBuildingData(data?.getDealById?.sellingFeeTotal)}</td>
                </tr>
                <tr>
                  <th scope="row">Listing Fee Total (PSF)</th>
                  <td className="address-txt">{data?.getDealById?.listingFeeTotal || "---"}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col lg={6}>
            <Table striped>
              <thead>
                <tr>
                  <th style={{ width: "60%" }}></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Selling Commissions</th>
                  <td className="address-txt">{data?.getDealById?.sellingCommissions || "---"}</td>
                </tr>
                <tr>
                  <th scope="row">Listing Commissions</th>
                  <td className="address-txt">{data?.getDealById?.listingCommissions || "---"}</td>
                </tr>
                <tr>
                  <th scope="row">Type</th>
                  <td className="address-txt">{data?.getDealById?.type || "---"}</td>
                </tr>
                <tr>
                  <th scope="row">Deal Done</th>
                  <td className="address-txt">{data?.getDealById?.dealDone || "---"}</td>
                </tr>
                <tr>
                  <th scope="row">Commencement Date</th>
                  <td className="address-txt">{data?.getDealById?.startDate || "---"}</td>
                </tr>
                <tr>
                  <th scope="row">Size (SQ.FT)</th>
                  <td className="address-txt">{data?.getDealById?.size || "---"}</td>
                </tr>
                <tr>
                  <th scope="row">Floor</th>
                  <td className="address-txt">{data?.getDealById?.floor || "---"}</td>
                </tr>
                <tr>
                  <th scope="row">Avg. Net Rent Over Term</th>
                  <td className="address-txt">{data?.getDealById?.avgNetRentOverTerm || "---"}</td>
                </tr>
                <tr>
                  <th scope="row">Total Additional Rent (PSF)</th>
                  <td className="address-txt">{data?.getDealById?.totalAdditionalRent || "---"}</td>
                </tr>
                <tr>
                  <th scope="row">Cash Allowance (PSF)</th>
                  <td className="address-txt">{data?.getDealById?.cashAllowance || "---"}</td>
                </tr>
                <tr>
                  <th scope="row">GER- Real Gross Rate</th>
                  <td className="address-txt">{data?.getDealById?.realGrossRate || "---"}</td>
                </tr>
              </tbody>
            </Table>
            <Col className="text-end mb-4">
              <Button className="btn btn-primary">Verify Comp Now</Button>
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
});
export default DealsInfo;
