import React, { useCallback } from "react";
import { DropdownMenu } from "reactstrap";
import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getLocalData } from "../../../utils/commonFunctions";
export interface IDropDownMenuProps {
  options: string[];
}
const DropDownMenu = ({ options }: IDropDownMenuProps) => {
  const navigate = useNavigate();
  const { isAdmin }: any = getLocalData();
  const handleNavigation = useCallback(
    (target: string) => {
      if (target === "Logout") {
        localStorage.clear();
        isAdmin === "false" ? navigate("/login") : navigate("/admin/login");
      }
      if (target === "CreditScore") {
        navigate("/credit-score");
      }
      if (target === "Profile") {
        navigate("/profile");
      }
    },
    [isAdmin, navigate]
  );

  return (
    <>
      <DropdownMenu>
        {options.map((option: string, index: number) => (
          <MenuItem onClick={() => handleNavigation(option)} key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </DropdownMenu>
    </>
  );
};
export default DropDownMenu;
