import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "../PrivateRoutes";
import ErrorPage from "../../../pages/ErrorPage";
import UserSignup from "../../../pages/auth/user/UserSignup";
import UserLogin from "../../../pages/auth/user/UserLogin";
import UserForgotPassword from "../../../pages/auth/user/UserForgotPassword";
import HomePage from "../../../pages/client-pages/HomePage";
import BrowserSearch from "../../../pages/client-pages/browserListing/BrowserSearch";
import UserResetPassword from "../../../pages/auth/user/UserResetPassword";
import GetStarted from "../../../pages/getStarted/GetStarted";
import Deals from "../../deals/Deals";
import PropertyDetail from "../../../pages/client-pages/browserListing/PropertyDetail";
import CreditScore from "../../../pages/client-pages/CreditScore";
import AllCompsList from "../../../pages/client-pages/AllCompsList";
import ErrorMessage from "../../error/ErrorMessage";
import SuccessMessage from "../../success/SuccessMessage";
import PublicRoutes from "../PublicRoutes";
import ScrollToTop from "../../../utils/scrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AboutUS from "../../aboutus/AboutUS";
import Navbar from "../../layout/Navigations/Navbar";
import UserProfile from "../../../pages/client-pages/userProfile/UserProfile";
import MarketDetail from "../../../pages/client-pages/MarketDetail";
import HowToWork from "../../../pages/client-pages/howToWork/HowToWork";
import AboutImages from "../../../pages/client-pages/aboutImages/AboutImages";
const index = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();
  const isLoginOrForgotPasswordPage = () => {
    return (
      location.pathname === "/login" ||
      location.pathname === "/forgot-password" ||
      location.pathname === "/about-images"
    );
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      {!isLoginOrForgotPasswordPage() && <Navbar />}

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/market-detail" element={<MarketDetail />} />
        <Route path="/how-spacecomps-works" element={<HowToWork />} />
        <Route path="/about-images" element={<AboutImages />} />
        <Route path="/" element={<PrivateRoute path="user" />}>
          <Route path="/your-deals" element={<Deals />} />
          <Route path="/building-search" element={<BrowserSearch />} />
          <Route path="/credit-score" element={<CreditScore />} />
          <Route path="/building-search/allcomps-list/property-detail" element={<PropertyDetail />} />
          <Route path="/building-search/allcomps-list" element={<AllCompsList />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/*" element={<ErrorPage />} />
          <Route path="/success" element={<SuccessMessage />} />
          <Route path="/error" element={<ErrorMessage />} />
        </Route>
        <Route path="/" element={<PublicRoutes />}>
          <Route path="forgot-password" element={<UserForgotPassword />} />
          <Route path="/reset-password" element={<UserResetPassword />} />
          <Route path="/getstarted" element={<GetStarted />} />
          <Route path="/login" element={<UserLogin />} />
          <Route path="/signup" element={<UserSignup />} />
          <Route path="/*" element={<ErrorPage />} />
        </Route>
        <Route path="/about-us" element={<AboutUS />} />
      </Routes>
      <ToastContainer />
    </React.Fragment>
  );
};
export default index;
