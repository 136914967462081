import { Container } from "reactstrap";
import React from "react";
import { makeStyles } from "@mui/styles";
import { darkblue } from "../../styles/variables";
import GetStartedButton from "../../common/GetStartedButton";
function Subscribe() {
  const styles = useStyles();
  return (
    <div className={styles.boxRoot}>
      <Container fluid="md">
        <div className={styles.containerRoot}>
          <p className={`${styles.textCss} ${styles.commonCss}  ${styles.textCenter}`}>Apply to Become a Member</p>
          <GetStartedButton path="/getstarted" name="Apply" />
        </div>
      </Container>
    </div>
  );
}
export default Subscribe;
const useStyles: any = makeStyles({
  boxRoot: {
    backgroundColor: "#EBEBEB",
    marginBottom: "100px",
    padding: "50px 0",
    "@media (max-width: 780px)": {
      height: "auto",
      padding: "0px",
      paddingTop: "15px",
      paddingBottom: "15px",
    },
  },
  containerRoot: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 780px)": {
      gap: "25px",
      justifyContent: "center",
    },
  },

  commonCss: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    textTransform: "capitalize",
    color: "#10257F",
  },
  textCss: {
    fontWeight: 600,
    fontFamily: "Inter",
    fontSize: "28px",
    lineHeight: "54px",
    letterSpacing: "0.01em",
    "@media (max-width: 780px)": {
      fontSize: "19px",
      lineHeight: "26px",
    },
  },
  subscribePara: {
    fontWeight: 400,
    color: darkblue,
    fontStyle: "normal",
    fontSize: "18px",
    fontFamily: "Poppins",
    lineHeight: "27px",

    "@media (max-width: 780px)": {
      fontSize: "16px",
      textAlign: "center",
    },
  },
  textCenter: {
    textAlign: "center",
  },
});
