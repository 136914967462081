import React from "react";
import { makeStyles } from "@mui/styles";

interface SelectOptionsProps {
  options: any[];
  defaultname: string;
}

const SelectOptions: React.FC<SelectOptionsProps> = ({ options, defaultname }) => {
  const classes = useStyles();
  const getFilterValue = (item: any, filtername: any) => {
    if (filtername === "All") {
      const val = item === "All" ? "0-5000000" : item;
      return val.replaceAll(",", "");
    }
    return item;
  };

  const getFilterName = (item: any, name: any) => {
    if (name === "All" && item !== "All") {
      return item + " SF";
    }
    return item;
  };

  return (
    <>
      <option value="">Please Select</option>
      {options.map((item, index) => {
        const optionValue = getFilterValue(item, defaultname);
        const filterName = getFilterName(item, defaultname);
        return (
          <option className={classes.optionBox} key={index} value={optionValue}>
            {filterName}
          </option>
        );
      })}
    </>
  );
};

export default SelectOptions;
const useStyles = makeStyles({
  optionBox: {
    boxSizing: "content-box",
    padding: " 5px 0",
    color: "#2E3642 !important",
  },
});
