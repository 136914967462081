export const getCompsRange = [
  "All",
  "0-5,000",
  "5,001-10,000",
  "10,001-25,000",
  "25,001-50,000",
  "50,001-100,000",
  "100,000 +",
];
export const getSubmarket = [
  "Financial Core",
  "Greater Core",
  "Downtown South",
  "Downtown North",
  "Downtown East",
  "Downtown West",
  "Libarty Village",
  "Bloor & Yonge",
  "St.Clair & Yonge",
  "Eglinton & Yonge",
];
export const getDateRange = ["All", "12 months", "24 months", "36 months"];
export const getMarket = ["Toronto"];
