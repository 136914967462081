import React, { useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useForm from "../../../Hooks/useForm";
import SelectBox from "../SelectBox/SelectBox";
export interface ILoginProps {
  open: boolean;
  columns: any;
  error?: any;
  loading?: boolean;
  success: boolean;
  onClose(): void;
  onSubmitCallback(values: { [key: string]: string | number }): void;
}

function UserModal({ open, columns, onClose, onSubmitCallback, success }: ILoginProps) {
  const { handleChange, values, clearForm, errors, handleSubmit } = useForm(onSubmitCallback, columns);
  const handleModalClosed = () => {
    clearForm();
    onClose();
  };
  useEffect(() => {
    if (success) {
      clearForm();
    }
  }, [success]);
  const classes = useStyles();

  return (
    <>
      <Dialog open={open}>
        <DialogTitle textAlign="center">ADD USER</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Stack
              className={classes.stackStyle}
              sx={{
                paddingTop: "5px",
                minWidth: {
                  xs: "300px",
                  sm: "360px",
                  md: "400px",
                },
              }}
            >
              {columns.map(
                (column: {
                  [x: string]: any;
                  accessorKey: any;
                  header: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
                  editVariant: string;
                }) => {
                  let component: React.ReactNode;
                  const { type, header, accessorKey, row } = column;
                  switch (type) {
                    case "select":
                      component = (
                        <SelectBox
                          label={header ?? accessorKey}
                          handleChange={handleChange}
                          name={accessorKey}
                          options={row}
                          value={values.role}
                          key={accessorKey}
                          error={errors[accessorKey]}
                        />
                      );
                      break;
                    case "email":
                      component = (
                        <TextField
                          key={accessorKey}
                          label={header}
                          type={type}
                          name={accessorKey}
                          className="inputTextField"
                          onChange={handleChange}
                          value={values[accessorKey]}
                          helperText={<p className="text-danger">{errors[accessorKey]}</p>}
                        />
                      );
                      break;
                  }
                  return component;
                }
              )}
            </Stack>
            <DialogActions sx={{ p: "1.25rem", alignItems: "end" }}>
              <Button onClick={handleModalClosed}>Cancel</Button>
              <Button className={classes.addUserStyle} type="submit" variant="contained">
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default UserModal;

const useStyles = makeStyles({
  stackStyle: {
    width: "100%",
    gap: "1.5rem",
  },

  addUserStyle: {
    color: "#FFF",
    borderRadius: "0px",
    borderColor: "#10257F",
    borderWidth: "1px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    background: "#10257F !important",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    "&:hover": {
      background: "#00c194 !important",
      borderRadius: "0px",
      borderColor: "#00c194",
      color: "#fff",
    },
  },
});
