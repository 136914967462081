import { gql } from "@apollo/client";
export const LOGIN = gql`
  query Login($loginName: String, $password: String) {
    login(loginName: $loginName, password: $password) {
      token
      info {
        createdOn
        entityOid
        isNewUser
        isActive
        isPending
        lastLoginDate
        loginName
        firstName
        isAdmin
        firstName
      }
    }
  }
`;
