import { gql } from "@apollo/client";
export const SIGNUP = gql`
  mutation SignUpUser($input: userInput!) {
    signUpUser(input: $input) {
      code
      success
      message
      user {
        role {
          role
          roleConstraint
          id
        }
        phoneNumber
        market
        lastName
        id
        firstName
        email
        companyName
      }
    }
  }
`;
