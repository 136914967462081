import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";

export interface ISelectBoxProps {
  options: { [key: string]: string }[] | string[];
  value: any;
  handleChange: any;
  label: string;
  name: string;
  error?: string;
}
const SelectBox = ({ options, label, value, handleChange, name, error = "" }: ISelectBoxProps) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formStyle}>
      <InputLabel id="demo-select-small">{label}</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={value?.value ?? value}
        label={`${label}`}
        name={name}
        onChange={(e) => {
          handleChange(e);
        }}
      >
        {options.map((val: any, i) => (
          <MenuItem key={i} value={val?.id ?? val}>
            {val?.role ?? val ?? val?.value}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <p className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
          <p className="text-danger">{error}</p>
        </p>
      )}
    </FormControl>
  );
};
export default SelectBox;
const useStyles: any = makeStyles({
  formStyle: {
    boxSizing: "border-box",
    left: "0%",
    right: "0%",
    top: "0%",
    bottom: "0%",
    background: "none !important",
    color: "#FFFF !important",
    borderRadius: "4px",
  },
});
