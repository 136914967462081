import React, { useMemo, useEffect, useState } from "react";
import SpaceCompsTable from "../../components/layout/SpaceCompsTable/SpaceCompsTable";
import { buildingSpaces, dealComps } from "../../utils/constants/tableColumns";
import { useLazyQuery, useQuery } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { IconButton, Tooltip } from "@mui/material";
import {
  GET_BUILDING_SPACES_BY_ID,
  GET_BUILDING_DEALS_BYBUILDING_ID,
  UPDATE_SPACES_FAVOURITE,
  UPDATE_DEAL_BY_ID,
} from "../../graphql/queries/building";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import InnerHeader from "../../components/Header/InnerHeader";
import AlertModal from "../../components/layout/AlertModal/AlertModal";
import { useAuth } from "../../contexts/auth/provideAuth";
import { useQueryData } from "../../utils/commonFunctions";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CREDIT_SCORE } from "../../graphql/queries/getCreditScore";
import GradeIcon from "@mui/icons-material/Grade";
import { toast } from "react-toastify";
const AllCompsList = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [rowID, setRowID] = useState("");
  const navigate = useNavigate();
  const { handleState } = useAuth();
  const query = useQueryData();
  const { data: creditData, refetch: crediitRefetch } = useQuery(CREDIT_SCORE, {
    fetchPolicy: "network-only",
  });
  const {
    data,
    loading: buildinLoading,
    refetch,
  } = useQuery<any>(GET_BUILDING_SPACES_BY_ID, {
    fetchPolicy: "network-only",
    variables: {
      targetId: query.get("id"),
    },
    onCompleted: crediitRefetch,
  });
  const {
    data: dealsdata,
    loading: dealsLoading,
    refetch: dealsRefetch,
  } = useQuery<any>(GET_BUILDING_DEALS_BYBUILDING_ID, {
    fetchPolicy: "network-only",
    variables: {
      buildingId: query.get("id"),
    },
  });

  const [updatedealbyId, { loading }] = useLazyQuery<any>(UPDATE_DEAL_BY_ID, {
    fetchPolicy: "network-only",
    onCompleted: dealsRefetch,
  });
  const [updateSpacesFavourite, { loading: favouriteLoading }] = useLazyQuery<any>(UPDATE_SPACES_FAVOURITE, {
    fetchPolicy: "network-only",
    onCompleted: refetch,
  });
  const [tableData, setTableData] = useState([]);
  const [selectTab, setSelectTab] = useState("comps");

  const handleSpaceListing = async () => {
    const creditAmount = creditData?.getCreditScore?.info?.creditAmount;
    if (!creditAmount || creditAmount <= 2) {
      navigate("/credit-score");
      return;
    }
    try {
      const { data: updateData, error: updateError } = await updatedealbyId({
        variables: { dealId: rowID?.toString() },
      });
      if (updateError || !updateData?.dealUnlockByCredit) {
        toast.error("Something went wrong");
        handleClose();
        return;
      }
      toast.success("Successfully unlocked");
      crediitRefetch();
      dealsRefetch();
      handleClose();
    } catch (error: any) {
      toast.error(error.message || "Something went wrong");
    }
  };
  const updateFavourite = async (id: any) => {
    try {
      const { error } = await updateSpacesFavourite({
        variables: { buildingSpacesId: id },
      });
      if (error) {
        refetch();
        toast.error("Favourite Removed Successfully");
        return;
      }
      toast.success("Favourite Added Successfully");
    } catch (error: any) {
      toast.error(error.message || "Something went wrong");
    }
  };
  useEffect(() => {
    if (data || dealsdata) {
      const array =
        selectTab === "comps"
          ? data?.getBuildingSpacesByTargetedId?.edges
          : dealsdata?.getBuildingDealsByBuildingId?.edges;
      setTableData(array);
    }
  }, [selectTab, data, dealsdata]);

  useEffect(() => {
    const isLoading = loading || buildinLoading || dealsLoading || favouriteLoading;
    handleState(!isLoading);
  }, [loading, handleState, buildinLoading, dealsLoading, favouriteLoading]);

  const spacesData = useMemo(
    () =>
      tableData?.map((d: any, index: number) => ({
        ...d?.Building,
        ...d?.node,
        id: index + 1,
        floors: d?.Building?.floors ?? d?.node?.floors,
        buildingSize: d?.Building?.buildingSize ?? d?.node?.buildingSize,
        physicalStatus: d?.Building?.buildingPhysicalStatus ?? d?.node?.physicalStatus,
        yearBuilt: d?.Building?.yearBuilt ?? d?.node?.yearBuilt,
        ownerPropertyManager: d?.Building?.ownerPropertyManager ?? d?.node?.ownerPropertyManager,
        listedOnDate: d?.node?.listedOnDate,
        size: d?.Building?.buildingSize ?? d?.node?.buildingSize,
        landlord: d?.node?.landlord,
        tenant: d?.node?.tenant,
        startDate: d?.Building?.startDate ?? d?.node?.startDate,
      })) ?? [],
    [tableData]
  );

  const handleClickOpen = (dataId: string) => {
    setRowID(dataId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleButtonClick = (pId: string, tabname: string) => {
    navigate(`/building-search/allcomps-list/property-detail?id=${pId}&tabname=${tabname}`);
  };

  if (!spacesData) {
    return <h2>Loading.....</h2>;
  }

  const handleRowAction = (row: any) => {
    const { id } = row;
    const space = spacesData[id - 1];
    const isUnlocked = space?.isUnlock;
    const isFavourite = space?.isFavourite;
    const handleBuildingDealClick = (rowid: any) => {
      if (!isUnlocked) {
        const dealId = dealsdata?.getBuildingDealsByBuildingId?.edges[rowid - 1]?.node?.id;
        handleClickOpen(dealId);
      }
    };
    switch (selectTab) {
      case "deals":
        return (
          <div>
            <Tooltip
              arrow
              placement="right"
              title={isUnlocked ? "View" : "Lock"}
              onClick={() => handleBuildingDealClick(id)}
            >
              <IconButton>
                {isUnlocked ? (
                  <RemoveRedEyeIcon
                    onClick={() => {
                      handleButtonClick(dealsdata?.getBuildingDealsByBuildingId?.edges[id - 1]?.node?.id, "deals");
                    }}
                  />
                ) : (
                  <LockIcon />
                )}
              </IconButton>
            </Tooltip>
          </div>
        );
      case "comps":
        return (
          <div className={classes.compsaction}>
            <Tooltip arrow placement="right" title="Favourite">
              <IconButton
                onClick={() => {
                  updateFavourite(data?.getBuildingSpacesByTargetedId?.edges[id - 1]?.node?.id);
                }}
              >
                <GradeIcon
                  sx={{
                    color: isFavourite ? "#10257f" : "inherit",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="View">
              <IconButton
                onClick={() => {
                  handleButtonClick(data?.getBuildingSpacesByTargetedId?.edges[id - 1]?.node?.id, "space");
                }}
              >
                <RemoveRedEyeIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      default:
        break;
    }
  };
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelectTab(newValue);
  };

  return (
    <>
      <InnerHeader props={"BUILDING DATA"} buttonAction={"Login"} />
      <AlertModal
        openModal={open}
        onClose={handleClose}
        handleSubmit={handleSpaceListing}
        refetchdata={data}
        loadingState={loading}
        creditscore={creditData?.getCreditScore?.info?.creditAmount}
        name="AllComps"
      />
      <div className={classes.topToolbarBoxStyling}>
        <div>
          <Tabs
            value={selectTab}
            className="mb-3 d-flex gap-5 p-0"
            onChange={handleChange}
            aria-label="scrollable force tabs example"
          >
            <Tab
              value="comps"
              label="Availabe Space"
              className={selectTab === "comps" ? classes.activeTab : classes.tabColor}
            />
            <Tab
              value="deals"
              label="Deal Comps"
              className={selectTab === "deals" ? classes.activeTab : classes.tabColor}
            />
          </Tabs>
        </div>
      </div>
      <div className={`${classes.tableDesined} `}>
        <SpaceCompsTable
          columns={selectTab !== "deals" ? buildingSpaces : dealComps}
          data={spacesData}
          getRowId={(row) => row.id}
          enableColumnFilterModes
          enableSorting={false}
          enableColumnOrdering
          enableRowActions={selectTab === "comps"}
          enableGrouping
          enableDensityToggle={false}
          enableGlobalFilter={false}
          enableFullScreenToggle={false}
          enableColumnFilters={false}
          enableHiding={false}
          enablePinning
          enableTopToolbar={true}
          enableToolbarInternalActions={false}
          muiTableHeadRowProps={{
            sx: {
              th: {
                textAlign: "center",
                textTransform: "uppercase",
              },
              "& tr:nth-of-type(even)": {
                backgroundColor: "grey",
              },
              "& th:first-child": {
                backgroundColor: "#FD6262 !important",
                color: "#fff",
              },
            },
          }}
          muiTableBodyProps={{
            sx: {
              "& tr td": {
                background: "#1B1C1E !important",
                color: "#FFFFFF",
                borderBottom: "1px solid #3F4349",
              },

              "& tr:nth-of-type(even)": {
                backgroundColor: "#1B1C1E !important",
                color: "#FFFFFF",
              },
            },
          }}
          renderRowActions={({ row }: any) => handleRowAction(row)}
        />
      </div>
    </>
  );
};
export default AllCompsList;
const useStyles: any = makeStyles({
  tableDesined: {
    textAlign: "center",
    overflowX: "auto",
    marginBottom: "40px",
  },
  divStyleCss: {
    display: "flex",
    gap: 15,
    maxHeight: "50px",
  },
  tableHeader: {
    color: "blue",
  },
  topToolbarBoxStyling: {
    display: "flex",
    padding: "40px 40px 0 40px",
    alignItems: "center",
    overflow: "hidden",
    justifyContent: "space-between",
  },
  addButtonStyle: {
    color: "#FFF",
    alignItems: "center",
    background: "#10257F !important",

    fontWeight: 500,
    fontSize: "16px",
    "&:hover": {
      background: "#00c194 !important",
      borderRadius: "0px",
      borderColor: "#00c194",
      color: "#fff",
    },
  },
  exportStyle: {
    color: "#10257F !important",
    background: "#FFF !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  downloadLink: {
    color: "#10257F !important",
  },
  compsaction: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  activeTab: {
    color: "#FD6262 !important",
    width: "150px !important",
  },
  tabColor: {
    color: "#FFFFFF !important",
  },
  tabsColor: {
    color: "#FFFF !important",
  },
});
