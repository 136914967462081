import React from "react";
import image from "../../assets/images/dark/HeaderLogoDark.png";
import { Col } from "reactstrap";
import { makeStyles } from "@mui/styles";
import withRouter from "../../utils/hoc/WithRouter";
import { Box } from "@mui/material";
const InnerHeader = (props: any) => {
  const classes = useStyles();
  return (
    <div>
      <Col className="innerHeader">
        <Col className={classes.paperContainer}>
          <Box className={classes.titleText}>
            <h2 className="mb-1">{props.props}</h2>
            {/* <Breadcrumbs /> */}
          </Box>
        </Col>
      </Col>
    </div>
  );
};
export default withRouter(InnerHeader);
const useStyles = makeStyles({
  paperContainer: {
    minHeight: "344px",
    position: "relative",
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    paddingTop: "240px",
    paddingBottom: "40px",
  },
  titleText: {
    textAlign: "start !important" as any,
    padding: "0px 40px 0 40px !important",
  },
});
