import { INavigation } from "../../ts/interfaces/navigations";
const iconName = "bi bi-bell";
const openIcon = "bi bi-caret-up-fill";
const closedIcon = "bi bi-caret-down-fill";
export const sidebarNavigations: INavigation[] = [
  {
    title: "Dashboard",
    path: "/admin/dashboard",
    icon: "bi bi-card-text",
    subNav: null,
  },
  {
    title: "User",
    path: "/admin/user-management",
    icon: "bi bi-person-circle",
    subNav: null,
  },
  {
    title: "Building",
    path: "/admin/management-static",
    iconClosed: closedIcon,
    iconOpen: openIcon,
    icon: "bi bi-house-add-fill",
    subNav: [
      {
        title: "Static Data",
        path: "/admin/management-static",
        icon: "bi bi-hdd-stack",
      },
      // {
      //   title: "Dynamic Data",
      //   path: "/admin/management-dynamic",
      //   icon: "bi bi-speedometer2",
      // },
    ],
  },
  {
    title: "Spaces",
    path: "/admin/spaces",
    icon: "bi bi-cart-plus-fill",
  },
  // {
  //   title: "Stacking",
  //   path: "/admin/stacking",
  //   icon: "bi bi-bag-plus",
  // },
  {
    title: "Deals",
    path: "/admin/deals",
    icon: "bi bi-graph-up-arrow",
  },
];
export const navabarNavigations: INavigation[] = [
  {
    title: "Starter",
    path: "/admin/starter",
    icon: iconName,
    subNav: null,
  },
  {
    title: "About",
    path: "/admin/about",
    icon: iconName,
    subNav: null,
  },
];
export const navbarMenuOptions: string[] = ["option 1", "option 2", "", "option 3", "option 4", ""];
export const adminDropdownOptions: string[] = [
  "Info",
  "My Account",
  "Edit Profile",
  "",
  "My Balance",
  "Inbox",
  "Logout",
];

export const userDropdownOptions: string[] = ["CreditScore", "Profile", "Logout"];
