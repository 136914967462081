import React from "react";
import { Nav } from "reactstrap";
import { sidebarNavigations } from "../../../../utils/constants/Navigations";
import { INavigation } from "../../../../ts/interfaces/navigations";
import SubMenu from "./Submenu";

const Sidebar = () => {
  return (
    <aside className="sidebarArea shadow">
      <div className="pageWrapper d-flex flex-nowrap">
        <aside className="sidebarArea shadow">
          <Nav vertical className="sidebarNav">
            {sidebarNavigations.map((navItem: INavigation, index: number) => {
              return <SubMenu item={navItem} key={index} />;
            })}
          </Nav>
        </aside>
      </div>
    </aside>
  );
};

export default Sidebar;
