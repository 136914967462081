import React from "react";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import { CompanyDetail } from "../../utils/constants/companyDetails";

export default function CompanyDetails() {
  const classes = useStyles();
  return (
    <Container>
      <div className={`${classes.main}`}>
        {" "}
        {CompanyDetail.map((item: any, index) => {
          return (
            <div key={index} className={`${classes.common} ${index !== 1 ? classes.parent : classes.parent2}`}>
              <img className={classes.img} src={item.image} alt={item.heading} />
              <div className={classes.item}>
                <h3 className={`${classes.root}`}>{item.heading}</h3>
                {item.description}
                {item.list && (
                  <ul className={classes.unorderedList}>
                    {item.list.map((listItem: any, listIndex: any) => {
                      return <li key={listIndex}>{listItem}</li>;
                    })}
                  </ul>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
}
const useStyles: any = makeStyles({
  main: {
    padding: "40px 0",
  },
  common: {
    display: "flex",
    flexWrap: "nowrap",
    gap: "50px",
    alignItems: "initial",
    "@media (max-width: 780px)": {
      flexDirection: "column-reverse",
      flexWrap: "wrap",
    },
  },
  parent: {
    marginBottom: "70px",
    flexDirection: "row-reverse",
  },
  parent1: {
    flexDirection: "row",
  },
  root: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "32px",
    lineHeight: "54px",
    letterSpacing: "0.01em",
    textTransform: "capitalize",
    color: "#10257F",
  },
  unorderedList: {
    color: "#10257F",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "32px",
    letterSpacing: "0px",
  },
  img: {
    height: "auto",
    margin: "auto",
    maxHeight: " 100%",
    maxWidth: "100%",
    width: "auto",
  },
});
