import React, { useMemo, useEffect, useState } from "react";
import SpaceCompsTable from "../../components/layout/SpaceCompsTable/SpaceCompsTable";
import { buildingManagementDynamic } from "../../utils/constants/tableColumns";
import { STATIC_BUILDING_DATA } from "../../graphql/queries/usersAdmin";
import { EDIT_DYNAMICDATA } from "../../graphql/mutations/userAdmin";
import { useLazyQuery, useMutation } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { MaterialReactTableProps } from "material-react-table";
import { Typography } from "@mui/material";
import { useAuth } from "../../contexts/auth/provideAuth";
import { toast } from "react-toastify";
const DynamicData = () => {
  const classes = useStyles();
  const { handleState } = useAuth();
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 5 });
  const [buildinglisting, { data, refetch, loading }] = useLazyQuery<any>(STATIC_BUILDING_DATA);
  const [editDynamicData, { loading: dynamicLoading }] = useMutation(EDIT_DYNAMICDATA, {
    fetchPolicy: "network-only",
    onCompleted: refetch,
  });
  useEffect(() => {
    buildinglisting({
      variables: {
        first: pagination.pageSize,
        after: pagination.pageSize * pagination.pageIndex,
        search: globalFilter ?? "",
      },
    });
  }, [buildinglisting, globalFilter, pagination.pageIndex, pagination.pageSize]);
  const dynamicData = useMemo(() => {
    return (
      data?.getBuilding?.edges.map((d: any, i: any) => {
        setRowCount(data?.getBuilding?.totalCount);
        return {
          ...d?.node,
          indexId: i + 1,
        };
      }) ?? []
    );
  }, [data]);
  const handleSaveRow: MaterialReactTableProps<any>["onEditingRowSave"] = async ({ exitEditingMode, values }) => {
    try {
      const editDynamicDataResponse = await editDynamicData({
        variables: {
          input: {
            id: values?.id,
            realityTaxes: Number(values?.realityTaxes),
            electricity: Number(values?.electricity),
            totalDirectAvailable: Number(values?.totalDirectAvailable),
            totalSubletAvailable: Number(values?.totalSubletAvailable),
            totalavailable: Number(values?.totalavailable),
            totalAdditionalRent: Number(values?.totalAdditionalRent),
            operatingCosts: Number(values?.operatingCosts),
          },
        },
      });
      if (editDynamicDataResponse.data) {
        toast.success("Dynamic data edited successfully");
        exitEditingMode();
        return;
      }
    } catch (e: any) {
      const errorMessage = e?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };
  useEffect(() => {
    const Loading = loading || dynamicLoading;
    handleState(!Loading);
  }, [loading, handleState, dynamicLoading]);

  return (
    <div className={classes.main}>
      <div className={`${classes.tableDesined} form-group form-check`}>
        <Typography component="span" variant="h4" sx={{ color: "#FFFF" }}>
          DYNAMIC DATA
        </Typography>
        <SpaceCompsTable
          columns={buildingManagementDynamic}
          data={dynamicData}
          editingMode="row"
          enableEditing
          onEditingRowSave={handleSaveRow}
          enableColumnFilterModes
          enableColumnOrdering
          onPaginationChange={setPagination}
          onGlobalFilterChange={setGlobalFilter}
          manualPagination={true}
          rowCount={rowCount}
          enableRowNumbers
          enableGrouping
          enablePinning
          enableRowActions
          state={{ pagination, globalFilter, showProgressBars: !dynamicData }}
          muiTablePaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "0",
              border: "1px dashed #e0e0e0",
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              backgroundColor: "#808080 !important",
              color: "#fff",
            },
          }}
        />
      </div>
    </div>
  );
};

export default DynamicData;
const useStyles: any = makeStyles({
  main: {
    maxWidth: "100%",
  },
  tableDesined: {
    textAlign: "center",
    marginTop: "40px",
    margin: "auto",
    width: "78vw",
    display: "block",
    overflowX: "auto",
  },
});
