import { gql } from "@apollo/client";
export const BUILDING_DETAIL_BY_USER = gql`
  mutation CreateBuildingDealByUser($input: createBuildingDealByUserInput) {
    createBuildingDealByUser(input: $input) {
      code
      success
      message
      info {
        deals_values
        created_at
        updated_at
      }
    }
  }
`;
