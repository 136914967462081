import { SetStateAction, useState } from "react";

export function useDataContext() {
  const [detailsData, setDetailsData] = useState({});
  const setDetails = (data: SetStateAction<{}>) => {
    setDetailsData(data);
  };
  return {
    detailsData,
    setDetails,
  };
}
