import React from "react";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import GetStartedButton from "../../../common/GetStartedButton";

const DetailInfo = ({ data }: any) => {
  const classes = useStyles();
  return (
    <Container className={classes.main}>
      <div className={`${classes.common}`}>
        {data.image && <img className={`${classes.carditem} ${classes.img} `} src={data.image} alt={data.title} />}
        <div className={classes.carditem}>
          <h3 className={`${classes.subtitle}`}>{data.subtitle}</h3>
          {data.list && (
            <ul className={`${classes.unorderedList} ${!data.image && classes.largeTextList}`}>
              {data.list.map((listItem: any, listIndex: any) => {
                return <li key={listIndex}>{listItem}</li>;
              })}
            </ul>
          )}
          <div style={{ marginTop: "30px" }}>
            <GetStartedButton path="/getstarted" name="Apply" />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DetailInfo;
const useStyles: any = makeStyles({
  main: {
    margin: "50px 0",
  },
  common: {
    display: "flex",
    flexWrap: "nowrap",
    gap: "20px",
    alignItems: "center",
    "@media (max-width: 780px)": {
      flexWrap: "wrap",
    },
  },
  img: {
    borderRadius: "30px 0 30px 0px",
  },
  reverse: {
    flexDirection: "row-reverse",
    "& img": {
      borderRadius: "0px 30px 0px 30px",
    },
  },
  parent: {
    marginBottom: "70px",
    flexDirection: "row-reverse",
  },
  parent1: {
    flexDirection: "row",
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "60px",
    fontWeight: "600",
    lineHeight: "36px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#201D1D",
    paddingBottom: "25px",
  },
  subtitle: {
    fontFamily: "Inter",
    fontSize: "50px",
    fontWeight: "600",
    lineHeight: "54px",
    letterSpacing: "0.01em",
    textAlign: "left",
    color: "#FFFFFF",
    "@media (max-width: 780px)": {
      fontSize: "30px",
      lineHeight: "40px",
    },
  },

  unorderedList: {
    color: "#8A8A8A",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "32px",
    letterSpacing: "0px",
  },
  largeTextList: {
    fontSize: "17px",
    fontWeight: "400",
  },
  carditem: {
    flex: "1 1 0",
    width: 0,
  },
});
