import { gql } from "@apollo/client";
export const DEAL_LIST = gql`
  query BuildingDeals($first: Int, $after: Int, $search: String) {
    buildingDeals(first: $first, after: $after, search: $search) {
      totalCount
      edges {
        node {
          userId
          updated_at
          type
          id
          buildingId
          isApproved
          tenant
          address
          node
          landlord
          size
          terms
          netRent
          tmi
          grossRent
          ti
          fixturn
          reFees
          ner
          dealDone
          startDate
          comments
          source
          dateAdded
          listingCommissions
          sellingCommissions
          discountRate
          llw
          lto
          grossRentMonthly
          netReentMonthly
          floor
          avgNetRentOverTerm
          totalAdditionalRent
          cashAllowance
          modelSuiteCost
          netGrossFreeRent
          sellingFeeTotal
          listingFeeTotal
          suite
          realGrossRate
          created_at
        }
        cursor
      }
    }
  }
`;
