import React from "react";
import { makeStyles } from "@mui/styles";
import { SpaceCompsTable } from "../layout/SpaceCompsTable";
import { MRT_ColumnDef } from "material-react-table";
import { IconButton, Tooltip } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { GET_DEAL_DETAIL } from "../../graphql/queries/getDealById";
import { useData } from "../../contexts/dataContext/dataContext";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

interface ProfileTableProps {
  column: MRT_ColumnDef<any>[];
  data: { [key: string]: string }[] | string[];
}
const UnlockedDealComps = ({ column, data }: ProfileTableProps) => {
  const classes = useStyles();
  const { setDetails } = useData();
  const navigate = useNavigate();
  const [getDealData] = useLazyQuery<any>(GET_DEAL_DETAIL, {
    fetchPolicy: "cache-and-network",
  });
  const handleView = async (id: string) => {
    const { data: response } = await getDealData({
      variables: { getDealByIdId: id },
    });
    if (response) {
      setDetails({ data: response, name: "deals" });
      navigate(`/building-search/allcomps-list/property-detail?id=${id}`);
    }
  };

  return (
    <div className={classes.unlock_deal_comps}>
      <SpaceCompsTable
        enableColumnActions={false}
        enableSorting={false}
        columns={column}
        data={data || []}
        autoResetAll
        enableGrouping
        enableDensityToggle={false}
        enableGlobalFilter={false}
        enableFullScreenToggle={false}
        enableColumnFilters={false}
        enableHiding={false}
        enablePinning={false}
        enableTopToolbar={false}
        enableColumnDragging={false}
        enableRowActions
        muiTableHeadRowProps={{
          sx: {
            "& th:first-child": {
              backgroundColor: "#FD6262 !important",
              color: "#fff",
            },
            "& th:first-child>div": {
              justifyContent: "center",
            },
          },
        }}
        muiTableBodyProps={{
          sx: {
            "& tr td": {
              background: "#1B1C1E !important",
              color: "#FFFFFF",
              borderBottom: "1px solid #3F4349",
            },

            "& tr:nth-of-type(even)": {
              backgroundColor: "#1B1C1E !important",
              color: "#FFFFFF",
            },
          },
        }}
        renderRowActions={({ row }: any) => {
          const { id } = row;
          const { id: getId } = data[id] as any;
          return (
            <div>
              <Tooltip arrow placement="right" title="View">
                <IconButton
                  onClick={() => {
                    handleView(getId);
                  }}
                >
                  <RemoveRedEyeIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        }}
      />
    </div>
  );
};

export default UnlockedDealComps;

const useStyles: any = makeStyles({
  unlock_deal_comps: {
    margin: "0",
  },
  loadingbtn: {
    minWidth: "150px",
    marginBottom: "210px !important",
  },
  export: {
    display: "flex",
    justifyContent: "end",
  },
});
