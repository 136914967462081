import React from "react";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import withoutHeader from "../../utils/hoc/withoutHeader";
interface MarketData {
  id: number;
  title: string;
}

const MarketDetail = () => {
  const classes = useStyles();
  return (
    <div className={classes.marketdetail}>
      <Container>
        <p className={classes.heading}>Commercial Real Estate Data Explained</p>
        <div className={classes.detail}>
          {datildata.map((item) => {
            return (
              <div key={item.id} className={classes.card}>
                <h3 className={classes.title}>{item.title}</h3>
                <p className={classes.description}>
                  Lorem ipsum dolor sit amet consectetur. Lobortis eget egestas montes morbi volutpat maecenas.
                  Consequat amet pellentesque ac tempus morbi vestibulum consectetur.
                </p>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default withoutHeader(MarketDetail);

const useStyles: any = makeStyles({
  marketdetail: {
    margin: "150px 0",
    background: "#FFFFFF",
  },
  heading: {
    fontFamily: "Inter",
    fontSize: "32px",
    fontWeight: 600,
    lineHeight: "54px",
    letterSpacing: "0.01em",
    textAlign: "center",
    color: "#10257F",
  },
  detail: {},
  card: {
    padding: "20px",
    marginBottom: "20px",
    boxShadow: "0px 15px 20px 10px #0000000D",
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0em",
    color: "#10257F",
  },
  description: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "24px",
    color: "#7A7A7A",
    margin: 0,
  },
});

const datildata: MarketData[] = [
  {
    id: 1,
    title: "Vacancy Rate",
  },
  {
    id: 2,
    title: "Real Rate (NER)",
  },
  {
    id: 3,
    title: "Net Effective Rate (NER)",
  },
  {
    id: 4,
    title: "Gross Effective Rate (GER)",
  },
  {
    id: 5,
    title: "Market Node",
  },
  {
    id: 6,
    title: "Shadow Vacancy",
  },
  {
    id: 7,
    title: "Selling Commission",
  },
  {
    id: 8,
    title: "Listing Commission",
  },
  {
    id: 9,
    title: "Renewal Market",
  },
  {
    id: 10,
    title: "Relocation Market",
  },
  {
    id: 11,
    title: "Model Suite Market",
  },
];
