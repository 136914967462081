import React, { useEffect, useMemo, useState } from "react";
import { Button } from "reactstrap";
import InnerHeader from "../../../components/Header/InnerHeader";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import SubscribedPlans from "../../../components/profile/SubscribedPlans";
import { useQuery } from "@apollo/client";
import { GET_UNLOCK_DEAL_BY_USER, GET_FAVOURITE_SPACES_BY_USER } from "../../../graphql/queries/getUnlockDealByUser";
import { getFilteredTableData } from "../../../utils/commonFunctions";
import SavedSearch from "../../../components/profile/SavedSearch";
import UnlockedDealComps from "../../../components/profile/UnlockedDealComps";
import { buildingSpaces, buildingComps, accountTable } from "../../../utils/constants/tableColumns";
import { useAuth } from "../../../contexts/auth/provideAuth";
import YourDeals from "../../../components/deals/YourDeals";
const UserProfile = () => {
  const classes = useStyles();
  const [tab, setTab] = useState("Sharing Comps");
  const {
    data,
    refetch: refetchData,
    loading,
  } = useQuery(GET_UNLOCK_DEAL_BY_USER, { fetchPolicy: "cache-and-network" });
  const {
    data: savedData,
    refetch,
    loading: isFavouriteLoading,
  } = useQuery(GET_FAVOURITE_SPACES_BY_USER, { fetchPolicy: "cache-and-network" });
  const [table, setTable] = useState(data?.getUnlockDealByUser?.edges || []);
  const { handleState } = useAuth();
  const handleTabChange = (value: string) => {
    setTab(value);
  };

  const getFilterSection = useMemo(() => {
    switch (tab) {
      case "Your Account":
        return <SubscribedPlans column={accountTable} />;
      case "Deal Comps Unlocked":
        return <UnlockedDealComps column={buildingComps} data={table} />;
      case "Saved Searches":
        return <SavedSearch column={buildingSpaces} data={table} refetch={refetch} />;
      case "Sharing Comps":
        return <YourDeals refetch={refetchData} />;
      default:
        return null;
    }
  }, [table, tab, refetch]);

  useEffect(() => {
    const filterData = getFilteredTableData(
      tab === "Deal Comps Unlocked" ? data?.getUnlockDealByUser?.edges : savedData?.getFavouriteSpacesByUser?.edges
    );
    setTable(filterData);
  }, [tab, data, savedData]);

  useEffect(() => {
    const isLoading = loading || isFavouriteLoading;
    handleState(!isLoading);
  }, [loading, isFavouriteLoading]);

  return (
    <div>
      <InnerHeader props="Profile" />
      <Container maxWidth="xl">
        <h3 className={classes.profileheading}>Profile Dashboard</h3>
        <div className={classes.tabs}>
          {profileTabs.map((item, index) => {
            return (
              <Button
                className={tab === item ? " btn_profilel btn-profileTab" : " btn_profilel"}
                onClick={() => {
                  handleTabChange(item);
                }}
                key={index}
              >
                {item}
              </Button>
            );
          })}
        </div>
        {getFilterSection}
      </Container>
    </div>
  );
};

export default UserProfile;

const profileTabs = ["Sharing Comps", "Your Account", "Deal Comps Unlocked", "Saved Searches"];
const useStyles: any = makeStyles({
  profileheading: {
    fontFamily: "Inter",
    fontSize: "32px",
    fontWeight: "600",
    padding: "20px",
    lineHeight: "54px",
    letterSpacing: " 0.01em",
    textAlign: "center",
    color: "#FFFF",
    display: "none",
  },
  tabs: {
    display: "flex",
    flexWrap: "wrap",
    // justifyContent: "space-between",
    marginBottom: "70px",
    alignItems: "center",
    "& button": {
      minWidth: "220px",
    },
  },
});
