import React from "react";
import MessageCard from "../layout/MessageCard/MessageCard";

const ErrorMessage = () => {
  return (
    <>
      <MessageCard title="Error" message="Something went wrong"></MessageCard>
    </>
  );
};

export default ErrorMessage;
