import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import BoxImg from "../../../assets/images/dark/box-bg.png";
import { makeStyles } from "@mui/styles";
import { creditData } from "../../../utils/constants/workData";

export default function CreditTextComponent() {
  const classes = useStyles();

  return (
    <Box sx={{ border: "1px solid #3F4349" }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box className={classes.imageBox}>
            <Typography gutterBottom variant="subtitle1" component="h4" className={classes.titleParent}>
              SpaceComps Credit System
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs className={classes.gridTextBox}>
              {creditData.map((item, index) => (
                <Typography key={index} gutterBottom variant="subtitle1" className={classes.title} component="div">
                  {item}
                </Typography>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles({
  titleParent: {
    fontFamily: "Poppins !important",
    fontSize: "30px !important",
    fontWeight: "600 !important",
    lineHeight: "42px !important",
    textAlign: "center !important" as any,
    color: "#FFFFFF",
  },
  imageBox: {
    backgroundImage: `url(${BoxImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: "468px",
    height: "100% !important",
    margin: "auto !important",
    maxWidth: "100% !important",
    backgroundPosition: "center",
    backgroundAttachment: "scroll",
    borderRight: "1px solid #3F4349",
    justifyContent: "center !important",
    alignItems: "center !important",
    display: "flex !important",
  },
  gridTextBox: {
    justifyContent: "center !important",
    alignItems: "flex-start !important",
    display: "flex !important",
    flexDirection: "column !important" as any,
    paddingLeft: "50px !important",
  },
  title: {
    color: "#8A8A8A",
    textAlign: "center",
    fontSize: "15px",
    fontFamily: "Poppins !important",
    fontWeight: "400",
  },
});
