import React from "react";
import InnerHeader from "../../components/Header/InnerHeader";
import GetDesribe from "../../components/getdescribe/GetDesribe";
const GetStarted = () => {
  return (
    <>
      <InnerHeader props={"Get Started"} buttonAction={"Login"} />
      <GetDesribe />
    </>
  );
};

export default GetStarted;
