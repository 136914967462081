import React from "react";
import { SpaceCompsTable } from "../layout/SpaceCompsTable";
import { makeStyles } from "@mui/styles";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UPDATE_SPACES_FAVOURITE } from "../../graphql/queries/building";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import GradeIcon from "@mui/icons-material/Grade";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { MRT_ColumnDef } from "material-react-table";
import { useData } from "../../contexts/dataContext/dataContext";
import { GET_PROPERTY_DETAIL } from "../../graphql/queries/propetyDetail";

interface ProfileTableProps {
  column: MRT_ColumnDef<any>[];
  data: { [key: string]: string }[] | [];
  refetch(): void;
}
const SavedSearch = ({ column, data, refetch }: ProfileTableProps) => {
  const [updateSpacesFavourite] = useLazyQuery<any>(UPDATE_SPACES_FAVOURITE, {
    fetchPolicy: "network-only",
  });
  const [getSpaceData] = useLazyQuery<any>(GET_PROPERTY_DETAIL, {
    fetchPolicy: "cache-and-network",
  });
  const { setDetails } = useData();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleView = async (id: string) => {
    const { data: response } = await getSpaceData({
      variables: { getBuildingSpaceByIdId: id },
    });
    if (response) {
      setDetails({ data: response, name: "space" });
      navigate(`/building-search/allcomps-list/property-detail?id=${id}&tabname=${"space"}`);
    }
  };

  const updateFavourite = async (id: any) => {
    try {
      const { error } = await updateSpacesFavourite({
        variables: { buildingSpacesId: id },
      });
      if (error) {
        refetch();
        toast.error("Favourite Removed Successfully");
        return;
      }
      refetch();
      toast.success("Favourite Added Successfully");
    } catch (error: any) {
      toast.error(error.message || "Something went wrong");
    }
  };
  return (
    <div className={classes.savedseacrh}>
      <SpaceCompsTable
        enableColumnActions={false}
        enableSorting={false}
        columns={column}
        data={data || []}
        autoResetAll
        enableGrouping
        enableDensityToggle={false}
        enableGlobalFilter={false}
        enableFullScreenToggle={false}
        enableColumnFilters={false}
        enableHiding={false}
        enablePinning={false}
        enableTopToolbar={false}
        enableColumnDragging={false}
        enableRowActions
        muiTableHeadRowProps={{
          sx: {
            "& th:first-child": {
              backgroundColor: "#FD6262 !important",
              color: "#fff",
            },
            "& th:first-child>div": {
              justifyContent: "center",
            },
          },
        }}
        muiTableBodyProps={{
          sx: {
            "& tr td": {
              background: "#1B1C1E !important",
              color: "#FFFFFF",
              borderBottom: "1px solid #3F4349",
            },

            "& tr:nth-of-type(even)": {
              backgroundColor: "#1B1C1E !important",
              color: "#FFFFFF",
            },
          },
        }}
        renderRowActions={({ row }: any) => {
          return (
            <div className={classes.rowAction}>
              <Tooltip arrow placement="right" title="Favourite">
                <IconButton
                  onClick={() => {
                    updateFavourite(data[row.id]?.id);
                  }}
                >
                  <GradeIcon
                    sx={{
                      color: "#10257f",
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="View">
                <IconButton
                  onClick={() => {
                    handleView(data[row?.id]?.id);
                  }}
                >
                  <RemoveRedEyeIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        }}
      />
    </div>
  );
};

export default SavedSearch;

const useStyles: any = makeStyles({
  savedseacrh: {
    margin: "50px 0",
  },
  rowAction: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
});
