import { gql } from "@apollo/client";
export const GET_BUILDING_SEARCH_LIST = gql`
  query SearchBuilding($search: String, $filterOptions: JSON) {
    searchBuilding(search: $search, filterOptions: $filterOptions) {
      totalCount
      edges {
        node {
          id
          city
          market
          zoning
          class
          address
          floors
          occupant
          baseYear
          lotSize
          ownerPropertyManager
          officeSize
          buildingPhysicalStatus
          buildingSize
          parkingRatio
          buildingName
          yearBuilt
          typicalFloorPlate
          realityTaxes
          operatingCosts
          electricity
          totalAdditionalRent
          totalavailable
          totalDirectAvailable
          totalSubletAvailable
          buildingSpaces
          latlng
          created_at
          updated_at
        }
        cursor
      }
    }
  }
`;

export const GET_BUILDING_SPACES_BY_ID = gql`
  query GetBuildingSpacesByTargetedId($targetId: String) {
    getBuildingSpacesByTargetedId(targetId: $targetId) {
      totalCount
      edges {
        node {
          id
          targetId
          city
          market
          zoning
          physicalStatus
          address
          floors
          yearBuilt
          parkingRatio
          buildingSize
          suiteUnit
          suiteSize
          minDivisible
          maxContiguous
          suiteConditionNotes
          leaseType
          expiryDate
          availableOn
          askingNetRent
          grossRent
          listingComments
          listingBroker
          listingBrokerEMAIL
          listingBrokerPHONE
          Vacancy
          daysOnMarket
          listedOnDate
          marketingComments
          ownerPropertyManager
          totalAdditionalRent
          typicalFloorPlate
          isFavourite
          created_at
          updated_at
        }
        cursor
        Building {
          id
          city
          market
          zoning
          class
          address
          floors
          occupant
          baseYear
          lotSize
          ownerPropertyManager
          officeSize
          buildingPhysicalStatus
          buildingSize
          parkingRatio
          buildingName
          yearBuilt
          typicalFloorPlate
          realityTaxes
          operatingCosts
          electricity
          totalAdditionalRent
          totalavailable
          totalDirectAvailable
          totalSubletAvailable
          buildingSpaces
          latlng
          created_at
          updated_at
        }
      }
    }
  }
`;

export const UPDATE_BUILDING_SPACES_BY_ID = gql`
  query Query($buildingSpacesId: String) {
    buildingUnlockByCredit(buildingSpacesId: $buildingSpacesId)
  }
`;

export const UPDATE_DEAL_BY_ID = gql`
  query Query($dealId: String) {
    dealUnlockByCredit(dealId: $dealId)
  }
`;

export const UPDATE_SPACES_FAVOURITE = gql`
  query Query($buildingSpacesId: String) {
    updateSpacesFavourite(buildingSpacesId: $buildingSpacesId)
  }
`;

export const GET_BUILDING_DEALS_BYBUILDING_ID = gql`
  query GetBuildingDealsByBuildingId($buildingId: String) {
    getBuildingDealsByBuildingId(buildingId: $buildingId) {
      totalCount
      edges {
        node {
          id
          buildingId
          userId
          isApproved
          tenant
          address
          node
          landlord
          size
          terms
          netRent
          tmi
          grossRent
          ti
          fixturn
          reFees
          ner
          type
          dealDone
          startDate
          comments
          source
          dateAdded
          listingCommissions
          sellingCommissions
          discountRate
          llw
          lto
          grossRentMonthly
          netReentMonthly
          isUnlock
          created_at
          isCreatedDeal
          updated_at
        }
        cursor
        Building {
          id
          city
          market
          zoning
          class
          address
          floors
          occupant
          baseYear
          lotSize
          ownerPropertyManager
          officeSize
          buildingPhysicalStatus
          buildingSize
          parkingRatio
          buildingName
          yearBuilt
          typicalFloorPlate
          realityTaxes
          operatingCosts
          electricity
          totalAdditionalRent
          totalavailable
          totalDirectAvailable
          totalSubletAvailable
          buildingSpaces
          latlng
          created_at
          updated_at
        }
      }
    }
  }
`;
