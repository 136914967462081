import React from "react";
import AllCompanies from "../../components/SearchFilter/AllCompanies";
import { makeStyles } from "@mui/styles";
import darkImage from "../../assets/images/dark/dark-bg.jpg";

const HomePage = () => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <AllCompanies />
    </div>
  );
};

export default HomePage;
const useStyles: any = makeStyles({
  main: {
    backgroundImage: `url(${darkImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    minHeight: "100vh",
    backgroundPosition: "center",
    backgroundAttachment: "scroll",
  },
});
