import React from "react";
import { Row, Col } from "reactstrap";
import Charts from "./Charts";
import { Blog } from "../../components/layout/Blog";
import { BlogData } from "../../utils/constants/blogData";

const AdminDashboard = () => {
  return (
    <div>
      {/* **Top Cards** */}
      {/***Sales & Feed***/}
      <Row>
        <Col sm="6" lg="6" xl="7" xxl="8">
          <Charts />
        </Col>
      </Row>
      {/***Table ***/}
      <Row>
        <Col lg="12">{/* <ProjectTables /> */}</Col>
      </Row>
      {/***Blog Cards***/}{" "}
      <Row>
        {BlogData.map((blg, index) => (
          <Col sm="6" lg="6" xl="3" key={index}>
            <Blog
              image={blg.image}
              title={blg.title}
              subtitle={blg.subtitle}
              text={blg.description}
              color={blg.btnbg}
            />
          </Col>
        ))}{" "}
      </Row>
    </div>
  );
};
export default AdminDashboard;
