import React, { useMemo, useEffect, useState } from "react";
import SpaceCompsTable from "../../components/layout/SpaceCompsTable/SpaceCompsTable";
import { spacesColumn } from "../../utils/constants/tableColumns";
import { useLazyQuery, useMutation } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { IconButton, Tooltip, Typography } from "@mui/material";
import ImportExcel from "../../components/layout/Excel/ImportExcel";
import { Result } from "react-spreadsheet-import/types/types";
import { saveExcelData } from "../../utils/importExcel";
import UploadIcon from "@mui/icons-material/Backup";
import { spacesField } from "../../utils/constants/TableField";
import FileDownloadIcon from "@mui/icons-material/CloudDownload";
import { ExportToCsv } from "export-to-csv";
import { EDIT_SPACESDATA, EXPORT_SPACES_BUILDING, SPACES_BUILDING } from "../../graphql/mutations/spaces";
import { SPACES_DATA } from "../../graphql/queries/spaces";
import { client } from "../../graphql/apollo-client";
import { useAuth } from "../../contexts/auth/provideAuth";
import { MaterialReactTableProps } from "material-react-table";
import { toast } from "react-toastify";
import { getFilteredTableData, removeNonNumericValue, removeUnusedKeys } from "../../utils/commonFunctions";
function Spaces() {
  const [isOpen, setIsOpen] = useState(false);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 5 });
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const { handleState } = useAuth();
  const classes = useStyles();
  const [spacesBuilding, { data, refetch, loading }] = useLazyQuery<any>(SPACES_DATA, {
    fetchPolicy: "network-only",
  });
  const [handleSpaceExport, { loading: exportLoading }] = useLazyQuery<any>(EXPORT_SPACES_BUILDING, {
    fetchPolicy: "cache-and-network",
  });
  const [editSpacesData, { loading: editLoading }] = useMutation(EDIT_SPACESDATA, {
    fetchPolicy: "network-only",
    onCompleted: refetch,
  });
  useEffect(() => {
    spacesBuilding({
      variables: {
        first: pagination.pageSize,
        after: pagination.pageIndex * pagination.pageSize,
        search: globalFilter ?? "",
      },
    });
  }, [spacesBuilding, pagination.pageSize, pagination.pageIndex, globalFilter]);

  const spacesData = useMemo(() => {
    const spaces = data?.getBuildingSpaces?.edges?.map((d: any, i: any) => {
      setRowCount(data?.getBuildingSpaces?.totalCount);
      const node = d?.node;
      const indexId = i + 1;
      removeNonNumericValue(node);
      return { ...node, indexId };
    });
    return spaces ?? [];
  }, [data]);
  const handleSpaceEdit: MaterialReactTableProps<any>["onEditingRowSave"] = async ({ exitEditingMode, values }) => {
    try {
      const editspaceResponse = await editSpacesData({
        variables: {
          input: {
            id: values?.id,
            askingNetRent: Number(values?.askingNetRent),
            address: values?.address,
            availableOn: values?.availableOn,
            buildingSize: Number(values?.buildingSize),
            city: values?.city,
            daysOnMarket: Number(values?.daysOnMarket),
            expiryDate: values?.expiryDate,
            floors: Number(values?.floors),
            grossRent: Number(values?.grossRent),
            leaseType: values?.leaseType,
            listedOnDate: values?.listedOnDate,
            listingBroker: values?.listingBroker,
            listingBrokerEMAIL: values?.listingBrokerEMAIL,
            listingBrokerPHONE: Number(values?.listingBrokerPHONE),
            listingComments: values?.listingComments,
            market: values?.market,
            maxContiguous: Number(values?.maxContiguous),
            minDivisible: Number(values?.minDivisible),
            ownerPropertyManager: values?.ownerPropertyManager,
            parkingRatio: Number(values?.parkingRatio),
            physicalStatus: values?.physicalStatus,
            suiteConditionNotes: values?.suiteConditionNotes,
            suiteSize: Number(values?.suiteSize),
            suiteUnit: Number(values?.suiteUnit),
            totalAdditionalRent: Number(values?.totalAdditionalRent),
            typicalFloorPlate: Number(values?.typicalFloorPlate),
            yearBuilt: Number(values?.yearBuilt),
            zoning: values?.zoning,
            Vacancy: values?.Vacancy,
            marketingComments: values?.marketingComments,
          },
        },
      });
      if (editspaceResponse?.data?.editSpacesBuilding) {
        toast.success("Spaces data edited successfully");
        exitEditingMode();
      }
    } catch (e: any) {
      const errorMessage = e?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    const Loading = loading || editLoading || exportLoading;
    handleState(!Loading);
  }, [loading, editLoading, exportLoading, handleState]);

  const onImportExcelOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onSubmit = async (submitData: Result<string>) => {
    // instead of gql``, pass mutation which get called to save data
    try {
      const spacedataResponse = await saveExcelData(submitData.validData, client, SPACES_BUILDING);
      if (spacedataResponse.data?.createSpaces) {
        toast.success("Data uploaded sucessfully");
        await refetch();
      } else {
        toast.error("There was an error in syncing the data");
      }
    } catch (e: any) {
      const errorMessage = e?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };
  const handleSpaceExportData = async () => {
    const { data: exporSpacetData } = await handleSpaceExport({
      variables: {
        first: rowCount,
        after: 0,
        search: "",
      },
    });
    const getFilteredData = getFilteredTableData(exporSpacetData?.exportBuildingSpaces?.edges);
    const exportSheet = removeUnusedKeys(getFilteredData);
    csvExporter.generateCsv(exportSheet);
  };
  return (
    <div className={`${classes.tableDesined} form-group form-check`}>
      <SpaceCompsTable
        columns={spacesColumn}
        data={spacesData}
        getRowId={(row) => row.id}
        editingMode="row"
        enableEditing
        onEditingRowSave={handleSpaceEdit}
        enableColumnFilterModes
        enableColumnOrdering
        enableGrouping
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enablePinning
        enableTopToolbar={true}
        onPaginationChange={setPagination}
        onGlobalFilterChange={setGlobalFilter}
        manualPagination={true}
        rowCount={rowCount}
        enableRowNumbers
        state={{ pagination, globalFilter, showProgressBars: !spacesData }}
        initialState={{ columnVisibility: { targetId: false } }}
        enableToolbarInternalActions={true}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "0",
            border: "1px dashed #e0e0e0",
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            backgroundColor: "#808080 !important",
            color: "#fff",
          },
        }}
        muiTableBodyProps={{
          sx: {
            "& tr td": {
              background: "#1B1C1E !important",
              color: "#FFFFFF",
              borderBottom: "1px solid #3F4349",
            },

            "& tr:nth-of-type(even)": {
              backgroundColor: "#1B1C1E !important",
              color: "#FFFFFF",
            },
          },
        }}
        renderTopToolbarCustomActions={() => (
          <div className={classes.topToolbarBoxStyling}>
            <div className={classes.spaceStyleCss}>
              <Typography component="span" variant="h4" sx={{ color: "#FFFFFF" }}>
                SPACES
              </Typography>
            </div>
            <div className={classes.tooltipStyle}>
              <Tooltip title="Upload Excel">
                <IconButton onClick={onImportExcelOpen}>
                  <UploadIcon color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Export All Data">
                <IconButton onClick={handleSpaceExportData}>
                  <FileDownloadIcon color="primary" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
      />
      <ImportExcel isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} fields={spacesField} />
    </div>
  );
}
const filterData = ["uuid", "S.NO."];
const csvOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true,
  headers: spacesColumn
    .map((c) => c.header)
    ?.filter((item) => {
      return !filterData.includes(item);
    }),
};

const csvExporter = new ExportToCsv(csvOptions);

export default Spaces;
const useStyles: any = makeStyles({
  tableDesined: {
    textAlign: "center",
    margin: "auto",
    marginTop: "40px",
    width: "78vw",
    display: "block",
    overflowX: "auto",
  },
  spaceStyleCss: {
    width: "100%",
    textAlign: "left",
  },
  tooltipStyle: {
    textAlign: "right",
    display: "flex",
  },
  exportCssStyle: {
    width: "25%",
  },
  topToolbarBoxStyling: {
    display: "flex",
    flex: 2,
    justifyContent: "space-between",
  },
});
