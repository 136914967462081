import { gql } from "@apollo/client";
export const SPACES_BUILDING = gql`
  mutation CreateSpaces($input: [createBuildingSpacesInput]) {
    createSpaces(input: $input) {
      created_at
      updated_at
    }
  }
`;

export const EDIT_SPACESDATA = gql`
  mutation EditSpacesBuilding($input: spaceBuildingInput) {
    editSpacesBuilding(input: $input)
  }
`;
export const EXPORT_SPACES_BUILDING = gql`
  query ExportBuildingSpaces($first: Int, $after: Int, $search: String) {
    exportBuildingSpaces(first: $first, after: $after, search: $search) {
      totalCount
      edges {
        node {
          id
          targetId
          city
          market
          zoning
          physicalStatus
          address
          floors
          yearBuilt
          parkingRatio
          buildingSize
          suiteUnit
          suiteSize
          minDivisible
          maxContiguous
          suiteConditionNotes
          leaseType
          expiryDate
          availableOn
          askingNetRent
          grossRent
          listingComments
          listingBroker
          listingBrokerEMAIL
          listingBrokerPHONE
          Vacancy
          daysOnMarket
          listedOnDate
          marketingComments
          ownerPropertyManager
          totalAdditionalRent
          typicalFloorPlate
          isFavourite
          created_at
          updated_at
        }
        cursor
      }
    }
  }
`;
