import company1 from "../../assets/images/logos/company1.png";
export const CompanyDetail = [
  {
    heading: "why I started this company",
    description: (
      <p
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "32px",
          textAlign: "justify",
          color: "#50576D",
        }}
      >
        My name is Dave B and I am the founder of{" "}
        <a target="_blank" href="https://spacecomps.com/" rel="noreferrer">
          {" "}
          SpaceComps.com.
        </a>{" "}
        I started this website mainly out of frustration. I worked for 35 Years for the biggest commercial real estate
        company in the world. Every single transaction we did we always ran into the most important question - “Can you
        provide two or three deal comps so we know we are getting a good or at least market deal?” Every company,
        broker, landlord and person that is responsible for negotiating their commercial real estate wants to know that
        they got a good deal. The problem is - unlike residential real estate there is no registry system for commercial
        leasing deals. How it works now, we essentially all share date through relationships and contacts. Everyone was
        willing to share but there was no platform to anonymously share the data. Spacecomps is that platform.
      </p>
    ),
    image: company1,
  },
];
