import React from "react";
import { makeStyles } from "@mui/styles";
import { IActivePlanProp } from "../../../pages/client-pages/CreditScore";

export interface IItems {
  planType: string;
  price: string;
  value: number;
  description: string;
  creditScore: string;
}

export interface ISelectCardProps {
  key: number;
  item?: IItems;
  active?: boolean;
  ActivePlans(prop: IActivePlanProp): void;
}

const SelectCard = ({ key, item, active, ActivePlans }: ISelectCardProps) => {
  const styles = useStyles();
  return (
    <div key={key} className={`${styles.card} ${active ? styles.activePlan : ""}`}>
      <label className={styles.label}>
        <div className={styles.cardRow}>
          <div>
            <input
              type="radio"
              className={styles.selectInput}
              name="select"
              value={item?.planType}
              checked={active}
              onChange={(e) => {
                ActivePlans({ planType: e.target.value, price: item?.value });
              }}
            />
            <span className={`${styles.planType} ${styles.common}`}>{item?.planType}</span>
          </div>
          <p className={`${styles.price} ${styles.common}`}>{item?.price}</p>
        </div>
        <p className={styles.description}>{item?.description}</p>
        <p className={`${styles.bottomHeading} ${styles.common}`}>{item?.creditScore}</p>
      </label>
    </div>
  );
};

export default SelectCard;
const useStyles: any = makeStyles({
  selectInput: {
    accentColor: "#FD6262 !important",
  },
  label: {
    cursor: "pointer",
  },
  card: {
    width: "515px",
    cursor: "pointer",
    boxSizing: "border-box",
    background: "#1B1C1E",
    borderRadius: "6px",
    padding: "15px",
    color: "#50576D",
    border: "1px solid #3F4349",
    boxShadow: "0px 15px 20px 10px rgba(0, 0, 0, 0.05)",
    "@media (max-width: 780px)": {
      width: "auto",
    },
  },
  cardRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  common: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "30px",
  },
  planType: {
    fontSize: "20px",
    paddingLeft: "10px",
    color: "#889CE7 !important",
  },
  price: {
    fontSize: "20px",
    color: "#889CE7 !important",
  },
  bottomHeading: {
    fontSize: "16px",
    color: "#FD6262",
  },
  description: {
    color: "#50576D",
    lineHeight: "30px",
  },
  activePlan: {
    border: "1px solid #3F4349",
    color: " #10257F",
  },
});
