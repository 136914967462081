import React from "react";
import { ForgotPassword } from "../../../components/layout/Auth";
import { FORGOTPASSWORD } from "../../../graphql/queries/forgotPassword";
import { useLazyQuery } from "@apollo/client";
const UserForgotPassword = () => {
  const [handleForgot, { data, error, loading }] = useLazyQuery(FORGOTPASSWORD);
  const onSubmit = (values: { [key: string]: string }): void => {
    handleForgot({
      variables: {
        loginName: values.email,
      },
    });
  };

  return (
    <>
      <ForgotPassword
        onSubmitCallback={onSubmit}
        navigateBaseUrl="/"
        error={error}
        loading={loading}
        data={data?.forgotPassword?.message}
      />
    </>
  );
};

export default UserForgotPassword;
