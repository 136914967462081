import { gql } from "@apollo/client";
export const GET_UNLOCK_DEAL_BY_USER = gql`
  query GetUnlockDealByUser {
    getUnlockDealByUser {
      edges {
        node {
          id
          buildingId
          userId
          isApproved
          tenant
          address
          node
          landlord
          size
          terms
          netRent
          tmi
          grossRent
          ti
          fixturn
          reFees
          ner
          type
          dealDone
          startDate
          comments
          source
          dateAdded
          listingCommissions
          sellingCommissions
          discountRate
          llw
          lto
          grossRentMonthly
          netReentMonthly
          isUnlock
          created_at
          updated_at
          isCreatedDeal
        }
        cursor
      }
    }
  }
`;
export const GET_FAVOURITE_SPACES_BY_USER = gql`
  query GetFavouriteSpacesByUser {
    getFavouriteSpacesByUser {
      edges {
        node {
          id
          targetId
          city
          market
          zoning
          physicalStatus
          address
          floors
          yearBuilt
          parkingRatio
          buildingSize
          suiteUnit
          suiteSize
          minDivisible
          maxContiguous
          suiteConditionNotes
          leaseType
          expiryDate
          availableOn
          askingNetRent
          grossRent
          listingComments
          listingBroker
          listingBrokerEMAIL
          listingBrokerPHONE
          Vacancy
          daysOnMarket
          listedOnDate
          marketingComments
          ownerPropertyManager
          totalAdditionalRent
          typicalFloorPlate
          isFavourite
          created_at
          updated_at
        }
        cursor
      }
      totalCount
    }
  }
`;
