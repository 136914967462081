import React, { useMemo } from "react";
import { Navbar } from "reactstrap";
import user1 from "../../../../assets/images/users/user4.jpg";
import { adminDropdownOptions } from "../../../../utils/constants/Navigations";
import { DropDown } from "../../DropDown";
import spacecompslogo from "../../../../assets/images/dark/logo.png";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { getLocalData } from "../../../../utils/commonFunctions";
const AdminNavbar = () => {
  const classes = useStyle();
  const { loginName, token, email }: any = getLocalData();
  const navTitle = useMemo(() => {
    return (
      <div className="d-flex align-items-center gap-2 avtar">
        <span style={{ color: "#FFFF" }}>{loginName !== "null" ? loginName : email}</span>
        <img src={user1} alt="profile" className="rounded-circle" width="40" />
      </div>
    );
  }, [loginName, email]);
  return (
    <Navbar color="white" light expand="md" className={`${classes.headNavStyle} fix-header`}>
      <div className={classes.navDivStyle}>
        <div className={classes.divCss}>
          <div className={classes.linkDivStyle}>
            <Link to={"/admin/dashboard"}>
              <div className="logo_body ">
                <div className="logo">
                  <img alt="logo" src={spacecompslogo}></img>
                </div>
                <span className={classes.logoText}>SpaceComps</span>
              </div>
            </Link>
          </div>
        </div>
        <div className={classes.typoDivStyle}>
          {token && <DropDown dropStyle title={navTitle} options={[...adminDropdownOptions]} />}
        </div>
      </div>
    </Navbar>
  );
};
export default AdminNavbar;
const useStyle = makeStyles({
  headNavStyle: {
    height: "110px !important",
    background: "none !important",
    boxShadow: "none !important",
  },
  logoText: {
    color: "#FFFF !important",
  },
  typoStyle: {
    padding: "10px 12px",
    fontSize: "20px",
    fontWeight: 900,
    borderRadius: "25px",
    outline: "none",
    border: "0px",
    height: "50px",
    background: "#10257F",
    color: "#FFF",
    textShadow: "-moz-initial",
    cursor: "text !important",
    "&:hover": {
      background: "#00c194",
      borderColor: "#00c194",
      color: "#fff",
      borderRadius: "25px",
      outline: "none",
      border: "0px",
    },
  },
  navDivStyle: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "space-between",
    position: "relative",
    top: "5px",
  },
  typoDivStyle: {
    display: "flex",
    alignItems: "center",
  },
  spaceStyle: {
    gap: "10px !important",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "700 !important",
    fontSize: "30px !important",
    lineHeight: "34px",
    alignItems: "center",
    textAlign: "center",
    textTransform: "capitalize",

    color: "#10257F",
    "@media (max-width: 992px)": {
      fontSize: "20px !important",
    },
    "@media (max-width: 1024px)": {
      fontSize: "24px !important",
    },
  },
  spantheStyle: {
    fontFamily: "Poppins",
    fontStyle: "italic",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "34px",
    alignItems: "center",
    textAlign: "center",
    textTransform: "capitalize",
    color: "#FFFF !important",
    "@media (max-width: 992px)": {
      fontSize: "18px",
    },
    "@media (max-width: 1024px)": {
      fontSize: "20px !important",
    },
  },
  dropStyle: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "space-between !important",
    width: "80px !important",
    height: "80px !important",
  },

  divCss: {
    display: "flex",
  },
  linkDivStyle: {
    "& span": {
      color: "#10257F",
    },
    display: "flex",
    alignItems: "flex-start",
  },
});
