import { gql } from "@apollo/client";
export const SPACES_DATA = gql`
  query GetBuildingSpaces($first: Int, $after: Int, $search: String) {
    getBuildingSpaces(first: $first, after: $after, search: $search) {
      totalCount
      edges {
        node {
          address
          askingNetRent
          availableOn
          buildingSize
          city
          daysOnMarket
          expiryDate
          floors
          grossRent
          leaseType
          listedOnDate
          listingBroker
          listingBrokerEMAIL
          listingBrokerPHONE
          listingComments
          market
          maxContiguous
          minDivisible
          ownerPropertyManager
          parkingRatio
          physicalStatus
          suiteConditionNotes
          suiteSize
          suiteUnit
          totalAdditionalRent
          typicalFloorPlate
          yearBuilt
          zoning
          Vacancy
          marketingComments
          typicalFloorPlate
          created_at
          updated_at
          id
          targetId
        }
      }
    }
  }
`;
