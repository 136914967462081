import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ProvideAuth } from "../../contexts/auth/provideAuth";
import AdminRoutes from "./admin-routes";
import UserRoutes from "./user-routes";
import { ApolloProvider } from "@apollo/client";
import { client } from "../../graphql/apollo-client";

const Index = () => {
  return (
    <ApolloProvider client={client}>
      <ProvideAuth>
        <Router>
          <Routes>
            <Route path="/*" element={<UserRoutes />} />
            <Route path="/admin/*" element={<AdminRoutes />} />
          </Routes>
        </Router>
      </ProvideAuth>
    </ApolloProvider>
  );
};

export default Index;
