import image_one from "../../assets/images/dark/Image_one.png";
import image_two from "../../assets/images/dark/image_two.png";
import image_three from "../../assets/images/dark/image_three.png";
import image_four from "../../assets/images/dark/image_four.png";
export const UserRole = {
  firstBox: [
    {
      id: 0,
      img: image_one,
      title: "Broker",
      description: "You're a leasing broker seeking or sharing deal comps.",
    },
    {
      id: 1,
      img: image_two,
      title: "Landlord",
      description: "You're an owner or landlord leasing rep seeking or sharing deal comps.",
    },
  ],
  secondBox: [
    {
      id: 2,
      img: image_three,
      title: "Tenant",
      description: "You're a tenant seeking market validation for lease negotiations.",
    },
    {
      id: 3,
      img: image_four,
      title: "Appraiser",
      description: "You're an appraiser seeking the most up to date leasing deals.",
    },
  ],
};
