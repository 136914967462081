import React, { FC, useEffect, useRef } from "react";
import { CardBody, Container } from "reactstrap";
import useForm from "../../Hooks/useForm";
import { defaultAddCreditFields } from "../../utils/constants/defaultAuthFields";
import { makeStyles } from "@mui/styles";
import { InputBox } from "../layout/Form";
import { useLazyQuery } from "@apollo/client";
import { GET_BUILDING_BY_ADDRESS } from "../../graphql/queries/addDeals";
import { useAuth } from "../../contexts/auth/provideAuth";
export interface IAddCreditProps {
  onSubmitCallback(values: { [key: string]: string | number }): void;
  successdata: any;
}

const YourDealsForm: FC<IAddCreditProps> = ({ onSubmitCallback }: IAddCreditProps) => {
  const classes = useStyles();
  const { handleChange, handleAddress, handleDate, values, errors, handleDealSubmit } = useForm(
    onSubmitCallback,
    defaultAddCreditFields
  );
  const { handleState } = useAuth();
  const [fetchAddress, { data, loading }] = useLazyQuery(GET_BUILDING_BY_ADDRESS, {
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    fetchAddress({ variables: { search: "" } });
  }, [fetchAddress]);
  useEffect(() => {
    handleState(!loading);
  }, [loading, handleState]);
  const ref = useRef<null | HTMLDivElement>(null);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    if (name !== "address" && name !== "suite" && name !== "floor") {
      handleChange(e, "adddeals");
    } else {
      handleChange(e);
    }
  };

  return (
    <div>
      <Container fluid>
        <div className={`mx-auto   pb-3 mb-5 pt-4  ${classes.main}`} ref={ref}>
          <CardBody className="p-3 pt-0  w-100 d-flex flex-column">
            <h3 className={classes.headingStyle}>Deals form</h3>
            <form className={classes.mainForm} onSubmit={handleDealSubmit}>
              <div className={classes.creditForm}>
                {" "}
                {defaultAddCreditFields?.map((field, index) => {
                  return (
                    <InputBox
                      key={index}
                      {...field}
                      onChange={(e) => {
                        handleInput(e, field.name);
                      }}
                      handleAddress={handleAddress}
                      handleDate={handleDate}
                      error={errors[field.name]}
                      value={values[field.name]}
                      countries={data?.searchBuildingByAddress?.edges}
                      className={classes.inputCustom}
                    />
                  );
                })}
              </div>
              <div className="text-end">
                <button type="submit" className={`${classes.styleButtonCss} btn btn-primary`}>
                  Submit
                </button>
              </div>
            </form>
          </CardBody>
        </div>
      </Container>
    </div>
  );
};

export default YourDealsForm;

const useStyles: any = makeStyles({
  main: {
    "@media (max-width: 780px)": {
      marginTop: "30px",
      oveflow: "hidden",
      width: "auto",
    },
    height: "auto",
  },
  mainForm: {
    display: "block",
    margin: "auto",
    paddingBottom: "50px",
    "@media (max-width: 780px)": {
      width: "100%",
    },
  },
  styleButtonCss: {
    color: "#FFF !important",
    border: "0px !important",
    height: "54.19px !important",
    fontFamily: "Poppins !important",
    fontStyle: "normal !important",
    width: "100%",
    maxWidth: "300px",
    fontWeight: 500,
    fontSize: "16px",
  },
  inputCustom: {
    border: "1px solid #8A8A8A !important",
    color: "#505050 !important",
  },
  headingStyle: {
    textAlign: "center",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "32px",
    lineHeight: "54px",
    letterSpacing: "0.01em",
    textTransform: "capitalize",
    color: "#FFFFFF",
  },
  title: {
    width: "100%",
    height: "44px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#001D23",
  },
  formControl: {
    boxSizing: "border-box",
    display: "block",
    alignItems: "flexStart",
    padding: "18.5px 0px 15.5px 12px",
    width: "100%",
    height: "44px",
    border: "2px solid #DDDDDD",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#001D23",
  },
  forgotPassword: {
    width: "124px",
    height: "21px",
    left: "804px",
    top: "755px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: " #757575",
  },
  errors: {
    fontSize: "13px",
    color: "red",
    textAlign: "center",
    marginTop: "10px",
    "@media (max-width: 500px)": {
      paddingLeft: "15px",
    },
  },
  keepMe: {
    height: "21px",
    left: "543px",
    top: "755px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
    textAlign: "center",
    color: "#757575",
  },

  checkBoxStyle: {
    marginLeft: "100px",
    width: "18px",
    borderRadius: "0px",
    height: "18px",
    marginTop: "1.5px",
    "&:checked": {
      borderRadius: "0px",
      backgroundColor: "#00C194",
    },
  },
  registerStyle: {
    textDecorationLine: "underline !important",
    textDecoration: "none",
    cursor: "pointer",
    color: "#00C194",
    "&:hover": {
      color: "#10257F",
    },
  },
  creditForm: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "start",
    marginTop: "10px",

    "&>div": {
      boxSizing: "border-box",
      margin: "10px 10px 0 0",
      width: "calc(1/3*100% - (1 - 1/3)*10px)",
    },
    "&>div:nth-child(3n)": {
      marginRight: "0",
    },
    "&:after": {
      content: "",
      flex: "auto",
    },
  },
});
