import { gql } from "@apollo/client";
export const GET_DEAL_DETAIL = gql`
  query GetDealById($getDealByIdId: String, $userId: String) {
    getDealById(id: $getDealByIdId, userId: $userId) {
      id
      buildingId
      userId
      isApproved
      tenant
      address
      node
      landlord
      size
      terms
      netRent
      tmi
      grossRent
      ti
      fixturn
      reFees
      ner
      type
      dealDone
      startDate
      comments
      source
      dateAdded
      listingCommissions
      sellingCommissions
      discountRate
      llw
      lto
      grossRentMonthly
      netReentMonthly
      isUnlock
      floor
      avgNetRentOverTerm
      totalAdditionalRent
      cashAllowance
      modelSuiteCost
      netGrossFreeRent
      sellingFeeTotal
      listingFeeTotal
      realGrossRate
      suite
      created_at
      isCreatedDeal
      updated_at
      Building {
        id
        city
        market
        zoning
        class
        address
        floors
        occupant
        baseYear
        lotSize
        ownerPropertyManager
        officeSize
        buildingPhysicalStatus
        buildingSize
        parkingRatio
        buildingName
        yearBuilt
        typicalFloorPlate
        realityTaxes
        operatingCosts
        electricity
        totalAdditionalRent
        totalavailable
        totalDirectAvailable
        totalSubletAvailable
        buildingSpaces
        latlng
        created_at
        updated_at
      }
    }
  }
`;
