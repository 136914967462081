import React, { useCallback, useEffect, useState, useMemo } from "react";
import SpaceCompsTable from "../../components/layout/SpaceCompsTable/SpaceCompsTable";
import { CREDIT_APPROVAL } from "../../graphql/queries/credit";
import { useLazyQuery, useMutation } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { MRT_RowSelectionState, MaterialReactTableProps } from "material-react-table";
import ImportExcel from "../../components/layout/Excel/ImportExcel";
import UploadIcon from "@mui/icons-material/Backup";
import { saveExcelData } from "../../utils/importExcel";
import { Result } from "react-spreadsheet-import/types/types";
import { client } from "../../graphql/apollo-client";
import { DealField } from "../../utils/constants/TableField";
import { DEAL_IMPORT, EDIT_DEALSDATA } from "../../graphql/mutations/deals";
import { DEAL_LIST } from "../../graphql/queries/deal";
import { useAuth } from "../../contexts/auth/provideAuth";
import DealsAction from "../../components/layout/DealsAction/DealsAction";
import { getDealsCulumn } from "../../utils/constants/tableColumns";
function Deals() {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [isOpen, setIsOpen] = useState(false);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 5 });
  const [globalFilter, setGlobalFilter] = useState("");
  const [dealsModal, setDealsModal] = useState(false);
  const [dealsValue, setDealsValues] = useState(null);
  const [rowCount, setRowCount] = useState(0);
  const classes = useStyles();
  const [dealsVariable, { data, loading, refetch }] = useLazyQuery<any>(DEAL_LIST, {
    fetchPolicy: "cache-and-network",
  });
  const [handleApprovedCredit, { loading: creditApprovalLoading }] = useLazyQuery(CREDIT_APPROVAL, {
    onCompleted: refetch,
    fetchPolicy: "network-only",
  });
  const { handleState } = useAuth();
  const [editDealData, { loading: editLoading }] = useMutation(EDIT_DEALSDATA, {
    fetchPolicy: "network-only",
    onCompleted: refetch,
  });

  useEffect(() => {
    dealsVariable({
      variables: {
        first: pagination.pageSize,
        after: pagination.pageIndex * pagination.pageSize,
        search: globalFilter ?? "",
      },
    });
  }, [dealsVariable, globalFilter, pagination.pageIndex, pagination.pageSize]);
  const dealsData = useMemo(() => {
    return (
      data?.buildingDeals?.edges.map((d: any) => {
        setRowCount(data?.buildingDeals?.totalCount);
        const isApproveds = d?.node?.isApproved ? "Approved" : "Pending";
        return {
          ...d?.node,
          indexId: d?.id,
          isApproved: isApproveds,
        };
      }) ?? []
    );
  }, [data]);

  const handleDealEdit: MaterialReactTableProps<any>["onEditingRowSave"] = async ({ exitEditingMode, values }) => {
    const { discountRate, grossRentMonthly, isApproved, listingCommissions, lto, sellingCommissions, ...rest } = values;
    if (!values?.ner || values?.ner === "") {
      toast.error("NER field is required for Deal's Approval.");
      return;
    }
    try {
      const editResponse = await editDealData({
        variables: {
          input: rest,
        },
      });
      if (editResponse.data) {
        toast.success("Deals data edited successfully");
        exitEditingMode();
      }
    } catch (e: any) {
      const errorMessage = e?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };

  const clearSelection = useCallback(() => setRowSelection({}), []);
  const onImportExcelOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  const onSubmit = async (submitData: Result<string>) => {
    // instead of gql``, pass mutation which get called to save data
    try {
      const exceluploadResponse = await saveExcelData(submitData.validData, client, DEAL_IMPORT);
      if (exceluploadResponse.data) {
        await refetch();
        toast.success("Data uploaded sucessfully");
      } else {
        toast.error("There was an error in syncing the data");
      }
    } catch (e: any) {
      const errorMessage = e?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    const Loading = loading || editLoading || creditApprovalLoading;
    handleState(!Loading);
  }, [loading, editLoading, handleState, creditApprovalLoading]);

  const AcceptDeal = async (row: any) => {
    const { original } = row;
    if (!original.ner) {
      return toast.error("NER field is required for Deal's Approval.");
    }
    try {
      const acceptResponse = await handleApprovedCredit({
        variables: {
          creditId: original.id,
          status: true,
        },
      });
      if (acceptResponse.data) {
        clearSelection();
        toast.success(acceptResponse.data.creditApproval.message);
      }
    } catch (e: any) {
      const errorMessage = e?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };

  const RejectDeal = async (row: any) => {
    const { original } = row;
    try {
      const rejectResponse = await handleApprovedCredit({
        variables: {
          creditId: original?.id,
          status: false,
        },
      });
      if (rejectResponse.data) {
        clearSelection();
        toast.success(rejectResponse.data?.creditApproval?.message);
      }
    } catch (e: any) {
      const errorMessage = e?.message || "Something went wrong";
      toast.error(errorMessage);
    }
  };
  const DealColumnData = getDealsCulumn(setDealsModal, setDealsValues);

  return (
    <div className={classes.tableDesined}>
      <Typography component="span" variant="h4" sx={{ color: "#FFFF" }}>
        DEAL DATA
      </Typography>
      <SpaceCompsTable
        columns={DealColumnData}
        data={dealsData}
        enableColumnFilterModes
        enableColumnOrdering
        getRowId={(row) => row.id}
        editingMode="row"
        enableMultiRowSelection={false}
        enableEditing
        onEditingRowSave={handleDealEdit}
        enableGrouping
        enablePinning
        onPaginationChange={setPagination}
        rowCount={rowCount}
        onGlobalFilterChange={setGlobalFilter}
        manualPagination={true}
        state={{ rowSelection, pagination, globalFilter, showProgressBars: !dealsData }}
        onRowSelectionChange={setRowSelection}
        enableRowSelection
        muiTableBodyCellProps={({ cell }) => ({
          sx: { background: cell?.row?.original?.isApproved === "Approved" ? "#9bcb9b" : "#fb9999" },
        })}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "0",
            border: "1px dashed #e0e0e0",
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            backgroundColor: "#808080 !important",
            color: "#fff",
          },
        }}
        muiTableBodyProps={{
          sx: {
            "& tr td": {
              background: "#1B1C1E !important",
              color: "#FFFFFF",
              borderBottom: "1px solid #3F4349",
            },

            "& tr:nth-of-type(even)": {
              backgroundColor: "#1B1C1E !important",
              color: "#FFFFFF",
            },
          },
        }}
        renderTopToolbarCustomActions={({ table }) => {
          const rowValue = table.getSelectedRowModel().flatRows[0];
          return (
            <>
              <div className={classes.topToolbarBoxStyling}>
                <div className={classes.spaceStyleCss}>
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    <Button
                      disabled={
                        Object.values(rowSelection).length === 0 ||
                        !rowValue ||
                        rowValue?.original?.isApproved === "Approved"
                      }
                      onClick={() => {
                        AcceptDeal(rowValue);
                      }}
                      variant="contained"
                      className={
                        Object.values(rowSelection).length === 0 ||
                        !rowValue ||
                        rowValue?.original?.isApproved === "Approved"
                          ? "primary_btn"
                          : "success_btn"
                      }
                    >
                      Approve
                    </Button>
                    <Button
                      disabled={
                        Object.values(rowSelection).length === 0 ||
                        !rowValue ||
                        rowValue?.original?.isApproved !== "Approved"
                      }
                      onClick={() => {
                        RejectDeal(rowValue);
                      }}
                      variant="contained"
                      className={
                        Object.values(rowSelection).length === 0 ||
                        !rowValue ||
                        rowValue?.original?.isApproved !== "Approved"
                          ? "primary_btn"
                          : "error_btn"
                      }
                    >
                      Reject
                    </Button>
                  </div>
                </div>
                <div className={classes.tooltipStyle}>
                  <Tooltip title="Upload Excel">
                    <IconButton onClick={onImportExcelOpen}>
                      <UploadIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </>
          );
        }}
      />
      <ImportExcel isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} fields={DealField} />
      <DealsAction
        data={dealsValue}
        isOpen={dealsModal}
        onClose={() => {
          setDealsModal(!dealsModal);
        }}
      />
    </div>
  );
}

export default Deals;
const useStyles: any = makeStyles({
  tableDesined: {
    textAlign: "center",
    marginTop: "40px",
    margin: "auto",
    width: "78vw",
    display: "block",
    overflowX: "auto",
  },
  BoxStyling: {
    textAlign: "center",
  },
  topToolbarBoxStyling: {
    display: "flex",
    flex: 2,
    justifyContent: "space-between",
  },
  spaceStyleCss: {
    width: "100%",
    textAlign: "left",
  },
  tooltipStyle: {
    textAlign: "right",
    display: "flex",
  },
});
