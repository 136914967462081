import React, { forwardRef } from "react";
import { Col, Table, Button, Container, Row } from "reactstrap";
import ViewImg from "../../assets/images/logos/view-img.png";
const SingleProperty = forwardRef((props: any, ref: any) => {
  const { data } = props;
  return (
    <div ref={ref}>
      <Container fluid className="p-0">
        <Row>
          <Col lg={5}>
            <img alt="logo" src={ViewImg} className="view-img"></img>
          </Col>
          <Col lg={7}>
            <Table striped>
              <thead>
                <tr>
                  <th style={{ width: "70%" }}></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Year Built</th>
                  <td className="address-txt">{data?.getBuildingSpaceById?.yearBuilt}</td>
                </tr>
                <tr>
                  <th scope="row">Landlord</th>
                  <td className="address-txts">{data?.getBuildingSpaceById?.ownerPropertyManager}</td>
                </tr>
                <tr>
                  <th scope="row">Building Size (sq. ft.)</th>
                  <td className="address-txt">{data?.getBuildingSpaceById?.buildingSize}</td>
                </tr>
                <tr>
                  <th scope="row">Typical Floor Plate (sq. ft.)</th>
                  <td className="address-txt">{data?.getBuildingSpaceById?.typicalFloorPlate}</td>
                </tr>
                <tr>
                  <th scope="row">Number of Floors</th>
                  <td className="address-txt">{data?.getBuildingSpaceById?.floors}</td>
                </tr>
                <tr>
                  <th scope="row">Building Class</th>
                  <td className="address-txt">---</td>
                </tr>
                <tr>
                  <th scope="row">Market Node Vacancy as of deal date</th>
                  <td className="address-txt">---</td>
                </tr>
                <tr>
                  <th scope="row">Total Market Vacancy as of deal date</th>
                  <td className="address-txt">{data?.getBuildingSpaceById?.daysOnMarket}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row className="my-4">
          <Col>
            <Button className="btn btn-primary">View Space Comps</Button>
          </Col>
          <Col className="text-center">
            <Button className="btn btn-primary">View Building Availability</Button>
          </Col>
          <Col className="text-end">
            <Button className="btn btn-primary">View Building Stacking Plan</Button>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Table striped>
              <thead>
                <tr>
                  <th style={{ width: "60%" }}></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Tenant</th>
                  <td className="address-txts"> {data?.getBuildingSpaceById?.listingBroker}</td>
                </tr>
                <tr>
                  <th scope="row">Lease Type</th>
                  <td className="address-txt">{data?.getBuildingSpaceById?.leaseType}</td>
                </tr>
                <tr>
                  <th scope="row">Net Rent</th>
                  <td className="address-txt" style={{ paddingTop: 4, paddingBottom: 4 }}>
                    {data?.getBuildingSpaceById?.askingNetRent}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Avg. Net Rent over Term</th>
                  <td className="address-txt">${data?.getBuildingSpaceById?.askingNetRent}</td>
                </tr>
                <tr>
                  <th scope="row">Additional Rent (PSF)</th>
                  <td className="address-txt">${data?.getBuildingSpaceById?.totalAdditionalRent}</td>
                </tr>
                <tr>
                  <th scope="row">Gross Rent (PSF)</th>
                  <td className="address-txt">${data?.getBuildingSpaceById?.grossRent}</td>
                </tr>
                <tr>
                  <th scope="row">Show Suite</th>
                  <td className="address-txt">---</td>
                </tr>
                <tr>
                  <th scope="row">Cash Allowance</th>
                  <td className="address-txt">---</td>
                </tr>
                <tr>
                  <th scope="row">Real Rate (NER)</th>
                  <td className="address-txt">---</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col lg={6}>
            <Table striped>
              <thead>
                <tr>
                  <th style={{ width: "60%" }}></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Selling Fee</th>
                  <td className="address-txt">---</td>
                </tr>
                <tr>
                  <th scope="row">Listing Fee</th>
                  <td className="address-txt">---</td>
                </tr>
                <tr>
                  <th scope="row">Type</th>
                  <td className="address-txt">---</td>
                </tr>
                <tr>
                  <th scope="row">Deal Done</th>
                  <td className="address-txt">---</td>
                </tr>
                <tr>
                  <th scope="row">Commencement Date</th>
                  <td className="address-txt">---</td>
                </tr>
                <tr>
                  <th scope="row">No. of Members to verify this Comp</th>
                  <td className="address-txt">---</td>
                </tr>
              </tbody>
            </Table>
            <Col className="text-end mb-4">
              <Button className="btn btn-primary">Verify Comp Now</Button>
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
});
export default SingleProperty;
