/* eslint-disable no-const-assign */
import React, { useState } from "react";
import { omit } from "lodash";
import { User } from "../ts/interfaces/user";
import { errorMessages } from "../utils/constants/errorMessages";
import { regex } from "../utils/constants/regex";
import { IDefaultAuthFormFields } from "../ts/interfaces/authFields";
const useForm = (
  callback: (values: { [key: string]: string | number }) => void,
  defaultFields: IDefaultAuthFormFields[]
) => {
  const defaultValues = defaultFields.reduce(
    (acc: any, f: any) => ({ ...acc, [f.name ?? f.accessorKey]: f.value }),
    {}
  );
  const [values, setValues] = useState(defaultValues);
  const [submitInfo, setSubmitInfo] = useState(false);
  const [errors, setErrors] = useState({} as any);
  const getLength = (name: any) => defaultFields.find((item) => name === item.name)?.maxLength || "100";
  const validate = (name: string, type: string, value: string): void => {
    const isAuthField = name.toLowerCase().includes("password");
    const key = isAuthField ? "password" : name;
    const errorMessage = errorMessages[key] ?? errorMessages[type] ?? errorMessages.default;
    const regexString = regex[key] ?? regex[type];
    const valuelengths = getLength(name);
    if (!value) {
      setErrors((prev: any) => ({
        ...prev,
        [name]: errorMessages.required,
      }));
    } else if (value.length > Number(valuelengths)) {
      setErrors({
        ...errors,
        [name]: `Max Limit:  ${valuelengths}  characters`,
      });
    } else if (!new RegExp(regexString).test(value)) {
      setErrors({
        ...errors,
        [name]: errorMessage,
      });
    } else {
      const newObj = omit(errors, name);
      setErrors(() => ({ ...newObj }));
    }
  };

  const handleChange = (event: any, option?: any) => {
    event.preventDefault();
    const { name, value, type, checked } = event.target;
    const trimmedValue = value.trimStart();
    if (!option) {
      validateForm();
      validate(name, type, trimmedValue);
    }
    setValues((prev: any) => ({
      ...prev,
      [name]: type !== "checkbox" ? trimmedValue : checked,
    }));
  };
  const handleAddress = (address: any) => {
    validateForm();
    validate("address", "text", address?.label);
    setValues((prev: any) => ({
      ...prev,
      address: address?.label || "",
      buildingId: address?.id || "",
    }));
  };

  const handleDate = (date: any, name: any) => {
    const getDate = `${date?.date()}/${date?.month() + 1}/${date?.year()}`.toString();
    validateForm();
    validate(name, "text", getDate);
    setValues((prev: any) => ({
      ...prev,
      [name]: getDate || "",
    }));
  };

  const validateForm = (): boolean => {
    let valid = true;
    Object.keys(values).forEach((key: string) => {
      if (!values[key as keyof User]) {
        valid = false;
      }
    });
    return valid;
  };
  console.log(values);

  const validateFormSubmit = (): boolean => {
    let valid = true;

    Object.keys(values).forEach((key: string) => {
      if (!values[key as keyof User]) {
        setErrors((prev: any) => ({
          ...prev,
          [key]: errorMessages.required,
        }));
        valid = false;
      }
    });

    return valid;
  };

  const validatePasswrd = () => {
    setErrors((prev: any) => ({
      ...prev,
      confirmPassword: errorMessages.matchPassword,
    }));
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    const ValidateData = validateFormSubmit();

    if (ValidateData) {
      if (Object.keys(errors).length === 0 && Object.keys(values).length !== 0) {
        callback(values);
        setSubmitInfo(true);
      }
    }
  };
  const validateAddress = () => {
    let valid = true;
    Object.keys(values).forEach((key: string) => {
      if (!values[key as keyof User]) {
        if (key === "address" || key === "suite" || key === "floor") {
          setErrors((prev: any) => ({
            ...prev,
            [key]: errorMessages.required,
          }));
          valid = false;
        }
      }
    });
    return valid;
  };
  const handleDealSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    const ValidateData = validateAddress();
    if (ValidateData) {
      if (Object.keys(values).length !== 0 && Object.keys(errors).length === 0) {
        callback(values);
        setValues(defaultValues);
      }
    }
  };
  const clearForm = () => {
    setValues(defaultValues);
    setErrors({});
  };
  const clearFormData = () => {
    setValues(defaultValues);
  };
  return {
    values,
    errors,
    clearForm,
    clearFormData,
    handleChange,
    submitInfo,
    handleSubmit,
    validatePasswrd,
    handleAddress,
    handleDealSubmit,
    handleDate,
  };
};
export default useForm;
