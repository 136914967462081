import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRange = ({ onChange, setSearchDisabled }: any) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleStartDateChange = (date: Date | null) => {
    console.log(date?.toISOString(), "date");
    setStartDate(date);
    setEndDate(null); // Reset end date when start date changes
    setSearchDisabled(!date || !endDate); // Disable search if either start date or end date is null
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    console.log("startDate", startDate);
    console.log("endDate", date);
    const formattedStartDate = startDate
      ? `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`
      : null;
    const formattedEndDate = date ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : null;
    onChange([formattedStartDate, formattedEndDate], "baseYear");
    setSearchDisabled(!startDate || !date); // Disable search if either start date or end date is null
  };

  return (
    <div className="d-flex gap-2">
      <DatePicker
        selected={startDate}
        className="range_picker"
        onChange={handleStartDateChange}
        dateFormat="dd/MM/yyyy"
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="Start Date"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
      <DatePicker
        selected={endDate}
        className="range_picker"
        onChange={handleEndDateChange}
        selectsEnd
        dateFormat="dd/MM/yyyy"
        startDate={startDate}
        endDate={endDate}
        minDate={startDate} // Minimum date should be the selected start date
        placeholderText="End Date"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        disabled={!startDate} // Disable end date picker if start date is not selected
      />
    </div>
  );
};

export default DateRange;
