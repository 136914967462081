import React, { useEffect, FC } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export interface Props {
  error: any;
  data?: any;
}
const Toaster: FC<Props> = ({ error, data }: Props) => {
  useEffect(() => {
    if (data) {
      toast.success(data);
    }

    if (error) {
      error?.graphQLErrors
        ? error?.graphQLErrors.map(({ message }: { message: string }) => toast.error(message))
        : toast.error(error);
    }
  }, [error, data, data?.createUserByAdmin, data?.updateUserByAdmin, data?.deleteUserByAdmin]);

  return (
    <div>
      <ToastContainer />
    </div>
  );
};
export default Toaster;
