import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import InnerHeader from "../../components/Header/InnerHeader";
import { CREDIT_SCORE, CHECKOUT_SESSION } from "../../graphql/queries/getCreditScore";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Container } from "reactstrap";
import SelectCard from "../../components/layout/SelectCard/SelectCard";
import { useAuth } from "../../contexts/auth/provideAuth";

export interface IActivePlanProp {
  planType: string;
  price: number | undefined;
}

function CreditScore() {
  const { data, loading, refetch } = useQuery(CREDIT_SCORE, {
    fetchPolicy: "network-only",
  });
  const [handleCheckout, { data: checkoutData, loading: checkoutLoading }] = useLazyQuery(CHECKOUT_SESSION, {
    onCompleted: refetch,
  });
  const [plans, setPlans] = useState<IActivePlanProp>({ planType: "Yearly", price: 2499 });
  const styles = useStyles();
  const { handleState } = useAuth();
  const handlePlanChange = (prop: IActivePlanProp) => {
    setPlans(prop);
  };

  const handleSubmit = () => {
    handleCheckout({
      variables: {
        productName: plans.planType,
        unitAmount: plans.price,
        quantity: 1,
      },
    });
  };

  useEffect(() => {
    if (checkoutData) {
      window.location.href = checkoutData.createCheckoutSession;
    }
  }, [checkoutData, data]);
  useEffect(() => {
    const isLoading = loading || checkoutLoading;
    handleState(!isLoading);
  }, [loading, handleState, checkoutLoading]);
  return (
    <div>
      <InnerHeader props={"Credit Scrore"} />
      <Container fluid="lg">
        <div className={styles.crediPlanWrapper}>
          <div className={styles.creditSubscription}>
            {data?.getCreditScore?.info?.creditAmount && (
              <p className={styles.subscriptionHeading}>
                Your Credit Scrore is {data?.getCreditScore?.info?.creditAmount}
              </p>
            )}
            <h3 className={styles.subscriptionSubHeading}>Select your Spacecomps plan</h3>
            <p className={styles.subscriptionDesc}>
              Lorem ipsum dolor sit amet consectetur. Quis morbi cum nibh adipiscing at nunc nullam volutpat turpis.
              Elementum eget sagittis quam sed sagittis euismod. Blandit quisque sit risus molestie ornare. Volutpat
              elementum vulputate risus amet vulputate et sed.{" "}
            </p>
            <div className="text-end mt-5">
              <button className={styles.subscriptionButton} onClick={handleSubmit}>
                Continue
              </button>
            </div>
          </div>
          <div className={styles.crediPlanCardWrapper}>
            {cradData.map((item, index) => {
              const isActive = plans.planType === item.planType;
              return <SelectCard key={index} item={item} active={isActive} ActivePlans={handlePlanChange} />;
            })}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CreditScore;
const useStyles: any = makeStyles({
  parentBox: {
    border: "1px solid #ffff !important", // Add border style here
  },
  creditScore: {
    textAlign: "center",
    padding: "20px",
    color: "#FFFF",
  },
  crediPlanWrapper: {
    padding: "30px 0px 120px 0px",
    display: "flex",
    alignItems: "start",
    gap: "55px",
    "@media (max-width: 780px)": {
      flexDirection: "column",
    },
  },
  crediPlanCardWrapper: {
    display: "flex",
    gap: "15px",
    flexDirection: "column",
    flex: "50%,",
  },
  creditSubscription: {
    flex: "50%",
  },
  subscriptionDesc: {
    color: "#BABABA",
  },
  subscriptionHeading: {
    width: "592px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "28.8px",
    color: "#FD6262",
  },
  subscriptionSubHeading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "37px",
    lineHeight: "54px",
    color: "#FFFF",
  },
  subscriptionButton: {
    width: " 291px",
    padding: "19px 40.7812px 19px 39px",
    background: "#FD6262 !important",
    borderRadius: "5px",
    color: "#FFF",
    border: "none",
    "@media (max-width: 780px)": {
      width: "100%",
    },
  },
});

const cradData = [
  {
    planType: "Yearly",
    price: "$2499/Yearly",
    value: 2499,
    description: "Lorem ipsum dolor sit amet consectetur. Quis morbi cum nibh adipiscing turpis",
    creditScore: "Credits Allotted - 120",
  },
  {
    planType: "Monthly",
    price: "$250/Monthly",
    value: 250,
    description: "Lorem ipsum dolor sit amet consectetur. Quis morbi cum nibh adipiscing turpis",
    creditScore: "Credits Allotted - 10",
  },
];
