import React, { ChangeEventHandler, useState, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { IconButton, InputAdornment, MenuItem, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AutoComplete from "./AutoComplete";
const InputBox = (props: {
  name: string;
  title?: string;
  error: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
  type?: string;
  handleAddress?: any;
  handleDate?: any;
  options?: string[];
  key: any;
  className?: any;
  readonly?: boolean;
  countries?: any;
}) => {
  const {
    countries,
    readonly = false,
    handleAddress,
    handleDate,
    key,
    name,
    title,
    error,
    value,
    type,
    options,
    className,
    ...rest
  } = props;
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };
  const IconVisibility = () => {
    if (showPassword) {
      return <Visibility />;
    }
    return <VisibilityOff />;
  };

  const isAuthField = useMemo(() => name === "confirmPassword" || name === "password", [name]);
  const inputRef = useRef<any>(null);
  const fieldComponents = () => {
    switch (type) {
      case "text":
      case "password":
      case "select":
      case "":
        return (
          <TextField
            inputRef={inputRef}
            InputProps={{
              endAdornment:
                type === "password" ? (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClick} edge="end">
                      {IconVisibility()}
                    </IconButton>
                  </InputAdornment>
                ) : null,
              readOnly: readonly,
              autoComplete: "off",
            }}
            type={showPassword && isAuthField ? "text" : type}
            name={name}
            {...rest}
            autoComplete="off"
            className={`${classes.formControl} ${className}`}
            value={value}
            select={type === "select" ? true : false}
          >
            {options?.map((option: any) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        );
      case "date":
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "100%", border: "1px solid #8A8A8A", color: "#505050 !important" }}
              className={classes.datepicker}
              onChange={(newValue) => handleDate(newValue, name)}
            />
          </LocalizationProvider>
        );
      case "search":
        return (
          <AutoComplete
            handleChange={handleAddress}
            key={key}
            value={value}
            field={props}
            errors={error}
            countries={countries}
          />
        );
    }
  };
  return (
    <div className={classes.formGroup}>
      <small className={classes.title}>{title}</small>
      {fieldComponents()}
      {error && <span className="text-danger  ">{error}</span>}
    </div>
  );
};
export default InputBox;
const useStyles = makeStyles({
  formGroup: {
    marginTop: "23px",
  },
  inputIcons: {
    position: "absolute",
    right: 0,
    padding: "24px 30px",
  },
  title: {
    marginTop: "23px",
    width: "100%",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "28px",
    color: "#8A8A8A",
  },
  hidepassword: {
    height: "20px",
    width: "20px",
    objectFit: "contain",
  },
  datepicker: {
    color: "#505050 !important",
  },
  openpassword: {
    alignItems: "center",
    justifyContent: "center",
    top: "48%",
    float: "right",
    right: "51px",
    objectFit: "contain",
    transform: "translate(-90px, 5px)",
    "@media (max-width: 500px)": {
      transform: "translate(-10px, 5px)",
    },
  },
  formControl: {
    marginTop: "10px",
    boxSizing: "border-box",
    display: "block",
    alignItems: "flexStart",
    padding: "18.5px 0px 15.5px 12px",
    width: "100%",
    height: "55px",
    border: "2px solid #DDDDDD",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "28px",
    color: "#001D23",
  },
});
