import React from "react";
import MaterialReactTable, { MaterialReactTableProps } from "material-react-table";
import { ThemeProvider, createTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
const SpaceCompsTable = (props: MaterialReactTableProps<any>) => {
  const classes = useStyles();

  const defaultMaterialTheme = createTheme();
  return (
    <div className={classes.divSpacecombsStyle}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialReactTable
          muiTableHeadCellFilterTextFieldProps={{
            InputProps: {
              autoComplete: "off", // or autoComplete: "false"
            },
            sx: {
              "& input": {
                color: "#FFF",
              },
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              boxShadow: "none",
              background: "none !important",
            },
          }}
          muiTableHeadCellDragHandleProps={{
            sx: {
              color: "#FFF",
              opacity: "1",
            },
          }}
          muiTableHeadCellColumnActionsButtonProps={{
            sx: {
              color: "#FFF",
              opacity: "1",
            },
          }}
          muiTableBodyProps={{
            sx: {
              "& tr:nth-of-type(even)": {
                // backgroundColor: "none !important",
                background: "none !important",
              },
            },
          }}
          muiTableHeadProps={{
            sx: {
              "& tr": {
                textTransform: "capitalize",
              },
            },
          }}
          enableStickyHeader
          muiTableContainerProps={{ sx: { maxHeight: "80vh" } }}
          {...props}
          rowNumberMode="static"
          localization={{ rowNumber: "S.No" }}
        />
      </ThemeProvider>
    </div>
  );
};
export default SpaceCompsTable;
const useStyles: any = makeStyles({
  divSpacecombsStyle: {
    display: "block",
    margin: "auto",
    maxWidth: "95vw",
    overflow: "auto",
    backgroundColor: "#e8eaf5",
    marginBottom: "30px",
  },
});
