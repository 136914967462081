import React from "react";
import YourDeals from "./YourDeals";
import InnerHeader from "../Header/InnerHeader";

function Deals() {
  const fetchDeals = async () => {
    return;
  };
  return (
    <div>
      <InnerHeader props={"Submit Your Deals"} buttonAction={"Login"} />
      <YourDeals refetch={fetchDeals} />
    </div>
  );
}

export default Deals;
