import React from "react";
import ListTable from "../Table/Table";

export interface ActionProps {
  listData?: any;
}

const DetailCard = ({ listData }: any) => {
  if (listData?.edges?.length === 0 || !listData) {
    return <p className="text-center pt-3">No data Found</p>;
  }
  const tableHead = ["NO OF MONTHS", "NET RENT"];
  return (
    <div className="pt-3">
      <ListTable tableHead={tableHead} tableBody={listData?.edges} />
    </div>
  );
};

export default DetailCard;
