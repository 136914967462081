import { gql } from "@apollo/client";
export const GET_BUILDING_BY_ADDRESS = gql`
  query SearchBuildingByAddress($search: String) {
    searchBuildingByAddress(search: $search) {
      totalCount
      edges {
        id
        address
      }
    }
  }
`;
