/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-cond-assign */
import React, { useEffect } from "react";
import { ResetPassword } from "../../../components/layout/Auth";
import { CHANGEPASSWORD } from "../../../graphql/queries/changePassword";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { seconds } from "../../../utils/timeoutSeconds";
const UserResetPassword = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [handleReset, { data, error, loading }] = useLazyQuery(CHANGEPASSWORD);
  const onSubmit = (values: { [key: string]: string }): void => {
    handleReset({
      variables: {
        tokenId: search.substring(1),
        password: values.password,
      },
    });
  };

  useEffect(() => {
    const status = data?.changePassword?.code;
    if (status === "200") {
      setTimeout(() => {
        navigate("/login");
      }, seconds);
    }
  }, [data]);
  return (
    <ResetPassword onSubmitCallback={onSubmit} error={error} loading={loading} data={data?.changePassword?.message} />
  );
};

export default UserResetPassword;
