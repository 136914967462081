import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Dropdown, DropdownToggle } from "reactstrap";
import DropDownMenu, { IDropDownMenuProps } from "./DropDownMenu";

export interface IDropDownProps extends IDropDownMenuProps {
  title: any;
  dropStyle?: any;
}

const DropDown = ({ options, title, dropStyle }: IDropDownProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const location = useLocation();
  useEffect(() => {
    setDropdownOpen(false);
  }, [location]);
  return (
    <Dropdown className={dropStyle} isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="transparent">{title}</DropdownToggle>
      <DropDownMenu options={[...options]} />
    </Dropdown>
  );
};

export default DropDown;
