import { useEffect, useState } from "react";

export function useAuthProvider() {
  // need an api to verify token
  const token = localStorage.getItem("token");
  const isAdminUser = localStorage.getItem("isAdmin");
  const [user, setUser] = useState<boolean>(!!token);
  const [isAdmin, setIsAdmin] = useState<boolean>(!!isAdminUser);
  const [state, setState] = useState<boolean>(true);
  const [header, setheader] = useState<boolean>(true);

  const handleState = (value: boolean) => {
    setState(value);
  };
  const handleHeader = (value: boolean) => {
    setheader(value);
  };

  useEffect(() => {
    setUser(true);
    setIsAdmin(!!isAdminUser);
  }, [isAdminUser]);

  // signin method: It can either return a promise or execute a callback function.
  // You can prefer to keep this in userServices.js
  return {
    user,
    isAdmin,
    handleState,
    handleHeader,
    header,
    state,
  };
}
