import { useQuery } from "@apollo/client";
import React from "react";
import { Button } from "reactstrap";
import { CREDIT_SCORE } from "../../graphql/queries/getCreditScore";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { SpaceCompsTable } from "../layout/SpaceCompsTable";
import { MRT_ColumnDef } from "material-react-table";
import { GET_CREDIT_HISTORY } from "../../graphql/queries/getCreditHistory";
import { getLocalData, getFilteredTableData } from "../../utils/commonFunctions";
import { IconButton } from "@mui/material";
interface SubscriptionsProps {
  column: MRT_ColumnDef<any>[];
}
const SubscribedPlans = ({ column }: SubscriptionsProps) => {
  const { data } = useQuery(CREDIT_SCORE, {
    fetchPolicy: "network-only",
  });
  const { loginInfo }: any = getLocalData();
  const { data: historyData } = useQuery(GET_CREDIT_HISTORY, {
    variables: {
      getCreditHistoryByIdId: loginInfo?.entityOid,
      search: "",
    },
    fetchPolicy: "network-only",
  });
  const filteredData = getFilteredTableData(historyData?.getCreditHistoryById?.edges || []);
  const { creditAmount } = data?.getCreditScore?.info || {};
  const classes = useStyles();
  return (
    <div className={classes.creditProfile}>
      <SpaceCompsTable
        enableColumnActions={false}
        enableSorting={false}
        columns={column}
        data={filteredData}
        autoResetAll
        enableGrouping
        enableGlobalFilterModes
        enableColumnDragging={false}
        initialState={{
          showGlobalFilter: true,
        }}
        muiSearchTextFieldProps={{
          placeholder: "Type Here",
          sx: {
            minWidth: "18rem",
            maxHeight: "48px",
            "& .MuiOutlinedInput-root": {
              color: "#FFFF !important",
              "& fieldset.MuiOutlinedInput-notchedOutline": {
                border: "1px solid #FFFF",
                color: "#FFFF !important",
              },
            },
          },
          variant: "outlined",
        }}
        positionGlobalFilter="right"
        muiTableHeadProps={{
          sx: {
            "& tr th div": {
              justifyContent: "center",
            },
          },
        }}
        muiTableBodyProps={{
          sx: {
            "& tr td": {
              textAlign: "center",
              background: "#1B1C1E !important",
              color: "#FFFFFF",
              borderBottom: "1px solid #3F4349",
            },

            "& tr:nth-of-type(even)": {
              backgroundColor: "#1B1C1E !important",
              color: "#FFFFFF",
            },
          },
        }}
        renderTopToolbarCustomActions={() => (
          <div className={classes.headings}>
            <h3 className={classes.creditscore}>
              current credit scores:&nbsp;<span>{creditAmount}</span>
            </h3>
          </div>
        )}
        renderToolbarInternalActions={() => (
          <IconButton className={classes.headerBoday} sx={{ padding: "0 10px 0 20px", marginBottom: "50px" }}>
            {" "}
            <Link to="/credit-score">
              <Button className={`btn btn-primary ${classes.addbtn}`}>
                <span className={classes.addCredit}>+ Add Credit</span>
              </Button>
            </Link>
          </IconButton>
        )}
      />
    </div>
  );
};

export default SubscribedPlans;

const useStyles: any = makeStyles({
  creditProfile: {
    margin: "50px 0",
  },
  headerBoday: {
    background: "none !important",
  },
  addCredit: {
    fontSize: "16px",
    fontWeight: "700",
  },
  headings: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "capitalize",
    paddingBottom: "20px",
  },
  addbtn: {
    minHeight: "56px",
  },
  creditscore: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    textTransform: "uppercase",
    display: "flex",
    justifyContent: "center",
    color: "#FD6262",
    paddingTop: "15px",
    alignItems: "center",
    "& span": {
      color: "#FFFF",
      fontSize: "32px",
    },
  },
});
