import React from "react";
import { Container } from "reactstrap";
import logo from "../../../assets/images/dark/logo-circle.png";
import facebook from "../../../assets/images/dark/facebook.png";
import linkedin from "../../../assets/images/dark/linkedin.png";
import twitter from "../../../assets/images/dark/twitter.png";
import Banner from "../../../assets/images/dark/banner.png";
import facebook_black from "../../../assets/images/dark/facebook_black.png";
import linkedin_black from "../../../assets/images/dark/linkedin_black.png";
import twitter_black from "../../../assets/images/dark/twitter_black.png";
const AboutImages = () => {
  return (
    <div className="buildingSearch">
      <section className="search-section">
        <Container fluid>
          <img src={logo} alt="img" />
          <img src={facebook} alt="img" />
          <img src={linkedin} alt="img" />
          <img src={twitter} alt="img" />
          <img src={facebook_black} alt="img" />
          <img src={linkedin_black} alt="img" />
          <img src={twitter_black} alt="img" />
          <div>
            <img src={Banner} alt="img" />
          </div>
        </Container>
      </section>
    </div>
  );
};

export default AboutImages;
