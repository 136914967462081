import { DocumentNode } from "graphql";
import { Data } from "react-spreadsheet-import/types/types";

// do a api request to save the data
// maintain a max record threshold for a single request
// so API handling could be efficient

export const saveExcelData = async (
  data: Data<string>[],
  client: any,
  query: DocumentNode,
  maxRecordPerRequest: number = 1000
) => {
  const maxRecordForApiRequest = Math.min(maxRecordPerRequest, data.length);
  let responseData;
  for (let i = 0; i < data.length; i += maxRecordForApiRequest) {
    const requestData = data.slice(i, i + maxRecordForApiRequest);
    responseData = await saveExcelImportData({ input: requestData }, client, query);
  }
  return responseData;
};

export const saveExcelImportData = async (variables: any, client: any, query: DocumentNode) => {
  try {
    return await client.mutate({
      mutation: query,
      variables,
    });
  } catch (err) {
    return { errors: [{ message: err }] };
  }
};
