import forwardbutton from "../assets/images/logos/right.png";
function NextArrow(props: any) {
  const { className, style, onClick, customStyle, nextButton } = props;
  return (
    <img
      alt="arrow1"
      onClick={onClick}
      className={className}
      style={{
        ...style,
        ...customStyle,
      }}
      src={nextButton || forwardbutton}
    />
  );
}
export default NextArrow;
