import { gql } from "@apollo/client";
export const GET_BUILDING_AVERAGE_CALCULATION = gql`
  query GetBuildingAverageCalculationQuery($search: String) {
    getBuildingAverageCalculationQuery(search: $search) {
      direct_average
      sublet_average
      total_floors
      total_vacancy_average
    }
  }
`;
