import React, { useEffect, useMemo } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import AdminSidebar from "../layout/Navigations/Admin/Sidebar";
import { useAuth } from "../../contexts/auth/provideAuth";
import AdminNavbar from "../layout/Navigations/Admin/AdminNavbar";
import { getLocalData } from "../../utils/commonFunctions";
import { ProvideData } from "../../contexts/dataContext/dataContext";

export interface IPrivateRouteProps {
  path: string;
}

const PrivateRoute = ({ path }: IPrivateRouteProps) => {
  const { user } = useAuth();
  const { token, isAdmin }: any = getLocalData();
  const navigationPath: string = useMemo(() => (path === "admin" ? "/admin/login" : "/"), [path]);
  const wrapperClassName = path === "admin" ? "pageWrapperAdmin" : "pageWrapper";

  const redirectTo = useNavigate();
  const { handleState } = useAuth();
  useEffect(() => {
    if (!token) {
      handleState(true);
      redirectTo(navigationPath);
    }
  }, [token, navigationPath, redirectTo, handleState]);
  if (isAdmin === "false" && path === "admin") {
    return <Navigate to="/" />;
  }
  if (isAdmin === "true" && path === "user") {
    return <Navigate to="/admin" />;
  }

  return user ? (
    <ProvideData>
      {path === "admin" && <AdminNavbar />}
      <div className={`d-lg-flex ${wrapperClassName}`}>
        {path === "admin" && <AdminSidebar />}
        <div className="contentArea m-0">
          <Outlet />
        </div>
      </div>
    </ProvideData>
  ) : (
    <Navigate to={navigationPath} />
  );
};
export default PrivateRoute;
