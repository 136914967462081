import React from "react";
import { Link } from "react-router-dom";

export interface BtnProps {
  path: any;
  name: string;
}
const GetStartedButton = ({ path, name }: BtnProps) => {
  return (
    <div>
      <Link to={path}>
        <button className="btn btn-primary ">{name}</button>
      </Link>
    </div>
  );
};

export default GetStartedButton;
