import { gql } from "@apollo/client";
export const CREDIT_LIST = gql`
  query CreditList {
    creditList {
      code
      success
      message
      info {
        id
        userId
        buildingName
        address1
        address2
        address3
        pinCode
        isApproved
      }
    }
  }
`;

export const CREDIT_APPROVAL = gql`
  query CreditApproval($creditId: String, $status: Boolean) {
    creditApproval(creditId: $creditId, status: $status) {
      id
      message
    }
  }
`;
