import { gql } from "@apollo/client";
export const GET_CREDIT_HISTORY = gql`
  query GetCreditHistoryById($getCreditHistoryByIdId: String, $search: String) {
    getCreditHistoryById(id: $getCreditHistoryByIdId, search: $search) {
      edges {
        node {
          creditAmount
          status
          creditMsg
          createdOn
        }
        cursor
      }
      totalCount
    }
  }
`;
