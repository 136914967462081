import { Box, Input, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import FormControl from "@mui/material/FormControl";

function truncate(cell: any) {
  return (
    <Box
      sx={{
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      {cell?.getValue()}
    </Box>
  );
}

export const adminTableColumns = (data: any, state: any, setState: any) => {
  return [
    {
      accessorKey: "id",
      enablePinning: false,
      header: "ID",
      muiTableBodyCellEditTextFieldProps: {
        disabled: true,
        hiddenLabel: true,
        hidden: true,
      },
    },
    {
      accessorKey: "email",
      header: "Work Email Address",
      muiTableBodyCellEditTextFieldProps: {
        disabled: true,
      },
    },
    {
      accessorKey: "role",
      header: "Role",
      customFilterAndSearch: (term: string, rowData: any) => {
        console.log(term, rowData);
        rowData.role.role.toLowerCase().includes(term.toLowerCase());
      },
      Edit: ({ cell }: any) => {
        if (state === "") {
          setState(cell?.row?.original?.role?.id);
        }
        return (
          <FormControl>
            <InputLabel id="demo-select-small">Role</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              label="Role"
              value={state}
              onChange={(e) => {
                setState(e.target.value);
              }}
            >
              {data.map((role: any) => {
                return (
                  <MenuItem key={role.id} value={role.id}>
                    {role.role}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      },
      Cell: ({ row }: any) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {row?.original?.role?.role}
        </Box>
      ),
    },
    {
      accessorKey: "isActive",
      header: "Status",
      muiTableHeadCellProps: { sx: { color: "#FFF", background: "grey" } },
      muiTableBodyCellEditTextFieldProps: {
        disabled: true,
      },
      Edit: ({ cell }: any) => {
        const { isActive } = cell?.row?.original;
        return <Input disabled value={isActive ? "Active" : "Inactive"} />;
      },
      Cell: ({ row }: any) => {
        const { isActive } = row?.original;
        return isActive ? <p style={{ color: "green" }}>Active</p> : <p style={{ color: "red" }}>Inactive</p>;
      },
    },
  ];
};

export const adminModalTableColumns = (data: any) => {
  return [
    {
      accessorKey: "email",
      header: "Work Email Address",
      value: "",
      type: "email",
    },
    {
      accessorKey: "role",
      header: "Role",
      value: "",
      type: "select",
      row: data,
    },
  ];
};

export const buildingManagement: MRT_ColumnDef<any>[] = [
  {
    accessorKey: "address",
    enableEditing: false,
    header: "Address",
  },
  {
    accessorKey: "buildingName",
    header: "Building Name Complex Name",
  },
  {
    accessorKey: "city",
    header: "City",
  },
  {
    accessorKey: "market",
    header: "Market",
  },

  {
    accessorKey: "officeSize",
    header: "Office Size",
  },
  {
    accessorKey: "buildingPhysicalStatus",
    header: "Building Physical Status",
  },
  {
    accessorKey: "buildingSize",
    header: "Building Size",
  },
  {
    accessorKey: "yearBuilt",
    header: "Year Built & Year Renovated",
  },

  {
    accessorKey: "class",
    header: "Class",
  },
  {
    accessorKey: "floors",
    header: "Floors",
    Footer: (props) => {
      return (
        <Stack>
          Total Floors:
          <Box color="success.main">{props?.table?.options?.data[0]?.total_floors}</Box>
        </Stack>
      );
    },
  },
  {
    accessorKey: "typicalFloorPlate",
    header: "Typical Floor Plate (sq.ft)",
  },
  {
    accessorKey: "realityTaxes",
    header: "Realty Taxes ($ per sq.ft)",
  },
  {
    accessorKey: "operatingCosts",
    header: "Operating Costs ($ per sq.ft)",
  },
  {
    accessorKey: "electricity",
    header: "Electricity ($per sq.ft)",
  },
  {
    accessorKey: "totalAdditionalRent",
    header: "Total Additional Rent ($per sq.ft)",
  },
  {
    accessorKey: "baseYear",
    header: "Base Year",
  },
  {
    accessorKey: "ownerPropertyManager",
    header: "Owner Property Manager",
  },
  {
    accessorKey: "totalDirectAvailable",
    header: "Total Direct Available (sq.ft)",
  },

  {
    accessorKey: "totalSubletAvailable",
    header: "Total Sublet Available (sq.ft)",
  },
  {
    accessorKey: "totalavailable",
    header: "Total Available (sq.ft)",
  },
  {
    accessorKey: "occupant",
    header: "Occupant",
  },
  {
    accessorKey: "zoning",
    header: "Zoning",
  },
  {
    accessorKey: "lotSize",
    header: "Lot Size (Acres)",
  },
  {
    accessorKey: "parkingRatio",
    header: "Parking Ratio # of stalls",
  },
  {
    accessorKey: "vacancyDirectPercentage",
    header: "Vacancy % Direct",
    enableEditing: false,
    Cell: ({ row }) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {Number(row?.original?.vacancyDirectPercentage) || 0}{" "}
        </Box>
      );
    },
    Footer: (props) => {
      return (
        <Stack>
          Average of Vacancy % Direct:
          <Box color="success.main">{props?.table?.options?.data[0]?.direct_average}</Box>
        </Stack>
      );
    },
  },
  {
    accessorKey: "vacancySubletPercentage",
    header: "Vacancy % Sublet",
    enableEditing: false,
    Cell: ({ row }) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {Number(row?.original?.totalSubletAvailable) || 0}{" "}
        </Box>
      );
    },
    Footer: (props) => {
      return (
        <Stack>
          Average of Vacancy % Sublet:
          <Box color="success.main">{props?.table?.options?.data[0]?.sublet_average}</Box>
        </Stack>
      );
    },
  },
  {
    accessorKey: "vacancyTotalPercentage",
    header: "Vacancy % Total",
    enableEditing: false,
    Cell: ({ row }) => {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {Number(row?.original?.vacancyTotalPercentage) || 0}{" "}
        </Box>
      );
    },
    Footer: ({ table }) => {
      return (
        <Stack>
          Average of Vacancy % Total:<Box color="success.main">{table?.options?.data[0]?.total_vacancy_average}</Box>
        </Stack>
      );
    },
  },
];
export const buildingManagementDynamic: MRT_ColumnDef<any>[] = [
  {
    accessorKey: "id",
    header: "uuid",
    muiTableHeadCellProps: { hidden: true },
    enablePinning: false,
    muiTableBodyCellProps: {
      hidden: true,
    },
    muiTableBodyCellEditTextFieldProps: {
      disabled: true,
      hidden: true,
      type: "text",
    },
  },
  {
    accessorKey: "address",
    header: "Address",
    filterVariant: "text",
    columnFilterModeOptions: ["fuzzy", "contains"],
    muiTableBodyCellEditTextFieldProps: {
      disabled: true,
      type: "text",
    },
  },
  {
    accessorKey: "realityTaxes",
    header: "Realty Taxes \n($ per sq. ft.)",
  },
  {
    accessorKey: "operatingCosts",
    header: "Operating Costs \n($ per sq. ft.)",
  },
  {
    accessorKey: "electricity",
    header: "Electricity\n ($ per sq. ft.)",
  },
  {
    accessorKey: "totalAdditionalRent",
    header: "Total Additional Rent \n($ per sq. ft.)",
  },
  {
    accessorKey: "totalDirectAvailable",
    header: "Total Direct Available  (sq. ft.)",
  },
  {
    accessorKey: "totalSubletAvailable",
    header: "Total Sublet Available  (sq. ft.)",
  },
  {
    accessorKey: "totalavailable",
    header: "Total Available  (sq. ft.)",
  },
];

export const getDealsCulumn = (setDealsModal: any, setDealsValues: any) => {
  const muiTableHeaderCallProps = { sx: { color: "#FFF", background: "grey" } };
  return [
    {
      header: "Add Net Rent",
      enableEditing: false,
      muiTableHeadCellProps: muiTableHeaderCallProps,
      Cell: ({ row }: any) => (
        <button
          className="btn btn-primary"
          onClick={() => {
            setDealsModal(true);
            setDealsValues(row?.original);
          }}
        >
          Net Rent
        </button>
      ),
      enableColumnActions: false,
      enableColumnOrdering: false,
      enableGrouping: false,
    },
    {
      accessorKey: "id",
      header: "uuid",
      muiTableHeadCellProps: { hidden: true, sx: { color: "#FFF", background: "grey" } },
      enablePinning: false,
      muiTableBodyCellProps: {
        hidden: true,
      },
      muiTableBodyCellEditTextFieldProps: {
        disabled: true,
        hidden: true,
        type: "text",
      },
    },
    {
      accessorKey: "address",
      enableEditing: false,
      header: "Address",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },

    {
      accessorKey: "comments",
      header: "Comments",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "cashAllowance",
      header: "Cash Allowance (PSF)",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "dateAdded",
      header: "Date Added",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "dealDone",
      header: "Deal Done",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },

    {
      accessorKey: "fixturn",
      header: "Fixturing Period(MTHS)",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "avgNetRentOverTerm",
      header: "Avg. Net Rent Over Term",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "floor",
      header: "Floor",
      enableEditing: false,
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "grossRent",
      header: "Gross Rent ($/SQ. FT)",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "realGrossRate",
      header: "GER- Real Gross Rate",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "netReentMonthly",
      header: "Net Free Rent (months)",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "landlord",
      header: "Landlord",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },

    {
      accessorKey: "modelSuiteCost",
      header: "Model Suite Cost (PSF)",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "ner",
      header: "Ner ($/SQ.FT)",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "netRent",
      header: "Net Rent ($/SQ. FT.)",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "node",
      header: "Node",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "netGrossFreeRent",
      header: "Net/Gross Free Rent (Months)",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "reFees",
      header: "Re Fees ($/SQ. FT)",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "suite",
      header: "Suite",
      enableEditing: false,
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "size",
      header: "Size (SQ. FT.)",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "source",
      header: "Source",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "startDate",
      header: "Start Date",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "sellingFeeTotal",
      header: "Selling Fee Total (PSF)",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "tenant",
      header: "Tenant",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "terms",
      header: "Term (MTHS)",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "ti",
      header: "Ti ($/SQ. FT)",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "tmi",
      header: "Tmi ($/SQ.FT)",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "type",
      header: "Type",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "totalAdditionalRent",
      header: "Total Additional Rent",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "llw",
      header: "LLW",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "listingCommissions",
      header: "Listing Commissions",
      muiTableHeadCellProps: muiTableHeaderCallProps,
      enableEditing: false,
    },
    {
      accessorKey: "listingFeeTotal",
      header: "Listing Fee Total (PSF)",
      muiTableHeadCellProps: muiTableHeaderCallProps,
    },
    {
      accessorKey: "sellingCommissions",
      header: "Selling Commissions",
      muiTableHeadCellProps: muiTableHeaderCallProps,
      enableEditing: false,
    },
    {
      accessorKey: "discountRate",
      header: "Discount Rate",
      muiTableHeadCellProps: muiTableHeaderCallProps,
      enableEditing: false,
    },
    {
      accessorKey: "lto",
      header: "Lto",
      muiTableHeadCellProps: muiTableHeaderCallProps,
      enableEditing: false,
    },
    {
      accessorKey: "isApproved",
      header: "Status",
      enableEditing: false,
      muiTableHeadCellProps: muiTableHeaderCallProps,
      Cell: ({ row }: { row: any }) => {
        if (row.original.isApproved === "Approved") {
          return (
            <p
              className=""
              style={{
                background: "#4BB543",
                color: "#fff",
                width: "90px",
                paddingLeft: "14px",
                height: "40px",
                paddingTop: "10px",
                borderRadius: "50px",
              }}
            >
              Approved
            </p>
          );
        }
        return (
          <p
            className=""
            style={{
              background: "#F2DBC5",
              width: "90px",
              color: "red",
              paddingLeft: "19px",
              height: "40px",
              paddingTop: "10px",
              borderRadius: "50px",
            }}
          >
            Pending
          </p>
        );
      },
    },
  ];
};

export const spacesColumn: MRT_ColumnDef<any>[] = [
  {
    accessorKey: "id",
    header: "uuid",
    muiTableHeadCellProps: { hidden: true },
    muiTableBodyCellProps: {
      hidden: true,
    },
    enablePinning: false,
    muiTableBodyCellEditTextFieldProps: {
      disabled: true,
      hidden: true,
      type: "text",
    },
  },
  {
    accessorKey: "address",
    enableEditing: false,
    header: "Address",
  },
  {
    accessorKey: "askingNetRent",
    header: "Asking Net Rent",
  },
  {
    accessorKey: "availableOn",
    header: "Available On",
  },
  {
    accessorKey: "buildingSize",
    header: "Building Size",
  },
  {
    accessorKey: "city",
    header: "City",
  },
  {
    accessorKey: "daysOnMarket",
    header: "Days On Market",
  },
  {
    accessorKey: "expiryDate",
    header: "Expiry Date",
  },
  {
    accessorKey: "floors",
    header: "Floors",
  },
  {
    accessorKey: "grossRent",
    header: "Gross Rent",
  },
  {
    accessorKey: "leaseType",
    header: "Lease Type",
  },
  {
    accessorKey: "listedOnDate",
    header: "Listed On Date",
  },
  {
    accessorKey: "listingBroker",
    header: "Listing Broker",
  },
  {
    accessorKey: "listingBrokerEMAIL",
    header: "Listing Broker Email",
  },
  {
    accessorKey: "listingBrokerPHONE",
    header: "Listing Broker Phone",
  },
  {
    accessorKey: "listingComments",
    header: "Listing Comments",
  },
  {
    accessorKey: "market",
    header: "Market",
  },
  {
    accessorKey: "maxContiguous",
    header: "Max Contiguous",
  },
  {
    accessorKey: "minDivisible",
    header: "Min Divisible",
  },
  {
    accessorKey: "ownerPropertyManager",
    header: "owner Property Manager",
  },
  {
    accessorKey: "parkingRatio",
    header: "Parking Ratio",
  },
  {
    accessorKey: "physicalStatus",
    header: "Physical Status",
  },
  {
    accessorKey: "suiteConditionNotes",
    header: "Suite Condition Notes",
  },
  {
    accessorKey: "suiteSize",
    header: "Suite Size",
  },
  {
    accessorKey: "suiteUnit",
    header: "Suite Unit",
  },
  {
    accessorKey: "totalAdditionalRent",
    header: "Total Additional Rent",
  },
  {
    accessorKey: "typicalFloorPlate",
    header: "Typical Floor Plate",
  },
  {
    accessorKey: "yearBuilt",
    header: "Year Built",
  },
  {
    accessorKey: "zoning",
    header: "Zoning",
  },
  {
    accessorKey: "Vacancy",
    header: "Vacancy",
  },
  {
    accessorKey: "marketingComments",
    header: "Marketing Comments.",
  },
];

export const stackingColumn: MRT_ColumnDef<any>[] = [
  {
    accessorKey: "id",
    header: "S.No.",
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableGrouping: false,
    size: 70,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "grey" } },
  },

  {
    accessorKey: "officeSize",
    header: "Office Size",
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "grey" } },
  },
  {
    accessorKey: "buildingPhysicalStatus",
    header: "Building Physical Status",
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "grey" } },
  },
  {
    accessorKey: "yearBuilt",
    header: "Year Built & Year Renovated",
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "grey" } },
  },

  {
    accessorKey: "class",
    header: "Class",
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "grey" } },
  },
  {
    accessorKey: "floors",
    header: "Floors",
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "grey" } },
  },
  {
    accessorKey: "typicalFloorPlate",
    header: "Typical Floor Plate (sq.ft)",
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "grey" } },
  },
];

export const DealColumn: MRT_ColumnDef<any>[] = [
  {
    accessorKey: "id",
    header: "S.No.",
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "grey" } },
  },
  {
    accessorKey: "realityTaxes",
    header: "Realty Taxes ($ per sq.ft)",
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "grey" } },
  },
  {
    accessorKey: "operatingCosts",
    header: "Operating Costs ($ per sq.ft)",
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "grey" } },
  },
  {
    accessorKey: "electricity",
    header: "Electricity ($per sq.ft)",
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "grey" } },
  },
  {
    accessorKey: "totalAdditionalRent",
    header: "Total Additional Rent ($per sq.ft)",
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "grey" } },
  },
  {
    accessorKey: "baseYear",
    header: "Base Year",
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "grey" } },
  },
  {
    accessorKey: "ownerPropertyManager",
    header: "Owner Property Manager",
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "grey" } },
  },
];
export const buildingSpaces: MRT_ColumnDef<any>[] = [
  {
    accessorKey: "address",
    header: "Address",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },

  {
    accessorKey: "market",
    header: "Market",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "floors",
    header: "Floors",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "buildingSize",
    header: "Building Size",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "physicalStatus",
    header: "Unit Physical Status",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "yearBuilt",
    header: "Year Built Building Class",
    size: 300,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "ownerPropertyManager",
    header: "Owner",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
    Cell: ({ cell }) => {
      return truncate(cell);
    },
  },
  {
    accessorKey: "listedOnDate",
    header: "Listed On Date",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
];
export const buildingComps: MRT_ColumnDef<any>[] = [
  {
    accessorKey: "address",
    header: "Address",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "comps",
    header: "Comps",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
    Cell: ({ row }: { row: any }) => {
      return <Typography>{row.original?.isCreatedDeal ? "Sharing" : "Seeking"}</Typography>;
    },
  },
  {
    accessorKey: "node",
    header: "Node",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "tenant",
    header: "Tenant",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "landlord",
    header: "Landlord",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "size",
    header: "Size",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "terms",
    header: "Terms",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "netRent",
    header: "Net Rent",
    size: 300,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "tmi",
    header: "Tmi",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
    Cell: ({ cell }) => {
      return truncate(cell);
    },
  },
  {
    accessorKey: "grossRent",
    header: "GrossRent",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "ti",
    header: "Ti",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "fixturn",
    header: "Fixturn",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "ner",
    header: "Ner",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },

  {
    accessorKey: "reFees",
    header: "Re Fees",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "type",
    header: "Type",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "startDate",
    header: "Start Date",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "dealDone",
    header: "Deal Done",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "comments",
    header: "Comments",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "source",
    header: "Source",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "dateAdded",
    header: "Date Added",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
];

export const dealComps: MRT_ColumnDef<any>[] = [
  {
    accessorKey: "address",
    header: "Address",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "landlord",
    header: "Landlord",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },

  {
    accessorKey: "tenant",
    header: "Tenant",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "size",
    header: "Size",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "startDate",
    header: "Start Date",
    size: 250,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
];

export const accountTable: MRT_ColumnDef<any>[] = [
  {
    accessorKey: "createdOn",
    header: "Date",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },

  {
    accessorKey: "creditMsg",
    header: "Detail",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "status",
    header: "Status",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
  },
  {
    accessorKey: "creditAmount",
    header: "Credit",
    size: 200,
    muiTableHeadCellProps: { sx: { color: "#FFF", background: "#FD6262" } },
    Cell: ({ row }: { row: any }) => {
      console.log(row.original, "ytest");

      if (Number(row.original?.creditAmount) < 0) {
        return (
          <p className="" style={{ color: "#EB5757", fontWeight: 500 }}>
            {row.original?.creditAmount}
          </p>
        );
      }
      return (
        <p className="" style={{ color: "#27AE60", fontWeight: 500 }}>
          + {row.original?.creditAmount}
        </p>
      );
    },
  },
];
