import { gql } from "@apollo/client";
export const DEAL_IMPORT = gql`
  mutation CreateDeals($input: [createBuildingDealsInput]) {
    createDeals(input: $input) {
      deals_values
      created_at
      updated_at
    }
  }
`;

export const EDIT_DEALSDATA = gql`
  mutation EditDealsBuilding($input: buildingDealsInput) {
    editDealsBuilding(input: $input)
  }
`;

export const CREATE_DEAL_NET_RENT = gql`
  mutation CreateDealNetRent($input: dealNetRentInput) {
    createDealNetRent(input: $input)
  }
`;
