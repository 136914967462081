import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export interface FormProps {
  tableHead: string[];
  tableBody: [key: string];
}
const ListTable = ({ tableHead, tableBody }: FormProps) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="caption table">
        <TableHead>
          <TableRow>
            {tableHead?.map((item: any, index: number) => {
              return (
                <TableCell align="center" sx={{ textTransform: "uppercase" }} key={index}>
                  {item}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBody?.map((row: any) => {
            const { __typename, rentMonthly, presentValue, createdOn, dealId, id, ...rest } = row?.node;
            return (
              <TableRow key={rest.name}>
                <TableCell align="center">{rest?.noOfMonths}</TableCell>
                <TableCell align="center">{rest?.netRent}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListTable;
