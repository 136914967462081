import React, { useEffect, useRef } from "react";
import SingleProperty from "../../../components/SearchFilter/SingleProperty";
import { Container, Row, Col, Button } from "reactstrap";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useData } from "../../../contexts/dataContext/dataContext";
import DealsInfo from "../../../components/SearchFilter/DealsInfo";
import { useLazyQuery } from "@apollo/client";
import { GET_DEAL_DETAIL } from "../../../graphql/queries/getDealById";
import { useSearchParams } from "react-router-dom";
import { GET_PROPERTY_DETAIL } from "../../../graphql/queries/propetyDetail";
import { toast } from "react-toastify";
import InnerHeader from "../../../components/Header/InnerHeader";

function PropertyDetail() {
  const { detailsData, setDetails } = useData();
  const [searchParams] = useSearchParams();

  const [getSpaceData] = useLazyQuery<any>(GET_PROPERTY_DETAIL, {
    fetchPolicy: "cache-and-network",
  });
  const [getDealData] = useLazyQuery<any>(GET_DEAL_DETAIL, {
    fetchPolicy: "cache-and-network",
    onError: (error: any) => toast.error(error?.message),
  });

  const fetchData = async () => {
    const userId: any = localStorage.getItem("loginInfo");
    const GQquery = searchParams.get("tabname") === "space" ? getSpaceData : getDealData;
    const variableName = searchParams.get("tabname") === "space" ? "getBuildingSpaceByIdId" : "getDealByIdId";
    try {
      const { data: response } = await GQquery({
        variables: { [variableName]: searchParams.get("id"), userId: searchParams.get("userId") || userId?.entityOid },
      });
      if (response) {
        setDetails({ data: response, name: searchParams.get("tabname") });
      }
      console.log(response, "responseresponse");
    } catch (error) {
      // Handle error, e.g., display error message
      console.log("something went wrong", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  const childRef = useRef();
  const pdfdownload = () => {
    const content = childRef.current;
    if (!content) {
      return;
    }
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 30, 20, 160, 180);
      pdf.save("download.pdf");
    });
  };
  const { data, name } = detailsData;
  const isSpace = name === "space";
  const address = isSpace ? data?.getBuildingSpaceById?.address : data?.getDealById?.address;
  return (
    <div>
      <InnerHeader props={"Property Detail"} />
      <section className="search-section property-detail-section my-5">
        <Container fluid>
          <Row>
            <Col lg={4}>
              <h2 style={{ color: "#FFFF" }}>{address}</h2>
            </Col>
            <Col lg={8}>
              <div className="btn-group-widget">
                <Button className="btn btn-primary btn-sm-primary" onClick={pdfdownload}>
                  Print PDF{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-printer"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                  </svg>
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {isSpace ? <SingleProperty data={data} ref={childRef} /> : <DealsInfo data={data} ref={childRef} />}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default PropertyDetail;
