import React from "react";
import InnerHeader from "../Header/InnerHeader";
import CompanyDetails from "../../pages/companyDetails/CompanyDetails";
import Subscribe from "../subscribe/Subscribe";

const AboutUS = () => {
  return (
    <div>
      <InnerHeader props={"About us"} buttonAction={"Login"} />
      <CompanyDetails />
      <Subscribe />
    </div>
  );
};

export default AboutUS;
