import { useMutation } from "@apollo/client";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/auth/provideAuth";
import { BUILDING_DETAIL_BY_USER } from "../../graphql/mutations/buildingDealByUser";
import AddCreditForm from "./YourDealsForm";

interface YourDealsProps {
  refetch(): void;
}
function YourDeals({ refetch }: YourDealsProps) {
  const [createBuildingDetail, { loading, data }] = useMutation(BUILDING_DETAIL_BY_USER, {
    fetchPolicy: "network-only",
  });
  const { handleState } = useAuth();
  const onSubmit = async (values: { [key: string]: string }) => {
    try {
      Object.keys(values).forEach((key) => {
        values[key] = values[key]?.trim() ?? null;
      });
      const buildingDetailResponse = await createBuildingDetail({
        variables: {
          input: values,
        },
      });
      if (buildingDetailResponse?.data?.createBuildingDealByUser) {
        toast.success("Deal submitted.Please wait for Admin's approval");
        refetch();
        return;
      }
    } catch (e: any) {
      toast.error(e.message);
    }
  };
  useEffect(() => {
    handleState(!loading);
  }, [loading, handleState]);
  return <AddCreditForm onSubmitCallback={onSubmit} successdata={data} />;
}

export default YourDeals;
