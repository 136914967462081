import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/auth/provideAuth";

const withoutHeader = (WrappedComponent: any) => {
  function HOC(props: any) {
    const { pathname } = useLocation();
    const { handleHeader } = useAuth();
    useEffect(() => {
      if (pathname !== "/getstarted") {
        handleHeader(false);
      }
    }, [handleHeader, pathname]);
    return (
      <>
        <WrappedComponent {...props} />
      </>
    );
  }
  return HOC;
};

export default withoutHeader;
